import { BlobServiceClient, BlockBlobClient, ContainerClient } from "@azure/storage-blob";
import { getDownloadSignedUrl, getSignedUrl } from "api";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";

export const uploadFilesToStorage = async (uploadFiles: FileWithId[], entityType: string, entityId: string): Promise<IFile[]> => {
    const uploadedFiles: IFile[] = [];

    for (let i = 0; i < uploadFiles.length; i++) {
        const isDuplicate: boolean = uploadedFiles.some((file: IFile): boolean => file.filename === uploadFiles[i]?.file?.name);
        if (isDuplicate) {
            continue;
        }

        const encodedFileName = encodeURIComponent(uploadFiles[i]?.file?.name);

        const uploadFileSASData = await getSignedUrl({
            filename: encodedFileName,
            entityType: entityType,
            entityId: entityId
        });

        const { sas, containerName, blobName } = uploadFileSASData;

        uploadedFiles.push({
            id: uploadFiles[i].id ?? undefined,
            url: uploadFileSASData.blobName,
            filename: blobName
        });

        const blobServiceClient: BlobServiceClient = new BlobServiceClient(sas);
        const containerClient: ContainerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient: BlockBlobClient = containerClient.getBlockBlobClient(blobName);

        await blockBlobClient.uploadData(uploadFiles[i]?.file, {
            blockSize: 4 * 1024 * 1024,
            concurrency: 20
        });
    }

    return uploadedFiles;
};

export const downloadFile = (fileUrl: string) => async () => {
    try {
        const fileWithUrl = await getDownloadSignedUrl({ filename: fileUrl });
        window.open(fileWithUrl?.sas, '_blank');
    }
    catch (error) {
        console.error('Error downloading file from Blob storage:', error);
    }
    return undefined;
};