import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITag } from 'models/ITag';

interface TagsState {
  tags: {
    [key: string]: ITag[]
  }
};

const initialState: TagsState = {
  tags: {},
};

export const currentTagsSlice = createSlice({
  name: 'currentTags',
  initialState,
  reducers: {
    setTagsForKey: (state, action: PayloadAction<{ key: string; tags: ITag[] }>) => {
      const { key, tags } = action.payload;
      if (!state.tags[key]) {
        state.tags[key] = [];
      }
      state.tags[key] = tags;
    },
  },
})

export const { setTagsForKey } = currentTagsSlice.actions

export default currentTagsSlice.reducer