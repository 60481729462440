import { mergeStyles } from "@fluentui/react";
import { Body2, makeStyles, Persona } from "@fluentui/react-components";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { SkeletonChat } from "./SkeletonChat";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "10px"
    },
    persona: {
        padding: "10px 20px 10px 10px",
        wordBreak: "break-word"
    }
});

const markdownClassName = mergeStyles({
    width: "100%"
});

export const AIResponse = ({ aiGenerateMessage, isLoading }: { aiGenerateMessage: any, isLoading: any }) => {
    const styles = useStyles();

    if (!aiGenerateMessage && !isLoading) return <></>

    if (isLoading) return <SkeletonChat />

    return (
        <div className={styles.container}>
            <Persona
                name="NorthHighland AI"
                secondaryText="assistant"
                presence={{ status: "available" }}
                size="large"
                avatar={{ image: { src: "/images/logo_sm.png" } }}
            />
            <Body2 className={styles.persona}>
                <Markdown className={markdownClassName} remarkPlugins={[remarkGfm]}>{aiGenerateMessage}</Markdown>
            </Body2>
        </div>
    )
}