import { NHColors } from "utils/generalUtils";

export const customButtonStyles: React.CSSProperties = {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    minWidth: '32px',
    minHeight: '32px',
    color: 'black',
};

export const selectedButtonStyles: React.CSSProperties = {
    color: NHColors.primary,
    fontWeight: 'bold',
};