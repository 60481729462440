import { Button, Card, CardHeader, Link, makeStyles, Text, Tooltip } from "@fluentui/react-components";
import { Delete16Regular, DocumentAdd16Regular } from "@fluentui/react-icons";
import { getDownloadSignedUrl } from "api";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { resolveAsset } from "../../common/fileIcons";
import { IAttachmentsHandler } from "./AttachmentsHandler.types";

const useStyles = makeStyles({
    card: {
        width: "100%",
        height: "fit-content",
        marginBottom: "8px"
    },
    textEllipsis: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%"
    }
});

export const AttachmentsHandler = <T extends { name: string; attachments?: IFile[]; description?: string }>(props: IAttachmentsHandler<T>) => {
    const styles = useStyles();
    const fileInputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const { entity, downloadFiles } = props;
    const currentAttachments = useSelector((state: RootState) => state.currentAttachments.value);

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const files: FileList | null = event.target.files;
        if (files) {
            const newFiles: FileWithId[] = Array.from(files).map((file) => ({
                file: file
            }));

            const existingFilenames: string[] = currentAttachments.map((attachment: FileWithId) => attachment.file.name);

            const duplicates: FileWithId[] = newFiles.filter((file: FileWithId): boolean => existingFilenames.includes(file.file.name));

            if (duplicates.length > 0) {
                alert(`The following files already exist: ${duplicates.map((file: FileWithId) => file.file.name).join(', ')}`);
                return;
            }

            const combinedFiles: FileWithId[] = [...currentAttachments, ...newFiles];

            if (props.onFilesChange) {
                props.onFilesChange([], combinedFiles);
            }
        }
    };

    const deselectFile = (file: IFile) => (): void => {
        if (props.entity.attachments) {
            if (props.onFilesChange) {
                const uniqueFilesMap = new Map<string, FileWithId>();

                props.entity.attachments.forEach(att => {
                    if (!uniqueFilesMap.has(att.filename)) {
                        uniqueFilesMap.set(att.filename, {
                            id: att.id,
                            file: new File([], att.filename)
                        });
                    }
                });

                const convertedFiles: FileWithId[] = Array.from(uniqueFilesMap.values());

                const remainingFiles: FileWithId[] = convertedFiles.filter(
                    (attachment: FileWithId) => attachment.file.name !== file.filename
                );

                props.onFilesChange(convertedFiles, remainingFiles, true);
            }
        }
    };

    const selectFileFromOS = (): void => fileInputRef.current?.click();

    const handleDownloadFile = async (file: IFile): Promise<void> => {
        if (downloadFiles && file.url) {
            const fileWithUrl: any = await getDownloadSignedUrl({ filename: file.url });
            window.open(fileWithUrl.sas, "_blank");
        }
    };

    return (
        <React.Fragment>
            {!downloadFiles && entity.attachments && entity.attachments.length > 0 &&
                entity.attachments.map((file: IFile): JSX.Element => (
                    <Card className={styles.card} size="small" role="listitem" key={file.filename}>
                        <CardHeader
                            //@ts-ignore
                            image={{ as: "img", src: resolveAsset(file.filename, file), alt: file.filename, width: "40px", height: "40px" }}
                            header={<Text size={100} weight="semibold">{file.filename}</Text>}
                            action={
                                props.takesFilesFromEntity === false || props.takesFilesFromEntity === undefined ?
                                    <Button
                                        appearance="transparent"
                                        icon={<Delete16Regular />}
                                        onClick={deselectFile(file)}
                                    /> : undefined
                            }
                        />
                    </Card>
                ))
            }

            {downloadFiles && entity.attachments && entity.attachments.length > 0 &&
                entity.attachments
                    .map((file: IFile): JSX.Element => (
                        <Card className={styles.card} size="small" role="listitem" key={file.filename}>
                            <CardHeader
                                //@ts-ignore
                                image={{ as: "img", src: resolveAsset(file.filename, file), alt: file.filename, width: "40px", height: "40px" }}
                                header={
                                    <Tooltip content={file.filename} relationship="description">
                                        <Link
                                            onClick={() => handleDownloadFile(file)}
                                            style={{
                                                cursor: "pointer",
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'inline-block'
                                            }}>
                                            {file.filename}
                                        </Link>
                                    </Tooltip>
                                }
                                action={
                                    props.takesFilesFromEntity === false || props.takesFilesFromEntity === undefined ?
                                        <Button
                                            appearance="transparent"
                                            icon={<Delete16Regular />}
                                            onClick={deselectFile(file)}
                                        /> : undefined
                                }
                            />
                        </Card>
                    ))
            }

            {props.takesFilesFromEntity === false || props.takesFilesFromEntity === undefined
                ? <Button icon={<DocumentAdd16Regular />} size="small" onClick={selectFileFromOS}>
                    Add Document
                </Button>
                : undefined
            }
            <input
                onChange={handleFileInputChange}
                ref={fileInputRef}
                type="file"
                hidden
                multiple
            />
        </React.Fragment>
    );
};