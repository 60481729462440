import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilesState {
  value: any[];
}

const initialState: FilesState = {
  value: [],
};

export const currentFilesSlice = createSlice({
  name: 'currentFiles',
  initialState,
  reducers: {
    setCurrentFiles: (state, action: PayloadAction<any[]>) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentFiles } = currentFilesSlice.actions;

export default currentFilesSlice.reducer;