import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import React from "react";

interface ISkeletonChatProps {
    avatarOnRight?: boolean;
};

export const SkeletonChat = (props: ISkeletonChatProps): JSX.Element => {
    const styles: { [key: string]: React.CSSProperties } = {
        skeleton: {
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        skeletonItem: {
            display: "flex",
            gap: "5px",
            justifyContent: props.avatarOnRight ? "end" : "start",
        },
        skeletonItem2: {
            display: "flex",
            width: "100px",
            flexDirection: "column",
            gap: "5px",
        },
        skeletonFooter: {
            display: "flex",
            gap: "20px",
        },
    };

    return (
        <Skeleton style={styles.skeleton} aria-label="Loading Content">
            <div style={styles.skeletonItem}>
                <SkeletonItem shape="circle" animation="pulse" size={36} />
                <div style={styles.skeletonItem2}>
                    <SkeletonItem size={12} />
                    <SkeletonItem animation="pulse" size={12} />
                </div>
            </div>
            <SkeletonItem />
            <div style={styles.skeletonFooter}>
                <SkeletonItem size={12} />
                <SkeletonItem size={12} />
                <SkeletonItem animation="pulse" size={12} />
            </div>
            <SkeletonItem />
        </Skeleton>
    );
};