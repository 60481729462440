import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    dialogSurface: {
        minWidth: '50vw',
        width: '75vw',
        display: 'block',
        gridTemplateColumns: 'none',
        gridTemplateRows: 'none',
    },
    dialogBody: {
        gridTemplateColumns: 'none',
        gridTemplateRows: 'none',
        display: 'block',
        ...shorthands.padding('2vh'),
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "0.3vh",
        padding: "0.5vh 0.5vh 0 0.5vh",
        backgroundColor: tokens.colorNeutralForeground1Static,
        gap: "0.5vh"
    },
    sortActionContainer: {
        display: "flex",
        gap: "0.5vh",
        width: "100%",
        padding: "0.5vh 0",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        "@media (max-width: 400px)": {
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    sortButton: {
        color: NHColors.white,
        fontSize: "1.4vh",
        padding: "0.4vh 0.8vh",
        borderRadius: "0.5vh",
        minWidth: "5vw",
        maxWidth: "15%",
        ':hover': {
            color: NHColors.white,
            backgroundColor: NHColors.primary,
            textDecoration: 'underline'
        },
        ':active': {
            backgroundColor: NHColors.primary
        },
        ':hover:active': {
            color: NHColors.white,
            backgroundColor: NHColors.primary
        }
    },
    title: {
        margin: "0 0 1.2vh",
        fontSize: "1.3vh",
        overflowWrap: "break-word"
    },
    card: {
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        padding: "1.25vh",
        height: "auto",
        border: `0.1vh solid ${NHColors.primaryOld}`,
        borderRadius: "0px",
        color: NHColors.white,
        backgroundColor: NHColors.primaryOld,
        ':hover': {
            backgroundColor: NHColors.primary,
            color: NHColors.white
        },
        ':active': {
            backgroundColor: NHColors.primary,
            color: NHColors.white
        }
    },
    flex: {
        gap: "0.4vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
    appIcon: {
        borderRadius: "0.4vh",
        height: "3.2vh",
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "0.5vh",
        "@media (max-width: 500px)": {
            flexDirection: "column",
        },
    },
    menuItem: {
        '&:hover:active': {
            backgroundColor: NHColors.primary2,
            color: NHColors.secondary,
        },
        '&:hover': {
            backgroundColor: NHColors.primary2,
            color: NHColors.secondary,
        },
    },
    newConversationButton: {
        width: "100%",
        display: "flex",
        justifySelf: "end",
        backgroundColor: NHColors.primary2,
        ':hover': {
            backgroundColor: NHColors.primary2,
        },
        ':active': {
            backgroundColor: NHColors.primary2
        },
        ':hover:active': {
            backgroundColor: NHColors.primary2
        }
    },
    pinIconWrapper: {
        position: 'absolute',
        top: '1vh',
        left: '1vh',
    },
    menuButton: {
        background: 'transparent',
        color: 'white',
        border: 'none',
        fontSize: "1.2vh",
        '&:hover': {
            opacity: 0.5,
            backgroundColor: 'transparent',
        },
    },
    conversationsSearch: {
        marginTop: '1vh',
        marginBottom: '1vh',
        width: "90%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: NHColors.white,
        color: "black",
        "--colorNeutralForeground3": "black",
        border: "0.1vh solid #ccc",
        borderRadius: "0.5vh",
        padding: "0.5vh",
        outline: "none",
        transition: "box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out",
        ':focus-within': {
            boxShadow: `0 0 0 0.15vh rgb(3, 159, 184)`,
        }
    },
    deleteButton: {
        color: 'white',
        background: 'transparent',
        border: '0px',
        borderRadius: '0px',
        ':hover': {
            color: NHColors.white,
            backgroundColor: NHColors.primary,
            borderBottom: '0.1vh solid white',
        },
    },
    activeButton: {
        textDecoration: "underline",
    },
    confirmationDialogButton: {
        color: 'white',
        backgroundColor: NHColors.primary,
        ':hover': {
            color: NHColors.white,
            backgroundColor: NHColors.primary,
        },
    },
    capabilityTag: {
        padding: '0.4vh 0.6vh',
        backgroundColor: NHColors.gray,
        borderRadius: '1.2vh',
        fontSize: '1.4vh',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        ':hover': {
            cursor: 'pointer',
        },
    },
    selectedCapabilityTag: {
        backgroundColor: NHColors.primary,
        color: 'white'
    },
    capabilityFilter: {
        color: 'white',
        ':hover': {
            background: 'transparent',
            color: NHColors.primary2
        },
        '&:hover:active': {
            color: NHColors.secondary,
            backgroundColor: 'transparent',
        },
    },
    clearFiltersButton: {
        ':hover': {
            cursor: 'pointer',
        },
    },
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5vh",
        width: "100%",
        alignItems: "center",
    },
    conversationsWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "0.5vh",
        overflowY: "auto",
        maxHeight: "45vh",
    },
    footer: {
        paddingLeft: '1vh',
        position: 'relative',
        paddingRight: '1vh',
        boxSizing: 'border-box',
        width: '100%',
        display: "flex",
        flexDirection: "column",
    },
    footerLink: {
        color: "rgb(3, 159, 184)",
        fontSize: "1.3vh",
        textDecoration: "underline",
        cursor: "pointer",
    },
    footerButtons: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: '1vh',
    },
    centerFooterButton: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1vh",
    },
});