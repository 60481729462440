import { saveAs } from "file-saver";
import { API_BASE } from "./config";

const requestWithExpiredTokenRetry = async (url, { ...data }) => {
    if (url.slice(-1) !== '/' && !url.includes('?')) {
        url = `${url}/`
    }

    const token = localStorage.getItem("token")
    const authorizationHeader = { "Authorization": `Bearer ${token}` }
    const cacheHeaders = { "Pragma": `no-cache`, "Cache-Control": 'no-cache' }
    if (data?.headers) {
        data.headers = { ...data.headers, ...authorizationHeader, ...cacheHeaders }
    } else data.headers = { ...authorizationHeader, ...cacheHeaders }
    const response = await fetch(url, { ...data })
    if (!response?.ok) throw new Error(`error fetching: ${url}`)

    return response
};


export const getConversations = async ({ onlySharedConversations, pinned = false, ascending = true, searchText, filterCapabilities = [] }) => {
    let url = new URL(`${API_BASE}/conversations/`);

    if (pinned) url.searchParams.append('pinned', pinned);
    if (onlySharedConversations) url.searchParams.append('shared', onlySharedConversations);
    if (ascending) url.searchParams.append('ascending', ascending);
    if (searchText) url.searchParams.append('searchText', searchText);

    const encodedCapabilities = filterCapabilities.map(cap => encodeURIComponent(cap));

    encodedCapabilities.forEach(cap => {
        url.searchParams.append('filterCapabilities', cap);
    });

    const response = await requestWithExpiredTokenRetry(url.toString(), { redirect: 'follow' });
    return await response.json();
}

export const getCategories = async (returnAllTags) => {

    const url = new URL(`${API_BASE}/categories/`);
    url.searchParams.append('return_all_tags', returnAllTags ? 'true' : 'false');

    const response = await requestWithExpiredTokenRetry(url.toString(), { redirect: 'follow' });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

export const getPrompts = async () => {
    const response = await requestWithExpiredTokenRetry(`${API_BASE}/prompts`, { redirect: 'follow' })
    return await response.json()
}

export const createPrompt = async ({ title, description }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/prompts`,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow', body: JSON.stringify({ title, description })
        })
    return await response.json()
}


export const deletePrompt = async ({ promptId }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/prompts/${promptId}`,
        {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
    return await response.json()
}

export const getMessagesOfConversation = async ({ conversationId }) => {
    const response = await requestWithExpiredTokenRetry(`${API_BASE}/conversations/${conversationId}/messages/?asc=true`, { redirect: 'follow' })
    return await response.json()
}

export const deleteConversation = async ({ conversationId }) => {
    const response = await requestWithExpiredTokenRetry(`${API_BASE}/conversations/${conversationId}`, { method: "DELETE", redirect: 'follow' })
    return await response.json()
}

export const getAIResponse = async ({ llmConfig, messages, persistFiles = true }) => {
    return await requestWithExpiredTokenRetry(`${API_BASE}/ai/chat`, {
        headers: {
            'Accept': 'text/event-stream',
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        method: "POST",
        body: JSON.stringify({ llmConfig, messages, persistFiles })
    })
}

export const getAIAbstract = async ({ llmConfig, messages }) => {
    const response = await requestWithExpiredTokenRetry(`${API_BASE}/ai/abstractgeneration`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        method: "POST",
        body: JSON.stringify({ llmConfig, messages })
    })
    return await response.json()
}

export const getAITitle = async ({ llmConfig, messages }) => {
    const response = await requestWithExpiredTokenRetry(`${API_BASE}/ai/titlegeneration`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        method: "POST",
        body: JSON.stringify({ llmConfig, messages })
    })
    return await response.json()
}

export const updateConversationTitle = async ({ conversation, title }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversation?.id}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PATCH",
            body: JSON.stringify({ title })
        }
    )
    return await response.json()
}

export const setConversationToPin = async ({ conversation }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversation?.id}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PATCH",
            body: JSON.stringify({
                shared: conversation?.shared,
                sharingType: conversation?.sharingType,
                sharedIds: conversation?.sharedIds,
                labels: conversation?.labels,
                abstract: conversation?.abstract,
                pinned: conversation?.pinned
            })
        }
    )
    return await response.json()
}

export const setConversationToSharing = async ({ conversation }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversation?.id}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PATCH",
            body: JSON.stringify({
                shared: conversation?.shared,
                sharingType: conversation?.sharingType,
                sharedIds: conversation?.sharedIds,
                labels: conversation?.labels,
                abstract: conversation?.abstract,
                pinned: conversation?.pinned
            })
        }
    )
    return await response.json();
}

export const addMessageToConversation = async ({ conversationId, message }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversationId}/messages`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ ...message })
        }
    )
    return await response.json()
}

export const createConversation = async ({ messages, title, pinned }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ messages, title, pinned })
        }
    )
    return await response.json()
}

export const updateConversation = async ({ conversation }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversation?.id}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify({ ...conversation })
        }
    )
    return await response.json()
}

export const updateContentOfConversation = async ({ conversationId, messageId, content }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/conversations/${conversationId}/messages/${messageId}/`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PATCH",
            body: JSON.stringify({ content })
        }
    )
    return await response.json()
}


export const getSignedUrl = async ({ filename, entityType, entityId }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/files/getSignedUrl`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                filename,
                entityType,
                entityId
            }),
        }
    );
    return await response.json();
}

export const getDownloadSignedUrl = async ({ filename }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/files/`,
        {
            redirect: 'follow',
            body: JSON.stringify({ filename }),
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json'
            },
            method: "POST",
        }
    )
    return await response.json()
}


export const getUserIdentity = async ({ role }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/users/identity/?role=${role}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
    )
    return await response.json()
};

export const updateCategory = async ({ category }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/categories/${category.id}`,
        {
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify({ ...category })
        }
    );

    return await response.json()
};

export const deleteCategory = async (categoryId) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/categories/${categoryId}`,
        {
            redirect: 'follow',
            method: "DELETE"
        }
    );

    return await response.json()
};


export const createCategory = async (category) => {
    const url = `${API_BASE}/categories`;

    const response = await requestWithExpiredTokenRetry(
        url,
        {

            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ ...category })
        }
    );

    return await response.json()
};

export const getUsers = async ({ text, hasOnlyOurUsers = false } = {}) => {
    let url = `${API_BASE}/users/`;

    const queryParams = [];
    if (text !== undefined) queryParams.push(`text=${encodeURIComponent(text)}`);

    if (hasOnlyOurUsers) queryParams.push(`onlyOurUsers=${hasOnlyOurUsers}`);

    if (queryParams.length > 0) {
        url += `?${queryParams.join("&")}`;
    }

    const response = await requestWithExpiredTokenRetry(url, {
        method: 'GET'
    });

    const data = await response.json();

    return data;
};

export const checkIfUserIsInGroup = async (accessToken, groupName) => {
    try {
        const response = await requestWithExpiredTokenRetry(`${API_BASE}/users/identity/?role=${groupName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch users');
        }

        return await response.json();
    } catch (error) {
        console.error('Error checking if user in group:', error);
        return [];
    }
};

export const addUserToGroup = async (groupName, userId) => {
    try {
        const response = await requestWithExpiredTokenRetry(`${API_BASE}/users/addUserToGroup`, {

            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                "userId": userId,
                "groupName": groupName
            })
        });

        if (!response.ok) {
            throw new Error('Failed to add user to group');
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to add user to group', error);
        return;
    }
};

export const removeUserFromGroup = async (groupName, userId) => {
    try {
        const response = await requestWithExpiredTokenRetry(`${API_BASE}/users/removeUserFromGroup`, {

            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "DELETE",
            body: JSON.stringify({
                "userId": userId,
                "groupName": groupName
            })
        });

        if (!response.ok) {
            throw new Error('Failed to add user to group');
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to add user to group', error);
        return;
    }
};

export const removeUserFromGroups = async (userId) => {
    try {
        const response = await requestWithExpiredTokenRetry(`${API_BASE}/users/removeUserFromGroups/${userId}`, {

            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "DELETE"
        });

        if (!response.ok) {
            throw new Error('Failed to remove user from groups');
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to remove user from groups', error);
        return;
    }
};

export const getGroups = async ({ asc = true } = {}) => {
    const url = new URL(`${API_BASE}/groups/`);
    url.searchParams.append('asc', asc);

    const response = await requestWithExpiredTokenRetry(url.toString(), { method: 'GET' });
    return await response.json();
};

export const createGroup = async ({ name, description, users }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/groups`,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({ name, description, users })
        }
    );
    return await response.json();
};

export const updateGroup = async ({ groupId, name, description, users }) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/groups/${groupId}`,
        {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({ name, description, users })
        }
    );
    return await response.json();
};

export const deleteGroup = async (groupId) => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/groups/${groupId}`,
        {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        }
    );
    return await response.json();
};

export const getUserActivityWithinTimeframe = async ({ startDate, endDate }) => {
    let url = new URL(`${API_BASE}/statistics/user-activity`);
    if (startDate) url.searchParams.append('start_date', startDate.toISOString());
    if (endDate) url.searchParams.append('end_date', endDate.toISOString());

    const response = await requestWithExpiredTokenRetry(url.toString(), { method: 'GET' });
    return await response.json();
};

export const getModelUsage = async ({ startDate, endDate }) => {
    let url = new URL(`${API_BASE}/statistics/model-usage`);
    if (startDate) url.searchParams.append('start_date', startDate.toISOString());
    if (endDate) url.searchParams.append('end_date', endDate.toISOString());

    const response = await requestWithExpiredTokenRetry(url.toString(), { method: 'GET' });
    return await response.json();
};

export const exportToExcel = async () => {
    const response = await requestWithExpiredTokenRetry(
        `${API_BASE}/files/exportToExcel`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        }
    );
    const blob = await response.blob();
    saveAs(blob, "Users.xlsx");
}