import { Button } from "@fluentui/react-components";
import { customButtonStyles, selectedButtonStyles } from "./PageButton.styles";
import { IPageButtonProps } from "./PageButton.types";

export const PageButton = (props: IPageButtonProps): JSX.Element => {
    const { ariaLabel, pageNo, selected } = props;

    return (
        <Button
            value={pageNo.toString()}
            key={pageNo}
            onClick={(): void => props.onClick(props.pageNo - 1)}
            aria-selected={selected}
            aria-label={ariaLabel}
            style={{
                ...customButtonStyles,
                ...(selected ? selectedButtonStyles : {}),
            }}
        >
            {pageNo}
        </Button>
    );
};