import { IFile } from "models/IFile";
import { FileWithId } from "../models/FileWithId";

const pdfIcon = "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg";
const txtIcon = "https://upload.wikimedia.org/wikipedia/commons/0/07/Notepad_Win11.svg";
const imageIcon = "https://upload.wikimedia.org/wikipedia/commons/c/c3/JPEG_format_logo.svg";

export const isImage = (filename: string): boolean => {
    if (!filename) {
        return false;
    }

    const extension: string | undefined = filename.split('.').pop()?.toLowerCase();
    if (!extension) {
        return false;
    }

    return ["jpg", "jpeg", "png", "gif", "bmp", "tiff"].includes(extension);
};

export const resolveAsset = (asset: any, file?: File | IFile | FileWithId): string | undefined => {
    if (file) {
        if ('file' in file && file.file instanceof File) {
            if (isImage(file.file.name)) {
                return resolveImage(file.file as File);
            }
        }
        else if (file instanceof File) {
            if (isImage(file.name)) {
                return resolveImage(file as File);
            }
        }
        else if ('url' in file && file.url) {
            if (isImage(file.filename)) {
                return file.url;
            }
        }
    }

    if (!asset) {
        return;
    }

    let extension = asset.split('.').pop()?.toLowerCase();
    if (extension === "doc") {
        extension = "docx";
    }
    else if (extension === "xls" || extension === "csv") {
        extension = "xlsx";
    }
    else if (extension === "ppt") {
        extension = "pptx";
    }

    if (extension === "pdf") {
        return pdfIcon;
    }

    if ([
        'accdb', 'csv', 'docx', 'dotx', 'mpp', 'mpt', 'one', 'onetoc', 'potx',
        'ppsx', 'pptx', 'pub', 'vsdx', 'vssx', 'vstx', 'xlsx', 'xltx', 'xsn'
    ].includes(extension)) {
        const ASSET_URL = "https://raw.githubusercontent.com/microsoft/fluentui/master/packages/react-components/react-card/stories/src/assets/";
        return `${ASSET_URL}${extension}.png`;
    }

    if (isImage(asset)) {
        return imageIcon;
    }

    return txtIcon;
};

const resolveImage = (file: File): string | undefined => {
    if (isImage(file.name)) {
        return URL.createObjectURL(file);
    }

    return undefined;
};