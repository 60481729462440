import { makeStyles } from "@fluentui/react-components";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px"
    }
});

export const confirmationButtonStyles = {
    color: 'white',
    backgroundColor: NHColors.primary,
    ':hover':
    {
        color: 'white',
        backgroundColor: NHColors.primary,
    }
};