import { Button, Menu, MenuItemRadio, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { Bot20Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { LLMType } from "enums/LLMType";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setNewConfigForKey } from "../../redux/llmConfig";
import { IAIModelSettingsProps } from "./AIModelSettings.types";

export const AIModelSettings = (props: IAIModelSettingsProps): JSX.Element => {
    const currentLLMConfig = useSelector((state: RootState) => state.currentLLMConfigSlice.config[props.dictKey] || { llmType: LLMType.OPENAI, rag: false });
    const [currentSelectedModel, setCurrentlySelectedModel] = useState<string>(currentLLMConfig.llmType);

    const dispatcher = useDispatch()

    const selectLLMModel = (e: any, { name, checkedItems }: { name: string, checkedItems: any }) => {
        const configCopy = { ...currentLLMConfig, llmType: LLMType.OPENAI, rag: false };

        const llmWithRagOptions = checkedItems[0].split('+');
        if (llmWithRagOptions?.length === 1) {
            configCopy.llmType = checkedItems[0];
            configCopy.rag = false;
        } else {
            configCopy.llmType = llmWithRagOptions[0];
            configCopy.rag = true;
        }

        setCurrentlySelectedModel(checkedItems[0]);
        dispatcher(setNewConfigForKey({ key: props.dictKey, config: configCopy }));
    };

    return (<div style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: "10px",
    }}>
        <Menu onCheckedValueChange={selectLLMModel}>
            <MenuTrigger disableButtonEnhancement>
                <Button style={{ display: "flex", gap: "2px" }}><Bot20Regular /> <span>{currentSelectedModel}</span></Button>
            </MenuTrigger>
            <MenuPopover style={{ width: "100%" }}>
                <MenuList>
                    <MenuItemRadio value={LLMType.CLAUDE} name={ConstantValues.EMPTY_STRING}>{LLMType.CLAUDE}</MenuItemRadio>
                    <MenuItemRadio value={LLMType.OPENAI} name={ConstantValues.EMPTY_STRING}>{LLMType.OPENAI}</MenuItemRadio>
                    <MenuItemRadio value={LLMType.LLAMA} name={ConstantValues.EMPTY_STRING}>{LLMType.LLAMA}</MenuItemRadio>
                    <MenuItemRadio value={LLMType.CLAUDE_NHQA} name={ConstantValues.EMPTY_STRING}>{LLMType.CLAUDE_NHQA}</MenuItemRadio>
                    <MenuItemRadio value={LLMType.OPENAI_NHQA} name={ConstantValues.EMPTY_STRING}>{LLMType.OPENAI_NHQA}</MenuItemRadio>
                    <MenuItemRadio value={LLMType.LLAMA_NHQA} name={ConstantValues.EMPTY_STRING}>{LLMType.LLAMA_NHQA}</MenuItemRadio>
                </MenuList>
            </MenuPopover>
        </Menu>
    </div>
    );
};