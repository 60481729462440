import { configureStore } from '@reduxjs/toolkit';
import aiGenerateSlice from './redux/aiGeneratedMessage';
import currentConversationSetter from './redux/conversations';
import currentAttachments from './redux/currentAttachments';
import currentUserSlice from './redux/currentUser';
import currentFilesSlice from './redux/files';
import groupsSlice from './redux/groups';
import currentLLMConfigSlice from './redux/llmConfig';
import currentMessagesSetter from './redux/messages';
import currentPromptSlice from './redux/selectedPrompt';
import currentTagsSlice from './redux/tags';
import temporaryChatSlice from './redux/temporaryChat';
import usersSlice from './redux/users';

const store = configureStore({
  reducer: {
    currentConversationSetter,
    currentMessagesSetter,
    aiGenerateSlice,
    currentTagsSlice,
    currentLLMConfigSlice,
    currentPromptSlice,
    currentFilesSlice,
    currentUserSlice,
    currentAttachments,
    usersSlice,
    temporaryChatSlice,
    groupsSlice
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;