import { IDropdownOption, Stack, StackItem } from "@fluentui/react";
import { Avatar, Button, InputOnChangeData, Label, MessageBar, SearchBox, SearchBoxChangeEvent, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableRowData, tokens, Toolbar, ToolbarButton, Tooltip, useFocusableGroup, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { Add16Regular, DeleteRegular, EditRegular, ErrorCircle20Regular } from "@fluentui/react-icons";
import { ColumnActionsMenu } from "components/columnActionsMenu/ColumnActionsMenu";
import { IColumnActionsMenuProps } from "components/columnActionsMenu/ColumnActionsMenu.types";
import { InputSkeleton } from "components/InputSkeletons";
import { Paginator } from "components/paginator/Paginator";
import { SortingDirection } from "enums/SortingOrder";
import { IColumnSizingOption } from "models/IColumnSizingOptions";
import React, { useEffect, useMemo, useState } from "react";
import { QueryClient, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { createCategory, deleteCategory, updateCategory } from "../../api";
import { ConstantValues } from "../../common/constants";
import { ColumnTypes } from "../../enums/ColumnTypes";
import { ICategory, ICreateCategory } from "../../models/ICategory";
import { ITableColumn } from "../../models/ITableColumn";
import { ITag } from "../../models/ITag";
import { currentAccount, IsNullUndefinedOrEmpty, NHColors, renderCellValue, validateFields } from "../../utils/generalUtils";
import { ActionDialog } from "../actionDialog/ActionDialog";
import { CellFactory } from "../cells/CellFactory";
import { containerClassName, searchBoxStyle, setGapBetweenHeadersAndDetailsList } from "./ManageCategories.styles";
import { IManageCategoriesProps } from "./ManageCategories.types";

const columnSizingOptions: Partial<Record<keyof ICategory, IColumnSizingOption>> = {
    icon: { idealWidth: 50, minWidth: 50 },
    title: { idealWidth: 225, minWidth: 225 },
    description: { idealWidth: 250, minWidth: 250 },
    tags: { idealWidth: 250, minWidth: 250 },
    createdAt: { idealWidth: 150, minWidth: 150 },
    updatedAt: { idealWidth: 150, minWidth: 150 },
    displayName: { idealWidth: 175, minWidth: 175 }
};

export const ManageCategories = (props: IManageCategoriesProps): JSX.Element => {
    const columns: ITableColumn<ICategory>[] = useMemo(() => [
        {
            columnId: "icon",
            displayValue: ConstantValues.EMPTY_STRING,
            renderHeaderCell: () => ConstantValues.EMPTY_STRING,
            renderCell: (): JSX.Element | null => null,
            compare: () => 0,
        },
        {
            columnId: "title",
            displayValue: "Title",
            required: true,
            renderHeaderCell: () => "Title",
            renderCell: (category: ICategory): string => category.title,
            compare: (a: ICategory, b: ICategory): number => a.title.localeCompare(b.title),
            isEditableForEdit: true,
            isEditableForSave: true,
            type: ColumnTypes.SingleLineText,
        },
        {
            columnId: "description",
            displayValue: "Description",
            required: true,
            renderHeaderCell: () => "Description",
            renderCell: (category: ICategory): string => category.description,
            compare: (a: ICategory, b: ICategory): number => a.description.localeCompare(b.description),
            isEditableForSave: true,
            isEditableForEdit: true,
            type: ColumnTypes.SingleLineText,
        },
        {
            columnId: "tags",
            displayValue: "Knowledge Components",
            renderHeaderCell: () => "Knowledge Components",
            renderCell: (category: ICategory): JSX.Element =>
                Array.isArray(category.tags) && category.tags.length > 0
                    ? (
                        <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: '0' }}>
                            {category.tags.map((tag: ITag, index: number) => (
                                <li key={index} style={{ marginBottom: '4px' }}>
                                    {tag.name}
                                </li>
                            ))}
                        </ul>
                    )
                    : <span>-</span>,
            compare: (a: ICategory, b: ICategory): number => {
                const tagsA = Array.isArray(a.tags) ? a.tags.map((tag: ITag) => tag.name).join(", ") : ConstantValues.EMPTY_STRING;
                const tagsB = Array.isArray(b.tags) ? b.tags.map((tag: ITag) => tag.name).join(", ") : ConstantValues.EMPTY_STRING;
                return tagsA.localeCompare(tagsB);
            },
        },
        {
            columnId: "createdAt",
            displayValue: "Created At",
            renderHeaderCell: () => "Created At",
            renderCell: (category: ICategory): JSX.Element => {
                if (!category.createdAt || isNaN(category.createdAt)) {
                    return <span>-</span>;
                }

                const date: Date = new Date(category.createdAt * 1000);
                return <span>{date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
            },
            compare: (a: ICategory, b: ICategory): number => a.createdAt - b.createdAt,
        },
        {
            columnId: "updatedAt",
            displayValue: "Updated At",
            renderHeaderCell: () => "Updated At",
            renderCell: (category: ICategory): JSX.Element => {
                if (!category.updatedAt || isNaN(category.updatedAt)) {
                    return <span>-</span>;
                }

                const date: Date = new Date(category.updatedAt * 1000);
                return <span>{date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
            },
            compare: (a: ICategory, b: ICategory): number => a.updatedAt - b.updatedAt,
        },
        {
            columnId: "displayName",
            displayValue: "Author",
            renderHeaderCell: () => "Author",
            renderCell: (category: ICategory): JSX.Element => {
                if (!category) {
                    return <TableCellLayout media={<Avatar />} />;
                }

                return (
                    <TableCellLayout media={<Avatar aria-label={category.displayName} name={category.displayName} />}>
                        {category.displayName}
                    </TableCellLayout>
                );
            },
            compare: (a: ICategory, b: ICategory): number => a.displayName.localeCompare(b.displayName),
        }
    ], []);

    const [categories, setCategories] = useState<ICategory[]>(props.categories);
    const [searchQuery, setSearchQuery] = useState<string>(ConstantValues.EMPTY_STRING);
    const [fileteredCategories, setFilteredCategories] = useState<ICategory[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
    const [showLoseEditChangesDialog, setShowLoseEditChangesDialog] = useState<boolean>(false);
    const [updatedCategory, setUpdatedCategory] = useState<ICategory | null>(null);
    const [newCategory, setNewCategory] = useState<ICategory>({ id: "new" } as ICategory);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [originalCategory, setOriginalCategory] = useState<ICategory | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [isSortDescending, setIsSortDescending] = useState<boolean>(false);
    const [validationMessages, setValidationMessages] = useState<Record<string, string[]>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value);

    const itemsPerPage: number = 8;
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const itemsStartIndex: number = selectedPageIndex * itemsPerPage;
    const itemsEndIndex: number = (selectedPageIndex + 1) * itemsPerPage;

    const queryClient: QueryClient = useQueryClient()

    const focusableGroupAttr = useFocusableGroup({ tabBehavior: "limited-trap-focus" });

    const pageCount: number = useMemo((): number => {
        return Math.ceil(fileteredCategories.length / itemsPerPage);
    }, [fileteredCategories]);

    const paginatedCategories: ICategory[] = fileteredCategories.slice(itemsStartIndex, itemsEndIndex);

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
        {
            columns,
            items: paginatedCategories
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
                autoFitColumns: false
            })
        ]
    );

    const rows: TableRowData<ICategory>[] = getRows();

    useEffect((): void => {
        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        if (savedSortingOptions) {
            const parsedOptions = JSON.parse(savedSortingOptions);
            const listSortingOptions = parsedOptions['Categories'];

            if (listSortingOptions) {
                setSortColumn(listSortingOptions.column);
                setIsSortDescending(listSortingOptions.direction === 'desc');
            }
        }
    }, []);

    useEffect((): void => {
        setFilteredCategories(categories);
    }, [categories])

    useEffect((): void => {
        let updatedCategories = [...categories];

        if (sortColumn) {
            const column: ITableColumn<ICategory> | undefined = columns.find((col: ITableColumn<ICategory>): boolean => col.columnId === sortColumn);
            if (column && column.compare) {
                updatedCategories.sort((a: ICategory, b: ICategory): number => column.compare(a, b) * (isSortDescending ? -1 : 1));
            }
        }

        setFilteredCategories(updatedCategories);
    }, [categories, sortColumn, isSortDescending]);

    const onPageChange = (index: number): void => setSelectedPageIndex(index);

    const onRenderDetailsFooter = (): JSX.Element => (
        <div style={{ position: "sticky", bottom: 0, backgroundColor: tokens.colorNeutralBackground2, padding: "10px", textAlign: "center" }}>
            {pageCount > 1 && (
                <Paginator
                    selectedIndex={selectedPageIndex}
                    pageCount={pageCount}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={categories.length}
                    displayPosition={true}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );

    const mapTagsToDropdownOptions = (categories: ICategory[]): IDropdownOption[] => {
        return categories.flatMap((category: ICategory): IDropdownOption[] =>
            category.tags.map((tag: ITag): IDropdownOption => ({
                key: tag.id,
                text: `${tag.name} - ${category.title}`,
            }))
        );
    };

    const getListViewCell = (
        category: ICategory,
        column: ITableColumn<ICategory>,
        isDisabled: boolean,
        onChange: (event: any, value: string | undefined) => void
    ): JSX.Element => {
        const value = category[column.columnId as keyof ICategory];
        const isNewCategory: boolean = category.id === "new";
        let isColumnEditable = false;

        if (isNewCategory) {
            isColumnEditable = column.isEditableForSave ?? false;
        } else {
            isColumnEditable = column.isEditableForEdit ?? false;
        }

        const isBeingEdited: boolean = (category.id === updatedCategory?.id && isEditing) || isNewCategory;

        if (column.columnId === "icon") {
            if (validationMessages[category.id]?.length > 0) {
                return (
                    <Tooltip
                        content={
                            <span>
                                {validationMessages[category.id].map((msg, index) => (
                                    <React.Fragment key={index}>
                                        {msg}
                                        {index < validationMessages[category.id].length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </span>
                        }
                        relationship="label"
                    >
                        <ErrorCircle20Regular
                            style={{
                                color: "red",
                                display: "block",
                                margin: "0 auto",
                                width: "20px",
                                height: "20px"
                            }}
                        />
                    </Tooltip>
                );
            } else if (isBeingEdited && hasChanges && ((isEditing && !isNewCategory) || (!isEditing && isNewCategory))) {
                return (
                    <EditRegular
                        style={{
                            color: NHColors.primary,
                            display: "block",
                            margin: "0 auto",
                            width: "20px",
                            height: "20px"
                        }}
                    />
                );
            }
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {isBeingEdited && !isDisabled
                    ? <CellFactory
                        value={value !== undefined ? String(value) : ConstantValues.EMPTY_STRING}
                        editable={isColumnEditable}
                        onChange={onChange}
                        type={column.type ?? ColumnTypes.SingleLineText}
                        options={column.type === ColumnTypes.MultipleSelection ? mapTagsToDropdownOptions(categories) : undefined}
                    />
                    : column.renderCell
                        ? column.renderCell(category)
                        : renderCellValue<ICategory>(category, column.columnId as keyof ICategory)
                }
            </div>
        );
    };

    const handleEditClick = (category: ICategory) => {
        if (isEditing && updatedCategory && updatedCategory.id === category.id) {
            if (hasChanges) {
                setShowLoseEditChangesDialog(true);
            } else {
                setIsEditing(false);
                setUpdatedCategory(null);
            }
            return;
        }

        setOriginalCategory({ ...category });
        setIsEditing(true);
        setUpdatedCategory(category);
        setHasChanges(false);
    };


    const onSortColumnChanged = (columnKey: string, descending: boolean) => {
        setSortColumn(columnKey);
        setIsSortDescending(descending);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        sortingOptions['Categories'] = {
            column: columnKey,
            direction: descending ? 'desc' : 'asc'
        };

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const onClearColumnChanged = () => {
        setSortColumn(null);
        setIsSortDescending(false);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        delete sortingOptions['Categories'];

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const handleDeleteClick = (category: ICategory) => {
        setShowDeleteDialog(true);
        setUpdatedCategory(category);
    };

    const handleSaveClick = (): void => {
        if (newCategory && !IsNullUndefinedOrEmpty(newCategory.title) && !isUniqueCategory(newCategory.title)) {
            setErrorMessage(`The category title "${newCategory.title}" already exists.`);
            setTimeout(() => setErrorMessage(null), 5000);
            return;
        }

        const itemToValidate: ICategory = updatedCategory || newCategory;
        const itemId: string = itemToValidate.id || "new";

        const messages: string[] = validateFields(itemToValidate, columns);

        if (messages.length > 0) {
            setValidationMessages((prevMessages) => ({
                ...prevMessages,
                [itemId]: messages,
            }));
            return;
        }

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[itemId];
            return newMessages;
        });

        setShowSaveDialog(true);
        setErrorMessage(null);
    };

    const handleCellChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof ICategory, categoryId: string) => {
        setHasChanges(true);
        let value: any;
        const column: ITableColumn<ICategory> | undefined = columns.find((column: ITableColumn<ICategory>): boolean => column.columnId === key);
        if (!column) {
            return;
        }

        if (column.type && column.type === ColumnTypes.SingleSelection) {
            value = event.target.textContent;
            const foundCategory: ICategory | undefined = categories.find((category: ICategory): boolean => category.title === value);
            value = {
                id: foundCategory?.id,
                title: value,
                tags: foundCategory?.tags,
            };
        }
        else {
            value = event.target.value;
        }
        setCategories((prevCategories: Array<ICategory>): Array<ICategory> => prevCategories?.map((category): ICategory => {
            if (category.id === categoryId) {
                return { ...category, [key]: value };
            }

            return category;
        }));
        setUpdatedCategory((prevTag) => ({ ...prevTag, [key]: value } as ICategory));

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[categoryId];
            return newMessages;
        });
    };

    const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof ICategory): void => {
        let newValue: any = event.target.value;
        const column: ITableColumn<ICategory> | undefined = columns.find((column: ITableColumn<ICategory>): boolean => column.columnId === key);
        if (!column) {
            return;
        }

        if (column.type && column.type === ColumnTypes.SingleSelection) {
            newValue = event.target.textContent;
            const foundCategory: ICategory | undefined = categories.find((category: ICategory): boolean => category.title === newValue);
            newValue = {
                id: foundCategory?.id,
                title: newValue,
                tags: foundCategory?.tags
            }
        }

        const categoryToUpdate: ICategory = { ...newCategory, [key]: newValue } as ICategory;
        const allFieldsEmpty: boolean = Object.entries(categoryToUpdate)
            .filter(([key]) => key !== 'id')
            .every(([_, value]) => value === ConstantValues.EMPTY_STRING);

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages["new"];
            return newMessages;
        });

        if (allFieldsEmpty) {
            setHasChanges(false);
            setNewCategory({ id: 'new' } as ICategory);
            return;
        }

        setHasChanges(true);
        setNewCategory(categoryToUpdate);
    };

    const resetStates = (): void => {
        setIsEditing(false);
        setUpdatedCategory(null);
        setHasChanges(false);
        setOriginalCategory(null);
        setNewCategory({ id: 'new' } as ICategory);
        setErrorMessage(null);
        setValidationMessages({});
    };

    const getColumnActions = (column: ITableColumn<ICategory>): IColumnActionsMenuProps => {
        const isColumnSorted: boolean = sortColumn === column.columnId;
        const isColumnSortable: boolean = column.isSortable === undefined ? true : column.isSortable;

        return {
            columnKey: column.columnId.toString(),
            displayValue: column.displayValue,
            isSortable: isColumnSortable,
            sortingDirection: isColumnSorted ? (isSortDescending ? SortingDirection.Descending : SortingDirection.Ascending) : SortingDirection.None,
            onSortClicked: (columnKey: string, descending: boolean) => onSortColumnChanged(columnKey, descending),
            onClear: onClearColumnChanged,
        };
    };

    const getUpdatedColumns = (): ITableColumn<ICategory>[] => {
        return columns.map((column: ITableColumn<ICategory>): ITableColumn<ICategory> => {
            if (column.columnId) {
                column.renderHeaderCell = (): JSX.Element => {
                    if (column.columnId === "icon") {
                        return <React.Fragment />;
                    }

                    const customColumn: ITableColumn<ICategory> | undefined = columns.find((c: ITableColumn<ICategory>): boolean => c.columnId === column.columnId.toString());
                    if (!customColumn) {
                        return <React.Fragment />;
                    }

                    const actions: IColumnActionsMenuProps = getColumnActions(column);
                    return (
                        <Stack horizontal style={{ alignItems: 'center' }}>
                            <Label>{customColumn.displayValue}</Label>
                            {customColumn.required && (
                                <span style={{ color: 'red', marginLeft: '2px', fontSize: '12px' }}>*</span>
                            )}
                            <ColumnActionsMenu {...actions} />
                        </Stack>
                    );
                };
            }

            return column;
        });
    };

    const onDeleteConfirmed = async (): Promise<void> => {
        if (!updatedCategory) {
            return;
        }

        setShowDeleteDialog(false);

        try {
            await deleteCategory(updatedCategory.id);
            setCategories((prevCategories: ICategory[]): ICategory[] => prevCategories
                .filter((category: ICategory): boolean => category.id !== updatedCategory.id));
            resetStates();
            await queryClient.invalidateQueries('categories');
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const isUniqueCategory = (title: string): boolean => {
        return !categories.some((category) => category.title.toLowerCase() === title.toLowerCase());
    };

    const onSaveConfirmed = async (): Promise<void> => {
        setShowSaveDialog(false);

        try {
            if (updatedCategory) {
                setIsLoading(true);
                await updateCategory({ category: updatedCategory });
                setCategories((prevCategories): ICategory[] =>
                    prevCategories.map((category: ICategory): ICategory => (category.id === updatedCategory.id ? updatedCategory : category))
                );
                setIsLoading(false);
            } else if (newCategory.title) {
                setIsLoading(true);
                if (!isUniqueCategory(newCategory.title)) {
                    throw new Error(`The category title "${newCategory.title}" already exists.`);
                }

                const createCategoryBody: ICreateCategory = {
                    title: newCategory.title,
                    description: newCategory.description,
                    tags: newCategory.tags ?? []
                };
                await createCategory(createCategoryBody);
            }
        } catch (error) {
            console.error(error);
        } finally {
            await queryClient.invalidateQueries('categories');
            resetStates();
            setIsLoading(false);
        }
    };

    const onLoseEditChangesConfirmed = (): void => {
        setCategories((prevCategories: ICategory[]) =>
            prevCategories?.map((prevCategory: ICategory): ICategory => (prevCategory.id === originalCategory?.id ? originalCategory : prevCategory))
        );
        resetStates();
        setShowLoseEditChangesDialog(false);
    };

    const handleSearchChange = (event: SearchBoxChangeEvent, data: InputOnChangeData): void => {
        const query = data.value || ConstantValues.EMPTY_STRING;
        setSearchQuery(query);

        if (query === ConstantValues.EMPTY_STRING) {
            setFilteredCategories(categories);
        } else {
            setFilteredCategories(
                categories.filter(
                    (category: ICategory) => category.title.toLowerCase().includes(query.toLowerCase())
                )
            );
        }

        setSelectedPageIndex(0);
    };

    const getDeleteDialogContent = (): JSX.Element => (
        <ActionDialog
            isVisible={showDeleteDialog}
            title={"Delete Alert"}
            message={"Are you sure you want to delete this category?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onDeleteConfirmed}
            onDismiss={(): void => {
                setUpdatedCategory(null);
                setShowDeleteDialog(false)
            }}
        />
    );

    const getSaveDialogContent = (): JSX.Element => (
        <ActionDialog
            isVisible={showSaveDialog}
            title={"Save Alert"}
            message={"Are you sure you want to save this category?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onSaveConfirmed}
            onDismiss={(): void => setShowSaveDialog(false)}
        />
    );

    const getLoseEditChangesDialog = (): JSX.Element => (
        <ActionDialog
            isVisible={showLoseEditChangesDialog}
            title={"Edit Alert"}
            message={"Are you sure you want to lose the edit changes of the category?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onLoseEditChangesConfirmed}
            onDismiss={(): void => setShowLoseEditChangesDialog(false)}
        />
    );

    const isCategoryDisabledForEdit = (category: ICategory): boolean => {
        return (hasChanges && updatedCategory?.id !== category.id) || (!currentUser.isAdmin && category.userId !== currentAccount.id.toString());
    };

    const isCategoryDisabledForDelete = (category: ICategory): boolean => {
        return (hasChanges && updatedCategory?.id !== category.id) || (!currentUser.isAdmin && category.userId !== currentAccount.id.toString());
    };

    if (isLoading) {
        return <InputSkeleton />;
    }

    return (
        <Stack tokens={setGapBetweenHeadersAndDetailsList} className={containerClassName}>
            {showDeleteDialog && getDeleteDialogContent()}
            {showSaveDialog && getSaveDialogContent()}
            {showLoseEditChangesDialog && getLoseEditChangesDialog()}

            {errorMessage && (
                <MessageBar intent={"warning"}>
                    {errorMessage}
                </MessageBar>
            )}

            <SearchBox
                placeholder="Search by Title"
                value={searchQuery}
                onChange={handleSearchChange}
                style={searchBoxStyle}
            />

            <Toolbar style={{
                marginBottom: '10px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                padding: '10px 0'
            }}>
                <ToolbarButton
                    icon={<Add16Regular />}
                    disabled={!hasChanges}
                    onClick={handleSaveClick}
                >
                    Save
                </ToolbarButton>
            </Toolbar>

            <StackItem>
                <Table ref={tableRef} {...columnSizing_unstable.getTableProps()} noNativeElements={true}>
                    <TableHeader>
                        <TableRow>
                            {columns?.map((column: ITableColumn<ICategory>): JSX.Element => (
                                <TableHeaderCell key={column.columnId} {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}>
                                    {column.renderHeaderCell()}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {rows?.map((item: TableRowData<ICategory>): JSX.Element => (
                            <TableRow key={item.item.id}>
                                {getUpdatedColumns()?.map((column: ITableColumn<ICategory>): JSX.Element => (
                                    <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                        {getListViewCell(
                                            item.item,
                                            column,
                                            IsNullUndefinedOrEmpty(isEditing && updatedCategory?.id === item.item.id ? column.isEditableForEdit : column.isEditableForSave),
                                            (event, value) => handleCellChange(event, column.columnId as keyof ICategory, item.item.id)
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                                    <TableCellLayout>
                                        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center', padding: '8px 0' }}>
                                            <Button
                                                icon={<EditRegular />}
                                                appearance="outline"
                                                disabled={isCategoryDisabledForEdit(item.item)}
                                                onClick={(): void => handleEditClick(item.item)}
                                                aria-label="Edit"
                                                style={{ padding: '6px' }}
                                            />
                                            <Button
                                                icon={<DeleteRegular />}
                                                appearance="outline"
                                                disabled={isCategoryDisabledForDelete(item.item)}
                                                onClick={(): void => handleDeleteClick(item.item)}
                                                aria-label="Delete"
                                                style={{ padding: '6px' }}
                                            />
                                        </div>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            {columns?.map((column: ITableColumn<ICategory>): JSX.Element => (
                                <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                    {getListViewCell(
                                        newCategory,
                                        column,
                                        isEditing || updatedCategory !== null,
                                        (event, value) => handleNewTagChange(event, column.columnId as keyof ICategory)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </StackItem>
            <StackItem>{onRenderDetailsFooter()}</StackItem>
        </Stack >
    );
};