import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PromptsState {
  prompts: {
    Chat: any;
    TemporaryChat: any;
  };
}

const initialState: PromptsState = {
  prompts: {
    Chat: null,
    TemporaryChat: null,
  },
};

export const currentPromptSlice = createSlice({
  name: 'currentPrompt',
  initialState,
  reducers: {
    setCurrentPrompt: (
      state,
      action: PayloadAction<{ key: keyof PromptsState['prompts']; prompt: any }>
    ) => {
      const { key, prompt } = action.payload;
      state.prompts[key] = prompt;
    },
    resetCurrentPrompt: (
      state,
      action: PayloadAction<{ key: keyof PromptsState['prompts'] }>
    ) => {
      const { key } = action.payload;
      state.prompts[key] = null;
    },
  },
});

export const { setCurrentPrompt, resetCurrentPrompt } = currentPromptSlice.actions;

export default currentPromptSlice.reducer;