import { Button, Card, CardHeader, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Text, mergeClasses } from "@fluentui/react-components";
import { Delete16Regular, Edit16Regular, LockClosed16Regular, LockOpen16Regular, MoreVertical20Regular, Pin16Filled, Pin16Regular, Share16Regular } from "@fluentui/react-icons";
import { updateConversationTitle } from "api";
import { ConstantValues } from "common/constants";
import { IShareOptions } from "models/IShareOption";
import { IUser } from "models/IUser";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { NHColors } from "utils/generalUtils";
import { useStyles } from "../Conversations.styles";
import { SharingOptionsDialog } from "../sharingOptionsDialog/SharingOptionsDialog";
import { IConversationCardProps } from "./ConversationCard.types";

export const ConversationCard = (props: IConversationCardProps) => {
    const styles = useStyles();
    const [isSharingOptionsDialogOpen, setIsSharingOptionsDialogOpen] = useState<boolean>(false);
    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const [newTitle, setNewTitle] = useState<string>(props.conversation?.title || ConstantValues.EMPTY_STRING);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isRenaming && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, 0);
        }
    }, [isRenaming]);

    const toggleRename = (event: any) => {
        event.stopPropagation();
        setIsRenaming(!isRenaming);
    };

    const handleTitleChange = (event: any) => {
        setNewTitle(event.target.value);
    };

    const updateTitle = async () => {
        if (newTitle.trim() !== props.conversation?.title.trim()) {
            try {
                await updateConversationTitle({ conversation: props.conversation, title: newTitle });
                props.conversation.title = newTitle;
            } catch (e) {
                console.error(e);
            }
        }

        setIsRenaming(false);
    };

    const cancelRename = () => {
        setNewTitle(props.conversation?.title || ConstantValues.EMPTY_STRING);
        setIsRenaming(false);
    };

    const handleSelectionChange = async (): Promise<void> => {
        await props.selectConversation(props.conversation);
    };

    const handleDelete = async (event: any): Promise<void> => {
        event.stopPropagation();
        await props.removeConversation(props.conversation);
    };

    const handleSetPinned = async (event: any): Promise<void> => {
        event.stopPropagation();
        await props.setConversationPinned(props.conversation);
    };

    const stopSharing = async (event: any): Promise<void> => {
        event.stopPropagation();
        const updatedConversation = {
            ...props.conversation,
            shared: false,
            sharedIds: [],
            sharingType: 'everyone',
            labels: []
        };
        await props.setConversationSharing(updatedConversation);
    };

    const handleSharing = async (shareOptions?: IShareOptions): Promise<void> => {
        closeShareDialog();

        if (shareOptions) {
            const updatedConversation = {
                ...props.conversation,
                shared: shareOptions.shared,
                sharedIds: shareOptions.sharedIds,
                sharingType: shareOptions.sharingType,
                labels: shareOptions.labels,
                abstract: shareOptions.abstract
            };

            await props.setConversationSharing(updatedConversation);
        }

        closeShareDialog();
    };

    const openShareDialog = (event: any) => {
        event.stopPropagation();
        setIsSharingOptionsDialogOpen(true);
    };

    const closeShareDialog = () => {
        setIsSharingOptionsDialogOpen(false);
    };

    const getSharedBy = (): string => {
        if (!props.conversation || !props.conversation.shared) {
            return ConstantValues.EMPTY_STRING;
        }

        if (props.conversation?.userId === props.currentUser?.id) {
            return 'Shared by: Me';
        }

        const user: IUser | undefined = users.find((user: IUser): boolean => user.id === props.conversation?.userId);
        if (!user) {
            return ConstantValues.EMPTY_STRING;
        }

        return `Shared by: ${user.displayName}`;
    };

    const isCurrentUserOwner: boolean = props.conversation.userId === props.currentUser?.id;

    return (
        <>
            <Card className={styles.card}
                selected={props.currentlySelectedConversation && props.currentlySelectedConversation.id === props.conversation?.id}
                onSelectionChange={handleSelectionChange}
                size="large"
            >
                <CardHeader
                    header={
                        <div style={{ display: "flex", gap: "0.5vh", flexDirection: "row", }}>
                            {(props.conversation?.pinned || false) && isCurrentUserOwner &&
                                <Pin16Filled style={{ color: NHColors.white }} />
                            }
                            {isRenaming
                                ? <Input
                                    ref={inputRef}
                                    value={newTitle}
                                    onChange={handleTitleChange}
                                    onBlur={updateTitle}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") updateTitle();
                                        if (e.key === "Escape") cancelRename();
                                    }}
                                    autoFocus
                                    style={{
                                        fontSize: "1.25vh",
                                        fontWeight: "semibold",
                                        width: '12.5vw'
                                    }} />
                                : <Text style={{ fontSize: "1.25vh" }} weight="semibold">
                                    {props.conversation?.title}
                                </Text>
                            }
                        </div>
                    }
                    action={
                        <Menu>
                            <MenuTrigger>
                                {isCurrentUserOwner
                                    ? <Button
                                        className={styles.menuButton}
                                        appearance="subtle"
                                        icon={<MoreVertical20Regular />}
                                    />
                                    : null}
                            </MenuTrigger>
                            <MenuPopover>
                                {!isCurrentUserOwner
                                    ? <> </>
                                    : <MenuList>
                                        {isCurrentUserOwner && (
                                            <>
                                                <MenuItem
                                                    className={styles.menuItem}
                                                    icon={(props.conversation?.pinned || false) ? <Pin16Filled /> : <Pin16Regular />}
                                                    onClick={handleSetPinned}>
                                                    {props.conversation?.pinned ? 'Unpin' : 'Pin'}
                                                </MenuItem>

                                                <MenuItem
                                                    className={styles.menuItem}
                                                    icon={<Edit16Regular />}
                                                    onClick={toggleRename}
                                                >
                                                    Rename
                                                </MenuItem>

                                                {(!props.conversation?.shared || false)
                                                    ? <MenuItem
                                                        className={styles.menuItem}
                                                        icon={<Share16Regular />}
                                                        onClick={openShareDialog}>
                                                        Share
                                                    </MenuItem>
                                                    : <>
                                                        <MenuItem
                                                            className={styles.menuItem}
                                                            icon={<LockClosed16Regular />}
                                                            onClick={openShareDialog}>
                                                            Open Sharing Options
                                                        </MenuItem>
                                                        <MenuItem
                                                            className={styles.menuItem}
                                                            icon={<LockOpen16Regular />}
                                                            onClick={stopSharing}>
                                                            Stop Sharing
                                                        </MenuItem>
                                                    </>
                                                }
                                            </>
                                        )}
                                        <MenuItem
                                            className={styles.menuItem}
                                            icon={<Delete16Regular />}
                                            onClick={handleDelete}>
                                            Delete
                                        </MenuItem>
                                    </MenuList>
                                }
                            </MenuPopover>
                        </Menu>
                    }
                />

                <footer className={mergeClasses(styles.flex, styles.cardFooter)}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5vh" }}>
                        <Text style={{ fontSize: "1.1vh" }}>{new Date(props.conversation?.createdAt * 1000).toDateString()}</Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5vh" }}>
                        <Text style={{ fontSize: "1.2vh" }}>{getSharedBy()}</Text>
                    </div>
                </footer>
            </Card>
            {
                props.conversation &&
                isSharingOptionsDialogOpen &&
                <SharingOptionsDialog
                    conversation={props.conversation}
                    isOpen={isSharingOptionsDialogOpen}
                    onClose={closeShareDialog}
                    onShare={handleSharing}
                />
            }
        </>
    );
};