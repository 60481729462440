import { IDropdownOption } from "@fluentui/react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-components";
import { ICellProps } from "./Cell.types";

export const SingleSelectionCell = (props: ICellProps) => {
    const handleOptionSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
        props.onChange(event, data.optionValue as string);
    };

    const dropdownListStyle: React.CSSProperties = {
        maxHeight: "15vh",
        overflowY: "auto",
    };

    return (
        <Dropdown
            aria-labelledby="dropdown-label"
            onOptionSelect={handleOptionSelect}
            style={{ width: "100%" }}
        >
            <div style={dropdownListStyle}>
                {props.options?.map((option: IDropdownOption) => (
                    <Option key={option.key} value={option.key.toString()}>
                        {option.text}
                    </Option>
                ))}
            </div>
        </Dropdown>
    );
};