import { IPaginatorPositionProps } from "./PaginatorPosition.types";

export const PaginatorPosition = (props: IPaginatorPositionProps): JSX.Element => {
    const { selectedIndex, itemsPerPage, totalItemsCount } = props;

    const leftItemIndex: number = selectedIndex * itemsPerPage + 1;
    const rightItemsIndex: number = Math.min(
        (selectedIndex + 1) * itemsPerPage,
        totalItemsCount
    );
    const visibleItemLabel: string = `${leftItemIndex} - ${rightItemsIndex} of ${totalItemsCount}`;

    return <div aria-label={visibleItemLabel}>{visibleItemLabel}</div>;
};