import { SearchBox } from "@fluentui/react-components";
import { useStyles } from "../Conversations.styles";
import { IConversationsSearchProps } from "./ConversationsSearch.types";

export const ConversationsSearch = (props: IConversationsSearchProps): JSX.Element => {
    const styles = useStyles();

    return (
        <SearchBox
            className={styles.conversationsSearch}
            placeholder="Search..."
            onKeyDown={props.onEnterPress}
            onChange={props.resetSearchIfInputIsEmpty}
        />
    );
}