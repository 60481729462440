import {
    Button,
    Tooltip,
    makeStyles,
    tokens,
} from "@fluentui/react-components";
import { getCategories } from "api";
import { ProgressBarIndeterminate } from "components/ProgressBar";
import { ICategory } from "models/ICategory";
import { ITag } from "models/ITag";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { NHColors } from "utils/generalUtils";
import { setTagsForKey } from "../../redux/tags";
import { ICategoryTagTreeProps } from "./CategoryTagTree.types";

const useStyles = makeStyles({
    container: {
        overflowY: "auto",
        borderRadius: "8px",
    },
    categoryButton: {
        margin: "12px 8px 4px 0",
        display: "block",
    },
    tagButton: {
        margin: "6px 4px 4px 24px",
        display: "block",
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
    },
    selectedTagButton: {
        margin: "4px 4px 4px 24px",
        display: "block",
        backgroundColor: NHColors.gray,
        color: 'black',
    },
});

export const CategoryTagTree = (props: ICategoryTagTreeProps) => {
    const styles = useStyles();
    const { data: categories, isLoading, isError } = useQuery(
        "categories",
        async () => {
            const categories = await getCategories(true);
            return categories;
        },
        { refetchOnWindowFocus: false }
    );

    const currentTags = useSelector(
        (state: RootState) => state.currentTagsSlice.tags[props.dictKey] || []
    );
    const dispatch = useDispatch();

    const tagIds = currentTags?.length
        ? currentTags.map((tag: ITag) => tag.id)
        : [];

    const toggleTagSelection = (tag: any) => {
        const updatedTags = tagIds.includes(tag.id)
            ? currentTags.filter((currentTag: ITag) => currentTag.id !== tag.id)
            : [...currentTags, tag];

        dispatch(setTagsForKey({ key: props.dictKey, tags: updatedTags }));
    };

    if (isLoading) {
        return <ProgressBarIndeterminate />;
    }

    if (isError || !Array.isArray(categories)) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            {categories.map((category: ICategory) => (
                <div key={category.id}>
                    <Button
                        className={styles.categoryButton}
                        appearance="primary"
                        size="small"
                    >
                        {category.title}
                    </Button>
                    {category.tags.map((tag: any, tagIndex: number) => (
                        <Tooltip
                            key={tagIndex}
                            content={tag.description || "No description"}
                            relationship={"label"}
                        >
                            <Button
                                className={`${tagIds.includes(tag.id)
                                    ? styles.selectedTagButton
                                    : styles.tagButton
                                    }`}
                                appearance="secondary"
                                size="small"
                                onClick={() => toggleTagSelection(tag)}
                            >
                                {tag.name}
                            </Button>
                        </Tooltip>
                    ))}
                </div>
            ))}
        </div>
    );
};