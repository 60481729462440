import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { IsNullUndefinedOrEmpty } from "utils/generalUtils";
import { useStyles } from "./ActionDialog.styles";
import IDialogProperties from "./ActionDialog.types";

export const ActionDialog = (props: IDialogProperties): JSX.Element => {
    const styles = useStyles();

    return (
        <Dialog open={props.isVisible} onOpenChange={props.onDismiss}>
            <DialogSurface>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogBody>
                    {props.hasSpinner && !IsNullUndefinedOrEmpty(props.spinnerMessage) ? (
                        <Spinner style={{ marginTop: '20px' }} label={props.spinnerMessage} />
                    ) : (
                        <p>{props.message}</p>
                    )}
                </DialogBody>
                <DialogActions>
                    {props.onConfirmation && (
                        <Button appearance="primary" onClick={props.onConfirmation} className={styles.button}>
                            {props.confirmationMessage}
                        </Button>
                    )}
                    {props.onDismiss && (
                        <Button appearance="secondary" onClick={props.onDismiss}>
                            {props.dismissMessage}
                        </Button>
                    )}
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};