import { IStackTokens, mergeStyles } from '@fluentui/react';

export const containerClassName: string = mergeStyles({
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    paddingBottom: '10px'
});

export const setGapBetweenHeadersAndDetailsList: IStackTokens = {
    childrenGap: "0.5vh",
    padding: 0
};

export const selectPeriodDropdownClassName: React.CSSProperties = ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'flex-start'
})

export const cardContainerStyles = {
    display: 'flex',
    gap: '12px',
    width: '100%',
    justifyContent: 'center',
} as React.CSSProperties;

export const cardStyles = {
    width: '250px',
    padding: '10px',
} as React.CSSProperties;

export const cardContentStyles = {
    padding: '8px 0',
} as React.CSSProperties;


export const controlContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '12px',
    width: '300px',
};


export const iconStyles: React.CSSProperties = {
    position: 'absolute',
    top: '8px',
    right: '8px',
};