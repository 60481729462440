import { Configuration } from "@azure/msal-browser";
import { NEXT_PUBLIC_CLIENT_URL } from "config";

export const msalConfig: Configuration = {
    auth: {
        clientId: "85d6cb66-c83b-401d-862c-bf79e152b967",
        authority: "https://login.microsoftonline.com/5c572e77-1a4e-4518-b82d-617cad976e5f",
        redirectUri: NEXT_PUBLIC_CLIENT_URL
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
    scopes: ["85d6cb66-c83b-401d-862c-bf79e152b967/.default"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};