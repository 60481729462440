export enum ConstantValues {
    EMPTY_STRING = "",
    YES = "Yes",
    NO = "No",
    CHAT = 'Chat',
    TEMPORARY_CHAT = 'TemporaryChat',
    SORTING_OPTIONS_CACHE_KEY = 'sorting_options'
};

export const Constants = {
    NEXT_PUBLIC_CLIENT_URL: "http://localhost:3000/",
    MEMBERS_GROUP_NAME_KEY: "user",
    MEMBERS_GROUP_NAME_VALUE: "user",
    ADMINS_GROUP_NAME_KEY: "admin",
    ADMINS_GROUP_NAME_VALUE: "admin",
    ISSUE_URL: 'mailto:ai.genesis@northhighland.com',
};

export const capabilities: string[] = [
    "Analytics and AI",
    "Cloud Technology",
    "Infrastructure",
    "Business & Op Model Transformation",
    "Organizational Change Management",
    "Workforce Management",
    "IT Strategy and Governance",
    "Software Development and Quality",
    "IT Operations and Service Management",
    "Business Analysis and Architecture",
    "Risk and Compliance",
    "User Experience",
    "Customer Experience"
];