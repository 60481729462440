import { Subtitle1 } from "@fluentui/react-components"

export const ErrorPage = (): JSX.Element => {
    return (
        <div className="App" style={{
            flexDirection: "row",
            display: "flex",
            height: "100vh",
            justifyContent: "center"
        }}>
            <Subtitle1>You do not have access to this page, please contact support</Subtitle1>
        </div>
    )
}