import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConversationState {
  value: any;
}

const initialState: ConversationState = {
  value: null,
};

export const currentConversationSlice = createSlice({
  name: 'currentConversation',
  initialState,
  reducers: {
    setCurrentConversation: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    updateConversationTitle: (state, action: PayloadAction<string>) => {
      if (state.value) {
        state.value.title = action.payload;
      }
    },
  },
});

export const { setCurrentConversation, updateConversationTitle } = currentConversationSlice.actions;

export default currentConversationSlice.reducer;