import { IStackTokens, mergeStyles } from "@fluentui/react";

export const containerClassName: string = mergeStyles({
    display: "flex",
    flexDirection: "column",
    gap: "5px"
});

export const setGapBetweenHeadersAndDetailsList: IStackTokens = {
    childrenGap: "0.5vh",
    padding: 0
};

export const searchBoxStyle: React.CSSProperties = {
    width: "300px",
    margin: '10px 0'
};