import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroup } from 'models/IGroup';

interface GroupsState {
    value: IGroup[];
}

const initialState: GroupsState = {
    value: []
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups: (state, action: PayloadAction<IGroup[]>): void => {
            state.value = action.payload;
        },
        addGroup: (state, action: PayloadAction<IGroup>): void => {
            state.value.push(action.payload);
        },
        updateGroupFromSlice: (state, action: PayloadAction<IGroup>): void => {
            const index: number = state.value.findIndex((group: IGroup): boolean => group.id === action.payload.id);
            if (index !== -1) {
                state.value[index] = action.payload;
            }
        },
        removeGroup: (state, action: PayloadAction<string>): void => {
            state.value = state.value.filter((group: IGroup): boolean => group.id !== action.payload);
        }
    }
});

export const { setGroups, addGroup, updateGroupFromSlice, removeGroup } = groupsSlice.actions;

export default groupsSlice.reducer;