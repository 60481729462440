import { SelectTabData, SelectTabEvent, Tab, TabList, tokens } from "@fluentui/react-components";
import { getCategories, getGroups, getUsers } from "api";
import { ManageCategories } from "components/manageCategories/ManageCategories";
import { ManageGroups } from "components/manageGroups/ManageGroups";
import { ManageStatistics } from "components/manageStatistics/ManageStatistics";
import { ManageTags } from "components/manageTags/ManageTags";
import { ManageUsers } from "components/manageUsers/ManageUsers";
import { NavigationPanel } from "components/NavigationPanel";
import { ProgressBarIndeterminate } from "components/ProgressBar";
import { IGroup } from "models/IGroup";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setGroups } from "../redux/groups";
import { setUsers } from "../redux/users";
import { RootState } from "../store";

const styles = {
    container: {
        display: "flex",
        height: "100vh",
    } as React.CSSProperties,

    navPanel: (isNavOpen: boolean) => ({
        width: isNavOpen ? "25vw" : "5vw",
        display: "flex",
        flexWrap: "wrap",
        height: "100vh",
        backgroundColor: tokens.colorNeutralBackground2,
        overflowY: "auto",
    }) as React.CSSProperties,

    contentContainer: (isNavOpen: boolean) => ({
        display: "flex",
        flexDirection: "column",
        width: isNavOpen ? "80vw" : "95vw",
        height: "100vh",
        padding: "20px",
        boxSizing: "border-box",
        backgroundColor: tokens.colorNeutralBackground2,
        overflowY: "auto",
    }) as React.CSSProperties,

    tabListContainer: {
        display: "flex",
        justifyContent: "start",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid #ddd",
    } as React.CSSProperties,

    contentWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        overflowX: "auto",
    } as React.CSSProperties,
};

export const ManagementPage = (): JSX.Element => {
    const { data: categories, isLoading: areCategoriesLoading, refetch: refetchCategories, isRefetching: categoriesAreRefetching } =
        useQuery('categories', async () => {
            const categories = await getCategories(false);
            return categories;
        },
            {
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                refetchInterval: false,
                staleTime: 0,
                cacheTime: 5 * 60 * 1000,
            }
        );

    const dispatch = useDispatch();
    const user: any = useSelector((state: RootState) => state.currentUserSlice.value);
    const isAdmin = user?.isAdmin;
    const [selectedTabKey, setSelectedTabKey] = useState<string>("tags");
    const [isNavOpen, setIsNavOpen] = useState(true);

    useEffect((): void => {
        const fetchUsers = async () => {
            if (isAdmin) {
                const data = await getUsers();
                dispatch(setUsers(data));
            }
        };

        fetchUsers();
    }, [dispatch, isAdmin]);

    useEffect((): void => {
        const fetchGroups = async () => {
            const data: IGroup[] = await getGroups();

            dispatch(setGroups(data));
        };

        fetchGroups();
    }, [dispatch]);

    useEffect((): void => {
        if (selectedTabKey === "tags" || selectedTabKey === "categories") {
            refetchCategories();
        }
    }, [selectedTabKey, refetchCategories]);

    const handleTabChange = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTabKey(data.value as string);
    };

    const renderContent = (): JSX.Element | null => {
        switch (selectedTabKey) {
            case "tags":
                if (areCategoriesLoading || categoriesAreRefetching) {
                    return <ProgressBarIndeterminate />;
                }

                return <ManageTags key={selectedTabKey} categories={categories ?? []} />;
            case "categories":
                if (areCategoriesLoading || categoriesAreRefetching) {
                    return <ProgressBarIndeterminate />;
                }

                return <ManageCategories key={selectedTabKey} categories={categories ?? []} />;
            case "users":
                return isAdmin ? <ManageUsers /> : null;
            case "groups":
                return <ManageGroups />;
            case "statistics":
                return isAdmin ? <ManageStatistics /> : null;
            default:
                return null;
        }
    };

    return (
        <div className="App" style={styles.container}>
            <div style={styles.navPanel(isNavOpen)}>
                <NavigationPanel isOpen={isNavOpen} setIsOpen={setIsNavOpen} />
            </div>

            <div style={styles.contentContainer(isNavOpen)}>
                <div style={styles.tabListContainer}>
                    <TabList selectedValue={selectedTabKey} onTabSelect={handleTabChange} appearance="subtle">
                        <Tab value="tags">Manage Knowledge Components</Tab>
                        <Tab value="categories">Manage Categories</Tab>
                        {isAdmin && <Tab value="users">Manage Users</Tab>}
                        <Tab value="groups">Manage Groups</Tab>
                        {isAdmin && <Tab value="statistics">Manage Statistics</Tab>}
                    </TabList>
                </div>

                <div style={styles.contentWrapper}>{renderContent()}</div>
            </div>
        </div>
    );
};