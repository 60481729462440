import { makeStyles } from "@fluentui/react-components";

export const usePeoplePickerStyles = makeStyles({
    container: {
        minWidth: '100%'
    },
    truncatedText: {
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
    }
});