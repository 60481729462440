import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    AccordionToggleEventHandler,
    Button,
    Card,
    CardHeader,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Link,
    makeStyles,
    tokens
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { CategoryTagTree } from "components/categoryTagTree/CategoryTagTree";
import { TagWithAttachments } from "components/tagWithAttachments/TagWithAttachments";
import { IFile } from "models/IFile";
import { IMessage } from "models/IMessage";
import { ITag } from "models/ITag";
import { CSSProperties, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { downloadFile } from "utils/blobUtils";
import { IsNullUndefinedOrEmpty } from "utils/generalUtils";
import { resolveAsset } from "../../common/fileIcons";

const useStyles = makeStyles({
    accordionContainer: {
        position: "relative",
    },
    accordionPanel: {
        position: "absolute",
        top: "100%",
        left: "0",
        width: "300px",
        zIndex: 10,
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        padding: "16px",
    },
    headerContainer: {
        position: "sticky",
        top: "0",
        zIndex: 10,
        minHeight: "100px",
        width: "100%",
        padding: "10px 20px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        backgroundColor: tokens.colorNeutralBackground3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
    },
    tagsContainer: {
        flexWrap: "wrap",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        gap: "5px",
        maxWidth: "66%",
        overflowX: "auto",
        alignItems: "center",
    },
    tagStyle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        height: "32px",
        borderRadius: "4px",
    },
    drawerContent: {
        padding: "16px",
    },
    scrollableContainer: {
        overflowY: "auto",
        height: "calc(100vh - 60px)",
    },
    documentContainer: {
        display: "flex",
        flexDirection: "column",
        maxHeight: "200px",
        overflowY: "auto",
        padding: "8px",
    },
    documentCard: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        marginBottom: '10px',
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: "6px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    },
    documentText: {
        marginLeft: "8px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    buttonStyle: {
        width: "100%",
        height: "30px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 8px",
        boxSizing: "border-box",
    }
});

interface IStickyHeaderProps extends PropsWithChildren<{}> {
    sourceKey: string;
};

export const StickyHeader = (props: IStickyHeaderProps): JSX.Element => {
    const childrenAndCategoryContainerStyle: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        gap: IsNullUndefinedOrEmpty(props.children) ? '0px' : '10px',
        justifyContent: "flex-end",
        position: "relative"
    };

    const styles = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openItems, setOpenItems] = useState<string[]>([]);
    const accordionRef = useRef<HTMLDivElement>(null);

    const currentTags: ITag[] = useSelector(
        (state: RootState) => state.currentTagsSlice.tags[props.sourceKey] || []
    );
    const currentMessages: IMessage[] = useSelector(
        (state: RootState) => state.currentMessagesSetter.value
    );

    const documentOptions: IFile[] = useMemo(() => {
        return currentMessages
            .flatMap((message) => message.files || [])
            .filter((file, index, self) =>
                index === self.findIndex((f) => f.filename === file.filename)
            );
    }, [currentMessages]);

    const handleToggle: AccordionToggleEventHandler<string> = (_, data) => {
        setOpenItems(data.openItems);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                accordionRef.current &&
                !accordionRef.current.contains(event.target as Node)
            ) {
                setOpenItems([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.tagsContainer}>
                {currentTags.map((tag: ITag, index: number) => (
                    <div key={index} className={styles.tagStyle}>
                        <TagWithAttachments
                            sourceKey={props.sourceKey}
                            entity={tag}
                            entityType="tag"
                            secondaryActionFunction={true}
                            isEditable={false}
                            takeFilesFromEntity={true}
                            showTagName={true}
                        />
                    </div>
                ))}
            </div>
            {documentOptions.length > 0 && props.sourceKey !== ConstantValues.TEMPORARY_CHAT ? (
                <div className={styles.accordionContainer} ref={accordionRef}>
                    <Accordion
                        openItems={openItems}
                        onToggle={handleToggle}
                        multiple
                        collapsible
                    >
                        <AccordionItem value="usedDocuments">
                            <AccordionHeader>Used Documents</AccordionHeader>
                            <AccordionPanel className={styles.accordionPanel}>
                                <div className={styles.documentContainer}>
                                    {documentOptions.map((file: IFile, index: number) => (
                                        <Card key={index} size="small">
                                            <CardHeader
                                                image={{
                                                    as: "img",
                                                    src: resolveAsset(file?.filename),
                                                    alt: "Document icon",
                                                    width: "24px",
                                                    height: "24px"
                                                }}
                                                header={
                                                    <Link
                                                        onClick={downloadFile(file.url ?? ConstantValues.EMPTY_STRING)}
                                                        title={decodeURI(file?.filename?.split('/')?.at(-1) || ConstantValues.EMPTY_STRING)}
                                                        style={{
                                                            cursor: "pointer",
                                                            display: "inline-block",
                                                            maxWidth: "200px",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {decodeURI(file?.filename?.split('/')?.at(-1) || ConstantValues.EMPTY_STRING)}
                                                    </Link>
                                                } />
                                        </Card>
                                    ))}
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            ) : null}
            <div style={childrenAndCategoryContainerStyle}>
                {props.children}
                <Button
                    appearance="primary"
                    onClick={(): void => setIsDrawerOpen(!isDrawerOpen)}
                    className={styles.buttonStyle}
                >
                    Select Knowledge Components
                </Button>
            </div>
            <Drawer
                type="overlay"
                separator
                position="end"
                open={isDrawerOpen}
                onOpenChange={(_, { open }) => setIsDrawerOpen(open)}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => setIsDrawerOpen(false)}
                            />
                        }
                    >
                        Categories for {props.sourceKey === "Chat" ? "Main Chat" : "Temporary Chat"}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className={styles.drawerContent}>
                        <CategoryTagTree allOpen={true} dictKey={props.sourceKey} />
                    </div>
                </DrawerBody>
            </Drawer>
        </div>
    );
};