import { Stack, StackItem } from "@fluentui/react";
import {
    InputOnChangeData,
    Label,
    SearchBox,
    SearchBoxChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableRowData,
    TableSelectionCell,
    tokens,
    Toolbar,
    ToolbarButton,
    useTableColumnSizing_unstable,
    useTableFeatures,
    useTableSelection
} from "@fluentui/react-components";
import { Add16Regular, Delete16Regular, Document16Regular, Edit16Regular } from "@fluentui/react-icons";
import { exportToExcel, removeUserFromGroups } from "api";
import { Constants, ConstantValues } from "common/constants";
import { ActionDialog } from "components/actionDialog/ActionDialog";
import { ColumnActionsMenu } from "components/columnActionsMenu/ColumnActionsMenu";
import { IColumnActionsMenuProps } from "components/columnActionsMenu/ColumnActionsMenu.types";
import { Paginator } from "components/paginator/Paginator";
import { ProgressBarIndeterminate } from "components/ProgressBar";
import { SortingDirection } from "enums/SortingOrder";
import { IColumnSizingOption } from "models/IColumnSizingOptions";
import { IUser } from "models/IUser";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ITableColumn } from "../../models/ITableColumn";
import { updateUser } from "../../redux/users";
import { IsNullOrUndefined, renderCellValue } from "../../utils/generalUtils";
import { EditDialog } from "./editDialog/EditDialog";
import { containerClassName, searchBoxStyle, setGapBetweenHeadersAndDetailsList } from "./ManageUsers.styles";

const columnSizingOptions: Partial<Record<keyof IUser, IColumnSizingOption>> = {
    displayName: {
        idealWidth: 500,
        minWidth: 400,
    },
    mail: {
        idealWidth: 500,
        minWidth: 400,
    },
    groups: {
        idealWidth: 150,
        minWidth: 100,
    },
};

export const ManageUsers = (): JSX.Element => {
    const columns: ITableColumn<IUser>[] = useMemo(
        () => [
            {
                columnId: "displayName",
                displayValue: "Display Name",
                renderHeaderCell: () => "Name",
                renderCell: (item: IUser) => item.displayName,
                compare: function (a: IUser, b: IUser): number {
                    return a.displayName.localeCompare(b.displayName);
                }
            },
            {
                columnId: "mail",
                displayValue: "Mail",
                renderHeaderCell: () => "Mail",
                renderCell: (item: IUser) => item.mail,
                compare: function (a: IUser, b: IUser): number {
                    return a.mail.localeCompare(b.mail);
                }
            },
            {
                columnId: "groups",
                displayValue: "Groups",
                renderHeaderCell: () => "Groups",
                renderCell: (item: IUser) => item.groups?.join(", ") || "No group",
                compare: function (a: IUser, b: IUser): number {
                    const groupA: string = a.groups?.join(", ") || ConstantValues.EMPTY_STRING;
                    const groupB: string = b.groups?.join(", ") || ConstantValues.EMPTY_STRING
                    return groupA.localeCompare(groupB);
                },
            },
        ],
        []
    );

    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState<string>(ConstantValues.EMPTY_STRING);

    const [allUsersWithAzGroups, setAllUsersWithAzGroups] = useState<IUser[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [isSortDescending, setIsSortDescending] = useState<boolean>(false);
    const [isExportingToExcel, setIsExportingToExcel] = useState<boolean>(false);
    const [exportMessage, setExportMessage] = useState<string>(ConstantValues.EMPTY_STRING);
    const [deleting, setDeleting] = useState<boolean>(false);

    const itemsPerPage: number = 10;
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const itemsStartIndex: number = selectedPageIndex * itemsPerPage;
    const itemsEndIndex: number = (selectedPageIndex + 1) * itemsPerPage;
    const isSelectedUserAdmin: boolean = selectedUser?.groups?.includes(Constants.ADMINS_GROUP_NAME_KEY) || false;
    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value);
    const userPrincipalName: string = currentUser?.userPrincipalName || currentUser.upn;
    const currentUserIsSelected: boolean = selectedUser?.userPrincipalName === userPrincipalName;
    const pageCount: number = useMemo((): number => {
        return Math.ceil(filteredUsers.length / itemsPerPage);
    }, [filteredUsers]);

    const paginatedUsers: IUser[] = filteredUsers.slice(itemsStartIndex, itemsEndIndex);

    const { getRows, columnSizing_unstable, tableRef, selection: { toggleRow, isRowSelected } } = useTableFeatures(
        {
            columns,
            items: paginatedUsers,
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
                autoFitColumns: false,
            }),
            useTableSelection({
                selectionMode: "single",
            }),
        ]
    );

    useEffect(() => {
        if (isDeleteDialogOpen) {
            return;
        }

        if (users.length > 0 && isLoading && !isDeleteDialogOpen) {
            setIsLoading(false);
        }

        if (!isLoading && users.length === 0) {
            setIsLoading(true);
        }
    }, [users, isLoading]);

    useEffect((): void => {
        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        if (savedSortingOptions) {
            const parsedOptions = JSON.parse(savedSortingOptions);
            const listSortingOptions = parsedOptions['Users'];

            if (listSortingOptions) {
                setSortColumn(listSortingOptions.column);
                setIsSortDescending(listSortingOptions.direction === 'desc');
            }
        }
    }, []);

    useEffect((): void => {
        const usersWithGroups: IUser[] = users.filter((user: IUser) => user.groups && user.groups.length > 0);
        setAllUsersWithAzGroups(usersWithGroups);
        setFilteredUsers(usersWithGroups);
    }, [users]);

    useEffect((): void => {
        let updatedUsers = [...allUsersWithAzGroups];

        if (sortColumn) {
            const column: ITableColumn<IUser> | undefined = columns.find((col: ITableColumn<IUser>): boolean => col.columnId === sortColumn);
            if (column && column.compare) {
                updatedUsers.sort((a: IUser, b: IUser): number => column.compare(a, b) * (isSortDescending ? -1 : 1));
            }
        }

        setFilteredUsers(updatedUsers);
    }, [allUsersWithAzGroups, sortColumn, isSortDescending]);

    const rows = getRows((row: TableRowData<IUser>) => {
        const selected: boolean = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent): void => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    e.preventDefault();
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const onPageChange = (index: number): void => setSelectedPageIndex(index);

    const onRenderDetailsFooter = (): JSX.Element => (
        <div style={{ position: "sticky", bottom: 0, backgroundColor: tokens.colorNeutralBackground2, padding: "10px", textAlign: "center" }}>
            {pageCount > 1 && (
                <Paginator
                    selectedIndex={selectedPageIndex}
                    pageCount={pageCount}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={allUsersWithAzGroups.length}
                    displayPosition={true}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );

    const handleRowClick = (user: IUser): void => {
        setSelectedUser(user.id === selectedUser?.id ? null : user);
    };

    const handleSearchChange = (event: SearchBoxChangeEvent, data: InputOnChangeData): void => {
        if (IsNullOrUndefined(data.value)) {
            return;
        }

        const query: string = data.value;
        setSearchQuery(query);

        if (query === ConstantValues.EMPTY_STRING) {
            setFilteredUsers(allUsersWithAzGroups);
        } else {
            setFilteredUsers(
                allUsersWithAzGroups.filter(
                    (user: IUser) =>
                        user.displayName?.toLowerCase().includes(query.toLowerCase()) ||
                        user.mail?.toLowerCase().includes(query.toLowerCase())
                )
            );
        }

        setSelectedPageIndex(0);
    };

    const handleDeleteUser = async (): Promise<void> => {
        if (selectedUser) {
            try {
                setDeleting(true);
                await removeUserFromGroups(selectedUser.id);
                setSearchQuery(ConstantValues.EMPTY_STRING);
                dispatch(updateUser({ ...selectedUser, groups: [] }));
                setIsDeleteDialogOpen(false);
                dispatch(updateUser({ ...selectedUser, groups: [] }));
                setSelectedUser(null);
                setDeleting(false);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleUpdateUsers = (updatedUser: IUser) => {
        setFilteredUsers((prevUsers) => prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
    };

    const onSortColumnChanged = (columnKey: string, descending: boolean) => {
        setSortColumn(columnKey);
        setIsSortDescending(descending);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        sortingOptions['Users'] = {
            column: columnKey,
            direction: descending ? 'desc' : 'asc'
        };

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const onClearColumnChanged = () => {
        setSortColumn(null);
        setIsSortDescending(false);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        delete sortingOptions['Users'];

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const getColumnActions = (column: ITableColumn<IUser>): IColumnActionsMenuProps => {
        const isColumnSorted: boolean = sortColumn === column.columnId;
        const isColumnSortable: boolean = column.isSortable === undefined ? true : column.isSortable;

        return {
            columnKey: column.columnId.toString(),
            displayValue: column.displayValue,
            isSortable: isColumnSortable,
            sortingDirection: isColumnSorted ? (isSortDescending ? SortingDirection.Descending : SortingDirection.Ascending) : SortingDirection.None,
            onSortClicked: (columnKey: string, descending: boolean) => onSortColumnChanged(columnKey, descending),
            onClear: onClearColumnChanged
        };
    };

    const getUpdatedColumns = (): ITableColumn<IUser>[] => {
        return columns.map((column: ITableColumn<IUser>): ITableColumn<IUser> => {
            if (column.columnId) {
                column.renderHeaderCell = (): JSX.Element => {
                    const customColumn: ITableColumn<IUser> | undefined = columns.find((c: ITableColumn<IUser>): boolean => c.columnId === column.columnId.toString());
                    if (!customColumn) {
                        return <React.Fragment />;
                    }

                    const actions: IColumnActionsMenuProps = getColumnActions(column);
                    return (
                        <Stack horizontal style={{ alignItems: 'center' }}>
                            <Label>{customColumn.displayValue} </Label>
                            <ColumnActionsMenu {...actions} />
                        </Stack>
                    );
                };
            }

            return column;
        });
    };

    const handleExportToExcel = async (): Promise<void> => {
        setIsExportingToExcel(true);
    };

    const getExportToDialogActionDialog = (): JSX.Element => (
        <ActionDialog
            hasSpinner={true}
            spinnerMessage={exportMessage}
            isVisible={isExportingToExcel}
            title={"Export to Excell Alert"}
            message={"Are you sure you want to export users to Excel?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onExportToExcelConfirmed}
            onDismiss={(): void => setIsExportingToExcel(false)}
        />
    );

    const onExportToExcelConfirmed = async () => {
        setExportMessage("Exporting to Excel...");
        try {
            await exportToExcel();
            setExportMessage("Exported to Excel successfully!");
            setTimeout(() => {
                setIsExportingToExcel(false);
                setExportMessage(ConstantValues.EMPTY_STRING);
            }, 1000);
        } catch (e) {
            console.error(e);
            setIsExportingToExcel(false);
        }
    };

    return (
        <Stack tokens={setGapBetweenHeadersAndDetailsList} className={containerClassName}>
            <>
                {isExportingToExcel && getExportToDialogActionDialog()}

                {isLoading
                    ? <ProgressBarIndeterminate />
                    : <> <SearchBox
                        placeholder="Search by name or email"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={searchBoxStyle}
                    />
                        <Toolbar>
                            <ToolbarButton icon={<Add16Regular />} disabled={!!selectedUser} onClick={(): void => setIsEditPopupOpen(true)}>
                                Create
                            </ToolbarButton>
                            <ToolbarButton icon={<Edit16Regular />} disabled={!selectedUser} onClick={(): void => setIsEditPopupOpen(true)}>
                                Update
                            </ToolbarButton>
                            <ToolbarButton icon={<Delete16Regular />} disabled={!selectedUser || isSelectedUserAdmin || currentUserIsSelected} onClick={() => setIsDeleteDialogOpen(true)}>
                                Delete
                            </ToolbarButton>
                            <ToolbarButton icon={< Document16Regular />} onClick={handleExportToExcel}>
                                Export to Excel
                            </ToolbarButton>
                        </Toolbar>
                        <StackItem>
                            {isEditPopupOpen &&
                                <EditDialog
                                    users={users}
                                    user={selectedUser}
                                    isOpen={isEditPopupOpen}
                                    onDismiss={(isLoading?: boolean): void => {
                                        setIsEditPopupOpen(false);
                                        setIsLoading(isLoading || false);
                                        setTimeout(() => { setIsLoading(false); }, 1000);
                                    }}
                                    onUpdateUser={handleUpdateUsers}
                                />
                            }
                            <Table ref={tableRef} {...columnSizing_unstable.getTableProps()} noNativeElements={true}>
                                <TableHeader >
                                    <TableRow>
                                        {getUpdatedColumns().map((column: ITableColumn<IUser>): JSX.Element => (
                                            <TableHeaderCell
                                                key={column.columnId}
                                                {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}
                                            >
                                                {column.renderHeaderCell()}
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {rows.map((row: TableRowData<IUser>): JSX.Element => (
                                        <TableRow key={row.item.id} appearance={row.item.id === selectedUser?.id ? "brand" : "none"} onClick={(): void => handleRowClick(row.item)}>
                                            <TableSelectionCell type="checkbox" checked={row.item.id === selectedUser?.id} checkboxIndicator={{ "aria-label": "Select row" }} />
                                            {columns.map((column: ITableColumn<IUser>): JSX.Element =>
                                                <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                                    <React.Fragment>
                                                        {column.renderCell ? column.renderCell(row.item) : renderCellValue<IUser>(row.item, column.columnId as keyof IUser)}
                                                    </React.Fragment>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </StackItem>

                        {isDeleteDialogOpen && (
                            <ActionDialog
                                hasSpinner={true}
                                spinnerMessage={deleting ? "Deleting user" : ConstantValues.EMPTY_STRING}
                                isVisible={isDeleteDialogOpen}
                                title={"Delete Alert"}
                                onConfirmation={handleDeleteUser}
                                onDismiss={() => setIsDeleteDialogOpen(false)}
                                confirmationMessage={"Yes"}
                                dismissMessage={"No"}
                                message={"Are you sure you want to delete this user?"} />
                        )}
                        <StackItem>{onRenderDetailsFooter()}</StackItem>
                    </>
                }
            </>
        </Stack>
    );
};