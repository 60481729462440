import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrandVariants, createLightTheme, FluentProvider } from "@fluentui/react-components";
import { Authentication } from "components/authentication/Authentication";
import { GuidelinesPage } from "pages/guidelines";
import { HelpPage } from "pages/help";
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NHColors } from "utils/generalUtils";
import { UserAuthGuard } from "./components/authguard/UserAuthGuard";
import './index.css';
import { ChatPage } from './pages/chat';
import { ErrorPage } from './pages/error';
import { ManagementPage } from './pages/management';
import { msalConfig } from "./services/msalConfig";

const myNewTheme: BrandVariants = {
    10: "#000308",
    20: "#0B1729",
    30: "#17253D",
    40: "#26304A",
    50: "#343C56",
    60: "#424863",
    70: "#51546F",
    80: "#60617C",
    90: "#6F6E88",
    100: "#7F7C95",
    110: "#8E8AA1",
    120: "#9D98AD",
    130: "#ADA7BA",
    140: "#BCB5C6",
    150: "#CBC5D2",
    160: "#DAD4DF"
};

const lightTheme = {
    ...createLightTheme(myNewTheme),
};

lightTheme.colorBrandForeground1 = NHColors.primary;
lightTheme.colorBrandForeground2 = NHColors.primary;
lightTheme.colorCompoundBrandStroke = NHColors.primary;
lightTheme.colorCompoundBrandBackground = NHColors.primary
lightTheme.colorCompoundBrandStroke = NHColors.primary
lightTheme.colorCompoundBrandForeground1 = NHColors.primary
lightTheme.colorBrandBackground = NHColors.primary

const router = createBrowserRouter([
    {
        path: "/",
        element: <UserAuthGuard component={<ChatPage />} />
    },
    {
        path: "/management",
        element: <UserAuthGuard component={<ManagementPage />} />
    },
    {
        path: "/error",
        element: <ErrorPage />
    },
    {
        path: "/guidelines",
        element: <GuidelinesPage />
    },
    {
        path: "/help",
        element: <HelpPage />
    },
]);

export const App = (): JSX.Element => {
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
    const currentTheme = lightTheme;

    useEffect((): void => {
        const instance: PublicClientApplication = new PublicClientApplication(msalConfig);
        instance.initialize().then(() => {
            setMsalInstance(instance);
        }).catch((error: any) => {
            console.error('Failed to initialize MSAL instance:', error);
        });
    }, []);

    return (
        <FluentProvider theme={currentTheme}>
            {msalInstance && (
                <MsalProvider instance={msalInstance}>
                    <Authentication >
                        <RouterProvider router={router} />
                    </Authentication>
                </MsalProvider>
            )}
        </FluentProvider>
    );
};