import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LLMType } from 'enums/LLMType';

interface LLMConfig {
  llmType: string;
  rag: boolean;
}

interface LLMConfigState {
  config: {
    [key: string]: LLMConfig;
  };
}

const initialState: LLMConfigState = {
  config: {},
};

export const currentLLMConfigSlice = createSlice({
  name: 'currentLLMConfig',
  initialState,
  reducers: {
    setNewConfigForKey: (state, action: PayloadAction<{ key: string; config: LLMConfig }>) => {
      const { key, config } = action.payload;
      if (!state.config[key]) {
        state.config[key] = { llmType: LLMType.OPENAI, rag: false };
      }

      state.config[key] = config;
    },
  },
});

export const { setNewConfigForKey } = currentLLMConfigSlice.actions;

export default currentLLMConfigSlice.reducer;