import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'models/IUser';

interface UsersState {
    value: IUser[];
}

const initialState: UsersState = {
    value: [],
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<IUser[]>) => {
            state.value = action.payload;
        },
        addUser: (state, action: PayloadAction<IUser>) => {
            state.value.push(action.payload);
        },
        updateUser: (state, action: PayloadAction<IUser>) => {
            const index = state.value.findIndex(user => user.id === action.payload.id);
            if (index !== -1) {
                state.value[index] = action.payload;
            }
        },
        removeUser: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(user => user.id !== action.payload);
        },
    },
});

export const { setUsers, addUser, updateUser, removeUser } = usersSlice.actions;

export default usersSlice.reducer;