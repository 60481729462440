import { addValueToAIGenerateMessage } from "../redux/aiGeneratedMessage";
import { ConstantValues } from "./constants";

export async function getChunkAndUpdateAIMessage(
    response,
    readableStream,
    reduxDispatched = null,
    reactSetState = null,
    setLoadingState = null
) {
    const reader = readableStream.pipeThrough(new TextDecoderStream()).getReader();
    let lastMessageValue = null;

    let done, value;
    let sourceDocuments = null;
    let chunks = ConstantValues.EMPTY_STRING;
    let chunkNr = 0;

    try {
        while (!done) {
            ({ value, done } = await reader.read());

            value = value?.trim();

            if (chunkNr === 0 && setLoadingState) setLoadingState(false);

            chunkNr++;

            if (done) {
                if (setLoadingState) setLoadingState(false);
                break;
            }

            chunks += value;

            let newMessage;

            try {
                let lines = chunks.split(/\n/);
                lines = lines.filter(l => l.trim().length);
                let lastMessage = lines[lines.length - 1].trim();
                lastMessage = lastMessage.split("}{");
                lastMessage = lastMessage[lastMessage.length - 1].trim()
                if (lastMessage[0] !== "{") lastMessage = "{" + lastMessage
                newMessage = JSON.parse(lastMessage);
            } catch (e) {
                console.error("Error parsing chunk:", e);
                continue;
            }

            newMessage.message = newMessage?.message?.replace('```', ConstantValues.EMPTY_STRING)?.replace('`', ConstantValues.EMPTY_STRING);

            lastMessageValue = newMessage?.message;

            if (newMessage?.hasFinished) sourceDocuments = newMessage?.files;

            if (reduxDispatched) reduxDispatched(addValueToAIGenerateMessage(newMessage?.message));
            if (reactSetState) reactSetState(newMessage?.message);
        }
    } catch (error) {
        console.error("Error while reading stream:", error);
        if (setLoadingState) setLoadingState(false);
        return { content: null, sourceDocuments: null };
    }

    try {
        let latestLines = chunks.split(/\n/);
        latestLines = latestLines.filter(l => l.trim().length);

        if (latestLines.length > 0) {
            let lastMessage = latestLines[latestLines.length - 1].trim();

            try {
                lastMessage = lastMessage.split("}{");
                lastMessage = lastMessage[lastMessage.length - 1].trim()
                if (lastMessage[0] !== "{") lastMessage = "{" + lastMessage
                lastMessage = JSON.parse(lastMessage);
            } catch (err) {
                console.error("Error parsing final message:", err);
                throw new Error("Failed to parse the last message");
            }

            lastMessageValue = lastMessage?.message?.replace('```', ConstantValues.EMPTY_STRING)?.replace('`', ConstantValues.EMPTY_STRING);
            sourceDocuments = lastMessage?.files;
        }
    } catch (finalError) {
        console.error("Error processing final chunks:", finalError);
    }

    return { content: lastMessageValue, sourceDocuments };
}
