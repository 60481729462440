import { makeStyles } from "@fluentui/react-components";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    tag: {
        '--colorBrandBackground2': NHColors.primary,
        color: 'white',
        ":hover": {
            '--colorCompoundBrandForeground1Hover': 'white',
            color: 'white',
            cursor: 'pointer',

        }
    }
});