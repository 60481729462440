import { initializeIcons, setIconOptions } from '@fluentui/react';
import { App } from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';

const queryClient: QueryClient = new QueryClient();

initializeIcons();
setIconOptions({ disableWarnings: true });

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient} contextSharing>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();