import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConstantValues } from 'common/constants';

interface AIGenerateState {
  value: string;
}

const initialState: AIGenerateState = {
  value: ConstantValues.EMPTY_STRING,
};

export const aiGenerateSlice = createSlice({
  name: 'currentConversation',
  initialState,
  reducers: {
    addValueToAIGenerateMessage: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    resetAIGeneratedSlice: (state) => {
      state.value = ConstantValues.EMPTY_STRING;
    },
  },
});

export const { addValueToAIGenerateMessage, resetAIGeneratedSlice } = aiGenerateSlice.actions;

export default aiGenerateSlice.reducer;