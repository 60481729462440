import { Card, CardHeader, InteractionTag, InteractionTagPrimary, InteractionTagSecondary, Popover, PopoverSurface, PopoverTrigger, TagSize, Text, Tooltip } from "@fluentui/react-components";
import { ConstantValues } from "common/constants";
import { resolveAsset } from "common/fileIcons";
import { AttachmentsHandler } from "components/attachmentsHandler/AttachmentsHandler";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";
import { ITag } from "models/ITag";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { addAttachment, removeAttachment } from "../../redux/currentAttachments";
import { setTagsForKey } from "../../redux/tags";
import { getInteractionTagStyle, interactionTagSummaryStyle, popoperSurfaceStyle, useStyles } from "./TagWithAttachments.styles";
import { ITagsWithAttachmentsProps } from "./TagWithAttachments.types";

export const TagWithAttachments = <T extends { id: string, name: string; attachments?: IFile[]; description?: string; displayName?: string }>(
    props: ITagsWithAttachmentsProps<T>
) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const currentTags: ITag[] = useSelector((state: RootState) => state.currentTagsSlice.tags[props.sourceKey ?? ConstantValues.CHAT] || []);
    let currentAttachments = useSelector((state: RootState) => state.currentAttachments.value);
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

    const attachmentsToShow = props.takeFilesFromEntity && props.entity.attachments
        ? props.entity.attachments.map(attachment => ({
            file: new File([], attachment.filename),
            id: attachment.id,
        }))
        : currentAttachments;

    const deselectTopic = (topic: any) => (): void => {
        const currentTopicCopy: ITag[] = currentTags.filter((t: any): boolean => t?.id !== topic.id);
        dispatch(setTagsForKey({ key: props.sourceKey ?? ConstantValues.CHAT, tags: currentTopicCopy }));
    };

    const handleFilesChange = (initialFiles: FileWithId[], updatedFiles: FileWithId[], remove = false): void => {
        if (!remove) {
            updatedFiles.forEach((file: FileWithId) => dispatch(addAttachment(file)));
        }

        if (!props.onAttachmentsChange) {
            return;
        }

        props.onAttachmentsChange(props.entity.id, initialFiles, updatedFiles, remove);
    };

    const handleFileRemove = (filename: string): void => {
        dispatch(removeAttachment(filename));
    };

    const handlePopoverToggle = (): void => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    return (
        <Tooltip
            content={
                <div className={styles.tooltip}>
                    <strong>{props.entity.name}</strong>
                    <br />
                    <br />
                    {props.entity.description || "No description available"}
                </div>
            }
            relationship="description"
        >
            <InteractionTag size={props.size as TagSize} appearance="brand">
                <Popover trapFocus open={isPopoverOpen} onOpenChange={(e, data): void => setIsPopoverOpen(data.open)}>
                    <PopoverTrigger>
                        <InteractionTagPrimary
                            style={getInteractionTagStyle(props.secondaryActionFunction)}
                            hasSecondaryAction={props.secondaryActionFunction}
                            onClick={handlePopoverToggle}
                        >
                            {props.showTagName
                                ? <Text style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100px",
                                    display: "inline-block"
                                }}>{props.entity.name}
                                </Text>
                                : <Text>{"Edit attachments..."}</Text>}
                        </InteractionTagPrimary>
                    </PopoverTrigger>
                    {props.isEditable || props.takeFilesFromEntity
                        ? <PopoverSurface className={styles.popover}>
                            <div style={popoperSurfaceStyle}>
                                <AttachmentsHandler
                                    takesFilesFromEntity={props.takeFilesFromEntity}
                                    onFilesChange={handleFilesChange}
                                    entityType={props.entityType}
                                    entity={props.entity}
                                    downloadFiles={props.entity.id === "new" || props.entity.id === ConstantValues.EMPTY_STRING ? false : true}
                                />
                                <p>{props.entity.description}</p>
                            </div>
                        </PopoverSurface>
                        : <PopoverSurface className={styles.popover}>
                            <div style={popoperSurfaceStyle}>
                                {attachmentsToShow.length > 0 &&
                                    <span style={{ color: '#666', fontWeight: 'bold' }}>Content:</span>
                                }
                                {attachmentsToShow.map((file: FileWithId): JSX.Element => (
                                    <Card size="small" role="listitem" key={file.file.name}>
                                        <CardHeader
                                            image={{ as: "img", src: resolveAsset(file.file.name, file), alt: file.file.name, width: "40px", height: "40px" }}
                                            header={<Text size={100} weight="semibold">{file.file.name}</Text>}
                                            action={
                                                props.isEditable ? (
                                                    <Tooltip content="Remove" relationship="label">
                                                        <InteractionTagSecondary
                                                            style={interactionTagSummaryStyle}
                                                            onClick={() => handleFileRemove(file.file.name)}
                                                        />
                                                    </Tooltip>
                                                ) : undefined
                                            }
                                        />
                                    </Card>
                                ))}
                                <p style={{ color: '#666' }}>
                                    <span style={{ fontWeight: 'bold' }}>Owner</span>: {props.entity.displayName}
                                </p>

                                <p style={{ color: '#666', marginTop: '-5px' }}>
                                    <span style={{ fontWeight: 'bold' }}>Description</span>: {props.entity.description}
                                </p>
                            </div>
                        </PopoverSurface>
                    }
                </Popover>
                {props.secondaryActionFunction
                    ? <Tooltip content="Dismiss" relationship="label">
                        <InteractionTagSecondary style={interactionTagSummaryStyle} onClick={deselectTopic(props.entity)} />
                    </Tooltip>
                    : <Fragment />
                }
            </InteractionTag>
        </Tooltip>
    );
};