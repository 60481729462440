import { IIconProps } from "@fluentui/react";
import { CSSProperties } from "react";
import { NHColors } from "utils/generalUtils";

export const firstPageIconProps: IIconProps = {
    iconName: "Previous",
    styles: {
        root: {
            color: NHColors.primary
        }
    }
};

export const previousPageIconProps: IIconProps = {
    iconName: "CaretSolidLeft",
    styles: {
        root: {
            color: NHColors.primary
        }
    }
};

export const nextPageIconProps: IIconProps = {
    iconName: "CaretSolidRight",
    styles: {
        root: {
            color: NHColors.primary
        }
    }
};

export const lastPageIconProps: IIconProps = {
    iconName: "Next",
    styles: {
        root: {
            color: NHColors.primary
        }
    }
};

export const stackStyle: CSSProperties = {
    paddingTop: 10,
    paddingBottom: 10
};