import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserIdentity } from '../../api';
import { setCurrentUser } from '../../redux/currentUser';

export const UserAuthGuard = ({ component }: { component: any }): JSX.Element => {
  const [hasAccess, setHasAccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect((): any => {
    checkToken();
  }, [component]);

  const checkToken = async () => {
    try {
      let user = null;
      let admin = null;

      try {
        user = await getUserIdentity({ role: "user" });
      } catch (userError) {
        console.warn("User identity not found:", userError);
      }

      try {
        admin = await getUserIdentity({ role: "admin" });
      } catch (adminError) {
        console.warn("Admin identity not found:", adminError);
      }

      if (!user && !admin) {
        navigate(`/error`);
        return;
      }

      if (admin && admin.id) {
        user = { ...admin, isAdmin: true };
      } else if (user && user.id) {
        user.isAdmin = false;
      }

      setHasAccess(true);
      dispatch(setCurrentUser(user));

    } catch (error) {
      console.error("Unexpected error:", error);
      navigate(`/error`);
    }
  }


  return hasAccess ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
};