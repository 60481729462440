import { tokens } from "@fluentui/react-components"
import { useSelector } from "react-redux"
import { RootState } from 'store'
import '../App.css'
import { NavigationPanel } from "../components/NavigationPanel"
import { Chat } from "../components/chat/Chat"
import { TemporaryChat } from "../components/chat/TemporaryChat"
import { Conversations } from '../components/conversations/Conversations'

const styles = {
  container: {
    flexDirection: "row",
    display: "flex",
    height: "100vh",
    width: "100%",
  } as React.CSSProperties,

  innerContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "stretch",
    width: "100%",
  } as React.CSSProperties,

  navigationPanelWrapper: {
    display: "flex",
    flexWrap: "wrap",
  } as React.CSSProperties,

  chatContainer: {
    display: "flex",
    width: "100%",
  } as React.CSSProperties,

  chatWrapper: (isOpen: boolean) => ({
    flexBasis: isOpen ? "50%" : "100%",
  }) as React.CSSProperties,

  temporaryChatWrapper: {
    width: "50%",
    backgroundColor: tokens.colorNeutralBackground2,
    padding: "0px 10px 0px 0px",
    display: "flex",
    justifyContent: "stretch",
    height: "100%",
    flexWrap: 'nowrap',
  } as React.CSSProperties,
};

export const ChatPage = (): JSX.Element => {
  const temporaryChatIsOpen: boolean = useSelector((state: RootState) => state.temporaryChatSlice.isOpen)

  return (
    <div className="App" style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.navigationPanelWrapper}>
          <NavigationPanel >
            <Conversations />
          </NavigationPanel>
        </div>
        <div style={styles.chatContainer}>
          <div style={styles.chatWrapper(temporaryChatIsOpen)}>
            <Chat />
          </div>
          {temporaryChatIsOpen && (
            <div style={styles.temporaryChatWrapper}>
              <TemporaryChat />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}