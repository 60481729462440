import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
    Spinner,
    Textarea
} from "@fluentui/react-components";
import { createGroup, updateGroup } from "api";
import { ConstantValues } from "common/constants";
import { PeoplePicker } from "components/fields/peoplePicker/PeoplePicker";
import { CustomOption } from "components/fields/peoplePicker/PeoplePicker.types";
import { IUser } from "models/IUser";
import { IUserDetails } from "models/IUserDetails";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { mapAzureUsersToModel } from "utils/generalUtils";
import { addGroup, updateGroupFromSlice } from '../../../redux/groups';
import { confirmationButtonStyles, useStyles } from "./EditDialog.styles";
import { IEditDialogProps } from "./EditDialog.types";

export const EditDialog = (props: IEditDialogProps): JSX.Element => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>(ConstantValues.EMPTY_STRING);
    const [description, setDescription] = useState<string>(ConstantValues.EMPTY_STRING);
    const [selectedUsers, setSelectedUsers] = useState<CustomOption[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dispatch = useDispatch();

    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);

    useEffect((): void => {
        if (props.selectedGroup) {
            setName(props.selectedGroup.name);
            setDescription(props.selectedGroup.description);

            const mappedUsers = props.selectedGroup.users.map((user: IUserDetails) => ({
                key: user.userId,
                text: user.displayName,
            }));
            setSelectedUsers(mappedUsers);
        } else {
            setName(ConstantValues.EMPTY_STRING);
            setDescription(ConstantValues.EMPTY_STRING);
            setSelectedUsers([]);
        }
    }, [props.selectedGroup]);

    const readResourcesForPickerFieldFiltered = async (searchedValue: string): Promise<any[]> => {
        return new Promise((resolve) => {
            const mappedUsers = mapAzureUsersToModel(users);
            const filteredResources = mappedUsers.filter((resource: any) =>
                resource.Name?.toLowerCase().includes(searchedValue.toLowerCase())
            );

            resolve(filteredResources);
        });
    };

    const handleSelectedUsers = (selectedOptions: CustomOption[]): void => {
        setSelectedUsers(selectedOptions);
    };

    const handleSave = async (ev: React.FormEvent): Promise<void> => {
        ev.preventDefault();
        setErrorMessage(null);

        if (!name.trim() || !description.trim() || selectedUsers.length === 0) {
            setErrorMessage("All fields are required. Please fill out the name, description, and members.");
            return;
        }

        if (props.groups.some((group) => group.name === name && group.id !== props.selectedGroup?.id)) {
            setErrorMessage("A group with this name already exists. Please choose a different name.");
            return;
        }

        setIsLoading(true);

        const payload = {
            name: name.trim(),
            description: description.trim(),
            users: selectedUsers.map((user: CustomOption): IUserDetails => ({
                userId: user.id ? user.id : ConstantValues.EMPTY_STRING,
                displayName: user.text
            }))
        };

        try {
            if (props.selectedGroup) {
                await updateGroup({
                    ...props.selectedGroup,
                    groupId: props.selectedGroup.id,
                    name: payload.name,
                    description: payload.description,
                    users: payload.users,
                });
                dispatch(updateGroupFromSlice({
                    ...props.selectedGroup,
                    name: payload.name,
                    description: payload.description,
                    users: payload.users,
                }));
            } else {
                const newGroup = await createGroup(payload);
                dispatch(addGroup(newGroup));
            }

            props.onDismiss(true);
        } catch (error) {
            console.error("Error saving group:", error);
            setErrorMessage("An error occurred while saving the group. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={props.isOpen} modalType="alert">
            <DialogSurface aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle>{props.selectedGroup ? "Update Group" : "Create Group"}</DialogTitle>
                    <DialogContent className={styles.content}>
                        {isLoading
                            ? <Spinner {...props} label={props.selectedGroup ? "Updating group..." : "Creating group..."} />
                            : <>
                                <Field label={"Name"} required>
                                    <Input
                                        id={"name"}
                                        value={name}
                                        onChange={(e): void => setName(e.target.value)}
                                    />
                                </Field>
                                <Field label={"Description"} required>
                                    <Textarea
                                        id={"description"}
                                        value={description}
                                        onChange={(e): void => setDescription(e.target.value)}
                                        resize="vertical"
                                    />
                                </Field>
                                <Field label={"Members"} required>
                                    <PeoplePicker
                                        id="members"
                                        handleSelectedItems={handleSelectedUsers}
                                        required={true}
                                        readResourcesForPickerFieldFiltered={readResourcesForPickerFieldFiltered}
                                        hasOnlyOurUsers={true}
                                        selectedOptions={selectedUsers}
                                    />
                                </Field>

                                {errorMessage && (
                                    <div style={{ color: "red", marginTop: "10px" }}>
                                        {errorMessage}
                                    </div>
                                )}
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => props.onDismiss(false)}>Close</Button>
                        </DialogTrigger>
                        <Button
                            type="submit"
                            appearance="primary"
                            onClick={handleSave}
                            style={confirmationButtonStyles}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};