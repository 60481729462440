import { FontSizes } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import React from "react";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    popover: {
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
        borderRadius: "7.5px",
        marginBottom: '10px'
    },
    tooltip: {
        color: NHColors.primary,
        fontSize: FontSizes.medium,
        padding: '10px'
    },
});

export const getInteractionTagStyle = (hasSecondaryFunction: boolean): React.CSSProperties => {
    return {
        background: NHColors.secondary,
        color: NHColors.white,

        marginBottom: !hasSecondaryFunction ? '0px' : '4px',
        borderRadius: !hasSecondaryFunction ? '5px' : undefined,
        height: !hasSecondaryFunction ? '95%' : '100%'
    }
};

export const popoperSurfaceStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
};

export const interactionTagSummaryStyle: React.CSSProperties = {
    marginBottom: '4px',
    backgroundColor: NHColors.secondary,
    color: 'white',
    borderBottomRightRadius: '6px',
};