import { Field, makeStyles, ProgressBar } from "@fluentui/react-components";

const DEFAULT_COLOR: string = "#616161";

const useStyles = makeStyles({
  fieldWithDefaultColor: {
    "--colorNeutralForeground3": DEFAULT_COLOR,
  },
  fieldWithWhiteColor: {
    "--colorNeutralForeground3": "white",
  }
});

export const ProgressBarIndeterminate = ({ text = "Loading...", color = DEFAULT_COLOR }): JSX.Element => {

  const styles = useStyles();
  return (
    <Field validationMessage={text} validationState="none" className={color === DEFAULT_COLOR ? styles.fieldWithDefaultColor : styles.fieldWithWhiteColor} >
      <ProgressBar />
    </Field>
  );
};