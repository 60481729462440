import { mergeStyles } from "@fluentui/react";
import { Button, Card, CardHeader, Spinner, Text, tokens, Tooltip } from "@fluentui/react-components";
import { Delete12Regular } from "@fluentui/react-icons";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { NHColors } from "utils/generalUtils";
import { deletePrompt, getPrompts } from "../../api";
import { setCurrentPrompt } from "../../redux/selectedPrompt";
import { ProgressBarIndeterminate } from "../ProgressBar";
import { containerStyle } from "./Prompts.styles";
import { IPromptsProps } from "./Prompts.types";

export const Prompts = (props: IPromptsProps): JSX.Element => {
    const { data: prompts, isLoading, isError, refetch } = useQuery('prompts', async () => {
        const prompts = await getPrompts();
        return prompts;
    }, { refetchOnWindowFocus: false });

    const [isDeleting, setIsDeleting] = useState<string | null>(null);
    const dispatch = useDispatch();
    const selectedPrompt: any = useSelector((state: RootState) => state.currentPromptSlice.prompts[props.dictKey as keyof typeof state.currentPromptSlice.prompts]);

    const promptRef = useRef<HTMLDivElement>(null);

    const selectPrompt = (prompt: any) => () => {
        dispatch(setCurrentPrompt({ key: props.dictKey, prompt: prompt }));
        if (props.onPromptSelect) {
            props.onPromptSelect();
        }
    };

    const removePrompt = (prompt: any) => async () => {
        setIsDeleting(prompt.id);
        await deletePrompt({ promptId: prompt?.id });
        refetch();
        setIsDeleting(null);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (promptRef.current && !promptRef.current.contains(event.target as Node)) {
                props.onPromptSelect();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.onPromptSelect]);

    if (isLoading) {
        return <ProgressBarIndeterminate />;
    }

    if (isError || !Array.isArray(prompts)) {
        return <></>;
    }

    const cardClassName: string = mergeStyles({
        background: 'white',
        ":hover": {
            background: tokens.colorNeutralBackground1Hover,
        },
    });

    const buttonClassName: string = mergeStyles({
        padding: '0',
        background: 'white',
        ":hover": {
            background: tokens.colorNeutralBackground1Hover,
        },
        height: '35px',
        minWidth: '40px',
        marginRight: '5px',
    });

    return (
        <div style={containerStyle} ref={promptRef}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {prompts.length === 0
                    ? "No Prompts"
                    : prompts?.map((prompt: any, index: number): JSX.Element => (
                        <Card
                            style={{
                                backgroundColor: selectedPrompt?.id === prompt.id
                                    ? 'white'
                                    : 'white',
                                cursor: 'pointer'
                            }}
                            className={cardClassName}
                            key={index}
                            onClick={selectPrompt(prompt)}
                            floatingAction={
                                <Button
                                    style={{ border: 'none' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removePrompt(prompt)();
                                    }}
                                    className={buttonClassName}
                                >
                                    {props.canManagePrompt
                                        ? <>
                                            {isDeleting === prompt.id
                                                ? <Spinner size="tiny" />
                                                : <Delete12Regular style={{ border: '0', height: '15px', width: '15px' }} />
                                            }
                                        </>
                                        : <></>}
                                </Button>
                            }
                        >
                            <CardHeader
                                header={
                                    <Tooltip content={prompt?.title} relationship="description">
                                        <Text
                                            weight="semibold"
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "120px",
                                            }}
                                        >
                                            {prompt?.title}
                                        </Text>
                                    </Tooltip>
                                }
                            />
                        </Card>
                    ))
                }
            </div>
            {props.canManagePrompt &&
                <Button onClick={props.onAddPrompt} appearance="primary" style={{ marginTop: "10px", backgroundColor: NHColors.primary }}>
                    Add new Prompt
                </Button>
            }
        </div>
    );
};