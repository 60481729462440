import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label } from "@fluentui/react-components";
import { Prompt16Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { Prompts } from "components/prompts/Prompts";
import { useRef, useState } from "react";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { NHColors } from "utils/generalUtils";
import { createPrompt } from "../../../api";
import { setCurrentPrompt } from "../../../redux/selectedPrompt";
import { IAddPromptProps } from "./AddPrompt.types";

export const AddPrompt = (props: IAddPromptProps): JSX.Element => {
  const [title, setTitle] = useState<string>(ConstantValues.EMPTY_STRING);
  const [openPromptList, setOpenPromptList] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(ConstantValues.EMPTY_STRING);
  const queryClient: QueryClient = useQueryClient();

  const buttonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

  const mutationWrapper = async ({ title, description }: { title: string; description: string }): Promise<void> => {
    const response = await createPrompt({ title, description });
    return response;
  };

  const mutation = useMutation({ mutationFn: mutationWrapper, onSuccess: () => queryClient.invalidateQueries({ queryKey: ['prompts'] }) });
  const dispatch = useDispatch();

  const handleSubmit = async (ev: any): Promise<void> => {
    if (!title || !props.description) {
      setErrorMessage("Some required fields are missing!");
      setTimeout(() => setErrorMessage(ConstantValues.EMPTY_STRING), 3000);
      return;
    }

    if (props.data.some((prompt: any) => prompt.title === title)) {
      setErrorMessage("Prompt with the same title already exists!");
      setTimeout((): void => setErrorMessage(ConstantValues.EMPTY_STRING), 3000);
      return;
    }

    mutation.mutate({ title, description: props.description });
    setTitle(ConstantValues.EMPTY_STRING);
    setOpenDialog(false);
  };

  const updateCurrentText = (ev: any): void => {
    props.setDescription(ev?.target?.value);
  };

  const updateTitle = (ev: any): void => {
    setTitle(ev?.target?.value);
  };

  const handleCloseClick = (): void => {
    setOpenDialog(false);
    setTitle(ConstantValues.EMPTY_STRING);
    props.setDescription(ConstantValues.EMPTY_STRING);
    dispatch(setCurrentPrompt({ key: props.dictKey, prompt: null }));
  };

  const handlePromptButtonClick = (): void => {
    setOpenPromptList(!openPromptList);
  };

  const handlePromptSelect = (): void => {
    setOpenPromptList(false);
  };

  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Button
        ref={buttonRef}
        style={{ display: "flex" }}
        onClick={handlePromptButtonClick}
        icon={<Prompt16Regular />}
      >
        Prompts
      </Button>

      {openPromptList && (
        <div
          style={{
            position: "absolute",
            bottom: '100%',
            backgroundColor: "white",
            width: "250px",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
            maxHeight: '200px',
            overflowY: 'auto'
          }}
        >
          <Prompts
            dictKey={props.dictKey}
            canManagePrompt={props.canManagePrompt}
            onAddPrompt={() => setOpenDialog(true)}
            onPromptSelect={handlePromptSelect}
          />
        </div>
      )}

      <Dialog modalType="alert" onOpenChange={() => setOpenDialog(!openDialog)} open={openDialog}>
        <DialogSurface aria-describedby={undefined}>
          <form>
            <DialogBody>
              <DialogTitle>Create a title for this prompt</DialogTitle>
              <DialogContent style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                {errorMessage && <span style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</span>}
                <Label required htmlFor={"title-input"}>Title</Label>
                <Input placeholder="Title" required id={"title-input"} value={title} onChange={updateTitle} />
                <Label required htmlFor={"message-input"}>Message</Label>
                <Input placeholder="Message text" required id={"message-input"} value={props.description} onChange={updateCurrentText} />
              </DialogContent>
              <DialogActions>
                <Button appearance="secondary" onClick={handleCloseClick}>Close</Button>
                <Button appearance="primary" style={{ backgroundColor: NHColors.primary }} onClick={handleSubmit}>
                  Submit
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    </div>
  );
};