import { Input, InputOnChangeData } from "@fluentui/react-components";
import { ICellProps } from "./Cell.types";

export const TextFieldCell = (props: ICellProps) => {
    if (!props.editable) {
        return <span>{props.value}</span>;
    }

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
        props.onChange(ev, data.value);
    };

    return <Input
        value={props.value}
        onChange={onChange}
    />
};