import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    Label,
    Option,
    OptionOnSelectData,
    SelectionEvents,
    Spinner,
} from "@fluentui/react-components";
import { Constants } from "common/constants";
import { PeoplePicker } from "components/fields/peoplePicker/PeoplePicker";
import { CustomOption } from "components/fields/peoplePicker/PeoplePicker.types";
import { IUser } from "models/IUser";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IsNullUndefinedOrEmpty, mapAzureUsersToModel, mapAzureUserToModel } from "utils/generalUtils";
import { addUserToGroup, removeUserFromGroup } from "../../../api";
import { setUsers, updateUser } from "../../../redux/users";
import { confirmationButtonStyles, useStyles } from "./EditDialog.styles";
import { IEditDialogProps } from "./EditDialog.types";

export const EditDialog = (props: IEditDialogProps): JSX.Element => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<CustomOption[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dispatch = useDispatch();

    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);

    const roleOptions = [
        { key: Constants.ADMINS_GROUP_NAME_KEY, text: Constants.ADMINS_GROUP_NAME_VALUE },
        { key: Constants.MEMBERS_GROUP_NAME_KEY, text: Constants.MEMBERS_GROUP_NAME_VALUE }
    ];

    useEffect((): void => {
        if (props.user) {
            const selectedUser: CustomOption = mapAzureUserToModel(props.user);
            setSelectedUsers([selectedUser]);

            const initialRoles = props.user.groups || [];
            setSelectedRoles(initialRoles);
        } else {
            setSelectedUsers([]);
            setSelectedRoles([Constants.MEMBERS_GROUP_NAME_KEY]);
            setFilteredUsers(filterUsersByRoles([Constants.MEMBERS_GROUP_NAME_KEY]));
        }
    }, [props.user]);

    const filterUsersByRoles = (roles: string[]) => {
        return users.filter((user: IUser): boolean => !roles.some((role: string): boolean | undefined => user.groups?.includes(role)));
    };

    const updateUserRoles = async (currentUser: IUser, selectedRoles: string[]) => {
        const currentRoles: string[] = currentUser.groups || [];
        let hasChanges: boolean = false;

        for (const role of currentRoles) {
            if (!selectedRoles.includes(role)) {
                hasChanges = true;
                await removeUserFromGroup(role, currentUser.id);
            }
        }

        for (const role of selectedRoles) {
            if (!currentRoles.includes(role)) {
                hasChanges = true;
                await addUserToGroup(role, currentUser.id);
            }
        }

        return hasChanges;
    };

    const onRoleChange = (event: SelectionEvents, data: OptionOnSelectData) => {
        const newRole = data.optionValue ?? Constants.MEMBERS_GROUP_NAME_KEY;

        setSelectedRoles((prevSelectedRoles) => {
            let updatedRoles;
            if (prevSelectedRoles.includes(newRole)) {
                updatedRoles = prevSelectedRoles.filter((role: string): boolean => role !== newRole);
            } else {
                updatedRoles = [...prevSelectedRoles, newRole];
            }

            const filtered: IUser[] = filterUsersByRoles(updatedRoles);
            setFilteredUsers(filtered);
            return updatedRoles;
        });

        setErrorMessage(null);
    };

    const handleSelectedUsers = (selectedOptions: CustomOption[]): void => {
        setSelectedUsers(selectedOptions);
    };

    const handleSave = async (ev: React.FormEvent): Promise<void> => {
        setIsLoading(true);

        if (selectedRoles.length === 0) {
            setErrorMessage("At least one role must be selected.");
            setIsLoading(false);
            return;
        }

        if (selectedUsers.length === 0) {
            setErrorMessage("At least one user must be selected.");
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        try {
            selectedUsers.forEach(async (option: any): Promise<void> => {
                const user: IUser = {
                    id: option?.id,
                    userPrincipalName: option?.upn,
                    displayName: option?.displayName,
                    mail: option?.mail,
                    groups: option?.groups
                }
                const hasChanges: boolean = await updateUserRoles(user, selectedRoles);
                if (hasChanges) {
                    const foundUser: IUser | undefined = users.find((u: IUser): boolean => u?.mail === user.mail)
                    if (foundUser) {
                        dispatch(updateUser({ ...user, groups: selectedRoles }))
                    }
                    else {
                        dispatch(setUsers([...users, { ...user, groups: selectedRoles }]))
                    }
                }
            });

        } catch (error) {
            console.error("Error updating user group:", error);
        } finally {
            setTimeout(() => {
                props.onDismiss(true);
                setIsLoading(false);
            }, 1000)
        }
    };

    const readResourcesForPickerFieldFiltered = async (searchedValue: string): Promise<any[]> => {
        return new Promise((resolve) => {
            const mappedUsers = mapAzureUsersToModel(filteredUsers);
            const filteredResources = mappedUsers.filter((resource: any) =>
                resource.Name?.toLowerCase().includes(searchedValue.toLowerCase())
            );

            resolve(filteredResources);
        });
    };

    return (
        <Dialog open={props.isOpen} modalType="alert">
            <DialogSurface aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle>{props.user ? "Update User" : "Create User"}</DialogTitle>
                    <DialogContent className={styles.content}>
                        {isLoading
                            ? <Spinner {...props} label={"Updating user group..."} />
                            : <>
                                <Label required htmlFor={"user"}>
                                    User
                                </Label>
                                <PeoplePicker
                                    id="user"
                                    handleSelectedItems={handleSelectedUsers}
                                    disabled={!IsNullUndefinedOrEmpty(props.user)}
                                    required={true}
                                    readResourcesForPickerFieldFiltered={readResourcesForPickerFieldFiltered}
                                    selectedOptions={selectedUsers}
                                />

                                <Label required htmlFor={"user-role"}>
                                    Role
                                </Label>
                                <Dropdown
                                    multiselect={true}
                                    id={"user-role"}
                                    value={selectedRoles.join(', ')}
                                    aria-labelledby="dropdown-label"
                                    selectedOptions={selectedRoles}
                                    onOptionSelect={onRoleChange}
                                >
                                    {roleOptions.map((option): JSX.Element => (
                                        <Option
                                            key={option.key.toString()}
                                            value={option.key.toString()}
                                            disabled={option.key === Constants.MEMBERS_GROUP_NAME_KEY && !props.user}
                                        >
                                            {option.text}
                                        </Option>
                                    ))}
                                </Dropdown>

                                {errorMessage && (
                                    <div style={{ color: "red", marginTop: "10px" }}>
                                        {errorMessage}
                                    </div>
                                )}
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => props.onDismiss(false)}>Close</Button>
                        </DialogTrigger>
                        <Button type="submit" appearance="primary" onClick={handleSave} style={confirmationButtonStyles}>
                            Save
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};