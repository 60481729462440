import { Button, Checkbox, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Text, Tooltip } from "@fluentui/react-components";
import { DismissCircle20Regular, Filter16Regular } from "@fluentui/react-icons";
import { capabilities } from "common/constants";
import { useEffect, useState } from "react";
import { NHColors } from "utils/generalUtils";
import { useStyles } from "../Conversations.styles";
import { IFilterCapabilitiesMenuProps } from "./FilterCapabilitiesMenu.types";

export const FilterCapabilitiesMenu = (props: IFilterCapabilitiesMenuProps): JSX.Element => {
    const [selectedCapabilities, setSelectedCapabilities] = useState<string[]>([]);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const styles = useStyles();

    useEffect((): void => {
        const savedCapabilities: string | null = localStorage.getItem("selectedCapabilities");
        if (savedCapabilities) {
            setSelectedCapabilities(JSON.parse(savedCapabilities));
            props.filterByCapabilities(JSON.parse(savedCapabilities));
        }
    }, []);

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleMenuToggle = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsMenuOpen((prev: boolean): boolean => !prev);
    };

    const clearFilters = async (): Promise<void> => {
        setSelectedCapabilities([]);
        localStorage.removeItem("selectedCapabilities");
        await props.filterByCapabilities([]);
    };

    const handleCapabilityChange = async (capability: string): Promise<void> => {
        const newSelectedCapabilities: string[] = selectedCapabilities.includes(capability)
            ? selectedCapabilities.filter((c: string): boolean => c !== capability)
            : [...selectedCapabilities, capability];

        setSelectedCapabilities(newSelectedCapabilities);
        localStorage.setItem("selectedCapabilities", JSON.stringify(newSelectedCapabilities));
        await props.filterByCapabilities(newSelectedCapabilities);
    };

    return <Menu open={isMenuOpen} onOpenChange={(e, data) => !data.open && handleMenuClose()}>
        <MenuTrigger disableButtonEnhancement>
            <Button
                icon={<Filter16Regular className={styles.capabilityFilter} style={{ color: selectedCapabilities.length > 0 ? NHColors.primary2 : 'inherit' }} />}
                className={styles.capabilityFilter}
                appearance="subtle"
                aria-label="Filter Capabilities"
                onClick={handleMenuToggle}
            />
        </MenuTrigger>
        <MenuPopover style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <MenuList>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px" }}>
                    <Text weight="semibold">
                        Filter capabilities ({selectedCapabilities.length})
                    </Text>
                    <Tooltip content="Clear all filters" relationship="label">
                        <Button
                            icon={<DismissCircle20Regular />}
                            className={styles.clearFiltersButton}
                            appearance="transparent"
                            onClick={clearFilters}
                            disabled={selectedCapabilities.length === 0}
                            aria-label="Clear Filters"
                        />
                    </Tooltip>
                </div>

                {capabilities.map((capability): JSX.Element => (
                    <MenuItem key={capability}>
                        <Checkbox
                            label={`${capability}`}
                            checked={selectedCapabilities.includes(capability)}
                            onChange={(): Promise<void> => handleCapabilityChange(capability)}
                        />
                    </MenuItem>
                ))}
            </MenuList>
        </MenuPopover>
    </Menu>
};