import { makeStyles, Tooltip } from "@fluentui/react-components";
import { ICellProps } from "./Cell.types";

const useStyles = makeStyles({
    cell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "block",
        width: "50%",
    },
});

export const ReadonlyCell = (props: ICellProps): JSX.Element => {
    const styles = useStyles();

    return (
        <Tooltip content={props.value} relationship="description">
            <span className={styles.cell} title={props.value}>
                {props.value}
            </span>
        </Tooltip>
    );
};