import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage } from 'models/IMessage';

interface MessagesState {
  value: IMessage[];
}

const initialState: MessagesState = {
  value: [],
};

export const currentMessagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setCurrentMessages: (state, action: PayloadAction<IMessage[]>) => {
      state.value = action.payload;
    },
    addMessageToCurrentMessages: (state, action: PayloadAction<IMessage>) => {
      state.value.push(action.payload);
    },
  },
});

export const { setCurrentMessages, addMessageToCurrentMessages } = currentMessagesSlice.actions;

export default currentMessagesSlice.reducer;