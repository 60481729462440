import { Fragment } from "react";
import { ColumnTypes } from "../../enums/ColumnTypes";
import { ICellProps } from "./Cell.types";
import { ReadonlyCell } from "./ReadonlyCell";
import { SingleSelectionCell } from "./SingleSelectionCell";
import { TextFieldCell } from "./TextFieldCell";

export const CellFactory = (props: ICellProps) => {
    const cellProperties: ICellProps = {
        value: props.value,
        editable: props.editable,
        onChange: props.onChange,
        type: props.type,
        options: props.options
    };
    let result: JSX.Element;

    switch (props.type) {
        case ColumnTypes.SingleLineText:
            result = <TextFieldCell {...cellProperties} />;
            break;
        case ColumnTypes.SingleSelection:
            result = <SingleSelectionCell {...cellProperties} />;
            break
        case ColumnTypes.Attachments:
            result = <span>No Attachments</span>;
            break;
        default:
            result = <ReadonlyCell {...cellProperties} />;
    }

    return <Fragment>{result}</Fragment>;
};