import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemporaryChatState {
  isOpen: boolean;
  initialMessage: any;
}

const initialState: TemporaryChatState = {
  isOpen: false,
  initialMessage: null,
};

export const temporaryChatSlice = createSlice({
  name: 'temporaryChatState',
  initialState,
  reducers: {
    setTemporaryChatOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setInitialMessage: (state, action: PayloadAction<any>) => {
      state.initialMessage = action.payload;
    },
  },
});

export const { setInitialMessage, setTemporaryChatOpen } = temporaryChatSlice.actions;

export default temporaryChatSlice.reducer;