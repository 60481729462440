import { AuthenticationResult, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ConstantValues } from "common/constants";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { loginRequest } from "../../services/msalConfig";
import { setCurrentAccount } from "../../utils/generalUtils";
import { IAuthenticationProps } from "./Authentication.types";

export const Authentication = (props: IAuthenticationProps): JSX.Element => {
    const isAuthenticated: boolean = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [isInteractionInProgress, setIsInteractionInProgress] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>(ConstantValues.EMPTY_STRING);

    const REFRESH_THRESHOLD: number = 300;
    const TOKEN_CHECK_INTERVAL: number = 60000;

    useEffect(() => {
        const checkTokenExpiry = () => {
            const backendAccessToken: string | null = localStorage.getItem('token');
            if (backendAccessToken) {
                const decodeToken = jwtDecode(backendAccessToken);

                const currentTime: number = Math.floor(Date.now() / 1000);
                if (decodeToken.exp === undefined) {
                    return;
                }

                if (currentTime > decodeToken.exp) {
                    const request: SilentRequest = {
                        ...loginRequest,
                        account: accounts[0]
                    };
                    instance.acquireTokenRedirect(request).catch(err => {
                        console.error("Redirect failed: ", err);
                        setIsInteractionInProgress(false);
                    });
                }

                const timeUntilExpiry: number = decodeToken.exp - currentTime;
                if (timeUntilExpiry <= REFRESH_THRESHOLD) {
                    acquireTokenWithRefreshToken();
                }
            }
        };

        const interValid: NodeJS.Timer = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
        checkTokenExpiry();
        return () => clearInterval(interValid);
    }, [])

    useEffect((): void => {
        const initializeAuth = async () => {
            if (accounts.length > 0 && !isInteractionInProgress) {
                setCurrentAccount(accounts[0]);

                const request: SilentRequest = {
                    ...loginRequest,
                    account: accounts[0]
                };

                try {
                    const response: AuthenticationResult = await instance.acquireTokenSilent(request);
                    localStorage.setItem("token", response?.accessToken);
                    setAccessToken(response?.accessToken);
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        setIsInteractionInProgress(true);
                        instance.acquireTokenRedirect(request).catch(err => {
                            console.error("Redirect failed: ", err);
                            setIsInteractionInProgress(false);
                        });
                        return;
                    }

                    console.error("Silent token acquisition failed: ", error);
                }
            } else if (!isAuthenticated && !isInteractionInProgress) {
                setIsInteractionInProgress(true);
                instance.loginRedirect(loginRequest).catch(err => {
                    console.error("Login failed: ", err);
                    setIsInteractionInProgress(false);
                });
            }
        };

        initializeAuth();
    }, [isAuthenticated, accounts, instance]);

    const acquireTokenWithRefreshToken = async () => {
        try {
            if (accounts.length && instance) {
                const response = await instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: loginRequest.scopes
                });
                localStorage.setItem('token', response.accessToken);
                jwtDecode(response.accessToken);
                setAccessToken(response?.accessToken);
            }
        } catch (error) {
            console.log('Error refreshing token', error);
        }
    };

    if (!isAuthenticated || !accessToken) {
        return (
            <div id="authentication-content" style={{ textAlign: "center", marginTop: "10px" }}>
                <label>You are currently logged out of your Office 365 account.</label>
            </div>
        );
    }

    return <div id="logged-in-user-content">{props.children}</div>;
};