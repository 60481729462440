import { Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerList, TagPickerOption, TagPickerProps } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { CustomOption } from "../peoplePicker/PeoplePicker.types";
import { useStyles } from "./TagPicker.styles";
import { ITagPickerCustomProps } from "./TagPicker.types";

export const TagPickerCustom = (props: ITagPickerCustomProps): JSX.Element => {
    const styles = useStyles();
    const [selectedOptions, setSelectedOptions] = useState<CustomOption[]>(props.selectedItems);
    const [availableOptions, setAvailableOptions] = useState<CustomOption[]>([]);

    useEffect((): void => {
        const newAvailableOptions: CustomOption[] = props.items.filter(
            (item: CustomOption): boolean => !selectedOptions.some((selectedItem: CustomOption): boolean => selectedItem.key === item.key)
        );

        setAvailableOptions(newAvailableOptions);
    }, [selectedOptions, props.items]);

    useEffect((): void => {
        setSelectedOptions(props.selectedItems);
    }, [props.selectedItems]);

    const onOptionSelect: TagPickerProps["onOptionSelect"] = (event, data) => {
        const newSelected: CustomOption[] = data.selectedOptions.map(option =>
            props.items.find(item => item.text === option)!
        );

        setSelectedOptions(newSelected);
        props.onSelectionChange(newSelected);
    };

    const handleSelectItem = (item: CustomOption): void => {
        if (selectedOptions.some((selectedItem: CustomOption): boolean => selectedItem.key === item.key)) {
            const newSelectedItems: CustomOption[] = selectedOptions.filter(selectedItem => selectedItem.key !== item.key);
            setSelectedOptions(newSelectedItems);
            props.onSelectionChange(newSelectedItems);
        } else {
            const newSelectedItems: CustomOption[] = [...selectedOptions, item];
            setSelectedOptions(newSelectedItems);
            props.onSelectionChange(newSelectedItems);
        }
    };

    return (
        <TagPicker
            selectedOptions={selectedOptions.map((s: CustomOption): string => s.text)}
            onOptionSelect={onOptionSelect}
        >
            <TagPickerControl>
                <TagPickerGroup aria-label="Selected Items">
                    {selectedOptions.map(item => (
                        <Tag
                            className={styles.tag}
                            appearance="brand"
                            key={item.key}
                            shape="rounded"
                            value={item.text}
                        >
                            {item.text}
                        </Tag>
                    ))}
                </TagPickerGroup>
            </TagPickerControl>
            <TagPickerList>
                {availableOptions.length > 0
                    ? availableOptions.map((item: CustomOption): JSX.Element => (
                        <TagPickerOption
                            key={item.key}
                            value={item.text}
                            onClick={() => handleSelectItem(item)}
                        >
                            {item.text}
                        </TagPickerOption>
                    ))
                    : "No options available"
                }
            </TagPickerList>
        </TagPicker>
    );
};