
import { DirectionalHint, IconButton, Stack, TooltipHost } from "@fluentui/react";
import { PageButton } from "./PageButton";
import { firstPageIconProps, lastPageIconProps, nextPageIconProps, previousPageIconProps, stackStyle } from "./Paginator.styles";
import { IPaginatorProps } from "./Paginator.types";
import { PaginatorPosition } from "./PaginatorPosition";

const DEFAULT_NUMBER_OF_DISPLAYED_PAGES: number = 5;

export const Paginator = (props: IPaginatorProps): JSX.Element => {
    const currentPageNumber: number = props.selectedIndex + 1;
    const canGoPrevious: boolean = props.selectedIndex > 0;
    const canGoNext: boolean = currentPageNumber < props.pageCount;
    const canGoFirst: boolean = props.selectedIndex !== 0;
    const canGoLast: boolean = props.selectedIndex !== props.pageCount - 1;

    const handleSelectedPage = (index: number): void => {
        if (index === props.selectedIndex) {
            return;
        }

        props.onPageChange(index);
    };

    const generatePage = (index: number): JSX.Element => {
        const { pageCount, selectedIndex } = props;
        const isSelected: boolean = index === selectedIndex;

        const ariaLabel: string = isSelected
            ? `page ${(index + 1).toString()} of ${pageCount.toString()} selected`
            : `page ${(index + 1).toString()} of ${pageCount.toString()}`;

        return (
            <PageButton
                key={index + 1}
                pageNo={index + 1}
                ariaLabel={ariaLabel}
                selected={isSelected}
                onClick={handleSelectedPage}
            />
        );
    };

    const generatePages = (): Array<JSX.Element> => {
        const { noOfDisplayedPages, pageCount, selectedIndex } = props;
        const pagesInView: number = noOfDisplayedPages ?? DEFAULT_NUMBER_OF_DISPLAYED_PAGES;

        const pageList: Array<JSX.Element> = new Array<JSX.Element>();
        if (pageCount <= pagesInView) {
            for (let index: number = 0; index < pageCount; index++) {
                pageList.push(generatePage(index));
            }

            return pageList;
        }

        const leftHalfCount: number = Math.floor((pagesInView - 1) / 2);
        const rightHalfCount: number = pagesInView - 1 - leftHalfCount;

        let leftSide: number = selectedIndex - leftHalfCount;
        let rightSide: number = selectedIndex + rightHalfCount;

        if (rightSide > pageCount - 1) {
            rightSide = pageCount - 1;
            leftSide = rightSide - pagesInView + 1;
        } else if (leftSide < 0) {
            leftSide = 0;
            rightSide = pagesInView - 1;
        }

        for (let index: number = leftSide; index <= rightSide; index++) {
            pageList.push(generatePage(index));
        }

        return pageList;
    };

    const renderPaginatorPosition = (): JSX.Element | null => {
        if (!props.displayPosition) {
            return null;
        }

        return (
            <PaginatorPosition
                totalItemsCount={props.totalItemsCount}
                itemsPerPage={props.itemsPerPage}
                selectedIndex={props.selectedIndex}
            />
        );
    };

    return (
        <Stack horizontalAlign="center" verticalAlign="end" style={stackStyle}>
            <Stack.Item>
                <Stack horizontal>
                    <TooltipHost
                        content={canGoFirst ? "Page 1" : undefined}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <IconButton
                            iconProps={firstPageIconProps}
                            onClick={(): void => handleSelectedPage(0)}
                            disabled={!canGoFirst}
                            aria-label={"first page"}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content={
                            canGoPrevious
                                ? `Page ${(currentPageNumber - 1).toString()}`
                                : undefined
                        }
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <IconButton
                            iconProps={previousPageIconProps}
                            onClick={(): void => handleSelectedPage(props.selectedIndex - 1)}
                            disabled={!canGoPrevious}
                            aria-label={"previous page"}
                        />
                    </TooltipHost>
                    {generatePages()}
                    <TooltipHost
                        content={
                            canGoNext ? `Page ${(currentPageNumber + 1).toString()}` : undefined
                        }
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <IconButton
                            iconProps={nextPageIconProps}
                            onClick={(): void => handleSelectedPage(currentPageNumber)}
                            disabled={!canGoNext}
                            aria-label={"next page"}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content={canGoLast ? `Page ${props.pageCount.toString()}` : undefined}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <IconButton
                            iconProps={lastPageIconProps}
                            onClick={(): void => handleSelectedPage(props.pageCount - 1)}
                            disabled={!canGoLast}
                            aria-label={"last page"}
                        />
                    </TooltipHost>
                </Stack>
            </Stack.Item>
            <Stack.Item>{renderPaginatorPosition()}</Stack.Item>
        </Stack>
    );
};