import { Button } from "@fluentui/react-components";
import { useStyles } from "../Conversations.styles";
import { FilterCapabilitiesMenu } from "../filterCapabilitiesMenu/FilterCapabilitiesMenu";
import { IActionButtonsProps } from "./ActionButtons.types";

export const ActionButtons = (props: IActionButtonsProps) => {
    const styles = useStyles();

    return (
        <div className={styles.sortActionContainer}>
            <Button
                className={styles.sortButton}
                onClick={async () => { props.orderByPinned() }}
                appearance={props.pinned ? "outline" : "subtle"}>
                Pinned
            </Button>
            <Button
                className={styles.sortButton}
                onClick={async () => { props.getOnlySharedConversation() }}
                appearance={props.onlySharedConversations ? "outline" : "subtle"}>
                {props.onlySharedConversations ? 'Shared' : 'Own'}
            </Button>
            <FilterCapabilitiesMenu filterByCapabilities={props.filterByCapabilities} />
            <Button
                className={styles.sortButton}
                onClick={props.changeAscending}
                appearance={"subtle"}>
                {props.ascending ? 'Oldest' : 'Newest'}
            </Button>
        </div>
    );
};