import { Stack, StackItem } from "@fluentui/react";
import {
    Avatar,
    Label,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableRowData,
    TableSelectionCell,
    tokens,
    Toolbar,
    ToolbarButton,
    useTableColumnSizing_unstable,
    useTableFeatures,
    useTableSelection
} from "@fluentui/react-components";
import { Add16Regular, Delete16Regular, Edit16Regular } from "@fluentui/react-icons";
import { deleteGroup } from "api";
import { ConstantValues } from "common/constants";
import { ActionDialog } from "components/actionDialog/ActionDialog";
import { ColumnActionsMenu } from "components/columnActionsMenu/ColumnActionsMenu";
import { IColumnActionsMenuProps } from "components/columnActionsMenu/ColumnActionsMenu.types";
import { InputSkeleton } from "components/InputSkeletons";
import { Paginator } from "components/paginator/Paginator";
import { SortingDirection } from "enums/SortingOrder";
import { IColumnSizingOption } from "models/IColumnSizingOptions";
import { IGroup } from "models/IGroup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ITableColumn } from "../../models/ITableColumn";
import { removeGroup } from "../../redux/groups";
import { renderCellValue } from "../../utils/generalUtils";
import { EditDialog } from "./editDialog/EditDialog";
import { containerClassName, setGapBetweenHeadersAndDetailsList } from "./ManageGroups.styles";

const columnSizingOptions: Partial<Record<keyof IGroup, IColumnSizingOption>> = {
    name: { idealWidth: 500, minWidth: 400 },
    description: { idealWidth: 500, minWidth: 400 },
    userId: { idealWidth: 175, minWidth: 175 },
};

export const ManageGroups = (): JSX.Element => {
    const columns: ITableColumn<IGroup>[] = useMemo(
        () => [
            {
                columnId: "name",
                displayValue: "Name",
                renderHeaderCell: () => "Name",
                renderCell: (item: IGroup) => item.name,
                compare: function (a: IGroup, b: IGroup): number {
                    return a.name.localeCompare(b.name);
                }
            },
            {
                columnId: "description",
                displayValue: "Description",
                renderHeaderCell: () => "Description",
                renderCell: (item: IGroup) => item.description,
                compare: function (a: IGroup, b: IGroup): number {
                    return a.description.localeCompare(b.description);
                }
            },
            {
                columnId: "userId",
                displayValue: "Author",
                renderHeaderCell: () => "Author",
                renderCell: (group: IGroup): JSX.Element => {
                    return (
                        <TableCellLayout media={<Avatar aria-label={group.displayName} name={group.displayName} />}>
                            {group.displayName}
                        </TableCellLayout>
                    );
                },
                compare: function (a: IGroup, b: IGroup): number {
                    return a.displayName.localeCompare(b.displayName);
                },
            }
        ],
        []
    );

    const groups: IGroup[] = useSelector((state: RootState) => state.groupsSlice.value);
    const dispatch = useDispatch();

    const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [isSortDescending, setIsSortDescending] = useState<boolean>(false);

    const itemsPerPage: number = 10;
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const itemsStartIndex: number = selectedPageIndex * itemsPerPage;
    const itemsEndIndex: number = (selectedPageIndex + 1) * itemsPerPage;

    const pageCount: number = useMemo((): number => {
        return Math.ceil(filteredGroups.length / itemsPerPage);
    }, [filteredGroups]);

    const paginatedGroups: IGroup[] = filteredGroups.slice(itemsStartIndex, itemsEndIndex);

    const { getRows, columnSizing_unstable, tableRef, selection: { toggleRow, isRowSelected } } = useTableFeatures(
        {
            columns,
            items: paginatedGroups,
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
                autoFitColumns: false,
            }),
            useTableSelection({
                selectionMode: "single",
            }),
        ]
    );

    useEffect((): void => {
        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        if (savedSortingOptions) {
            const parsedOptions = JSON.parse(savedSortingOptions);
            const listSortingOptions = parsedOptions['Groups'];

            if (listSortingOptions) {
                setSortColumn(listSortingOptions.column);
                setIsSortDescending(listSortingOptions.direction === 'desc');
            }
        }
    }, []);

    useEffect((): void => {
        setFilteredGroups(groups);
    }, [groups]);

    useEffect((): void => {
        let updatedGroups = [...groups];

        if (sortColumn) {
            const column: ITableColumn<IGroup> | undefined = columns.find((col: ITableColumn<IGroup>): boolean => col.columnId === sortColumn);
            if (column && column.compare) {
                updatedGroups.sort((a: IGroup, b: IGroup): number => column.compare(a, b) * (isSortDescending ? -1 : 1));
            }
        }

        setFilteredGroups(updatedGroups);
    }, [groups, sortColumn, isSortDescending]);

    const rows = getRows((row: TableRowData<IGroup>) => {
        const selected: boolean = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent): void => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    e.preventDefault();
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const onPageChange = (index: number): void => setSelectedPageIndex(index);


    const onRenderDetailsFooter = (): JSX.Element => (
        <div style={{ position: "sticky", bottom: 0, backgroundColor: tokens.colorNeutralBackground2, padding: "10px", textAlign: "center" }}>
            {pageCount > 1 && (
                <Paginator
                    selectedIndex={selectedPageIndex}
                    pageCount={pageCount}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={groups.length}
                    displayPosition={true}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );

    const handleRowClick = (group: IGroup): void => {
        setSelectedGroup(group.id === selectedGroup?.id ? null : group);
    };

    const handleDeleteGroup = async (): Promise<void> => {
        if (selectedGroup) {
            try {
                setIsLoading(true);
                await deleteGroup(selectedGroup.id);
                dispatch(removeGroup(selectedGroup.id));
                setIsDeleteDialogOpen(false);
                setSelectedGroup(null);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onSortColumnChanged = (columnKey: string, descending: boolean) => {
        setSortColumn(columnKey);
        setIsSortDescending(descending);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        sortingOptions['Groups'] = {
            column: columnKey,
            direction: descending ? 'desc' : 'asc'
        };

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const onClearColumnChanged = () => {
        setSortColumn(null);
        setIsSortDescending(false);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        delete sortingOptions['Groups'];

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const getColumnActions = (column: ITableColumn<IGroup>): IColumnActionsMenuProps => {
        const isColumnSorted: boolean = sortColumn === column.columnId;
        const isColumnSortable: boolean = column.isSortable === undefined ? true : column.isSortable;

        return {
            columnKey: column.columnId.toString(),
            displayValue: column.displayValue,
            isSortable: isColumnSortable,
            sortingDirection: isColumnSorted ? (isSortDescending ? SortingDirection.Descending : SortingDirection.Ascending) : SortingDirection.None,
            onSortClicked: (columnKey: string, descending: boolean) => onSortColumnChanged(columnKey, descending),
            onClear: onClearColumnChanged,
        };
    };

    const getUpdatedColumns = (): ITableColumn<IGroup>[] => {
        return columns.map((column: ITableColumn<IGroup>): ITableColumn<IGroup> => {
            if (column.columnId) {
                column.renderHeaderCell = (): JSX.Element => {
                    const customColumn: ITableColumn<IGroup> | undefined = columns.find((c: ITableColumn<IGroup>): boolean => c.columnId === column.columnId.toString());
                    if (!customColumn) {
                        return <React.Fragment />;
                    }

                    const actions: IColumnActionsMenuProps = getColumnActions(column);
                    return (
                        <Stack horizontal style={{ alignItems: 'center' }}>
                            <Label>{customColumn.displayValue} </Label>
                            <ColumnActionsMenu {...actions} />
                        </Stack>
                    );
                };
            }

            return column;
        });
    };

    return (
        <Stack tokens={setGapBetweenHeadersAndDetailsList} className={containerClassName}>
            <>
                {isLoading
                    ? <InputSkeleton />
                    : <>
                        <Toolbar>
                            <ToolbarButton icon={<Add16Regular />} disabled={!!selectedGroup} onClick={(): void => setIsEditPopupOpen(true)}>
                                Create
                            </ToolbarButton>
                            <ToolbarButton icon={<Edit16Regular />} disabled={!selectedGroup} onClick={(): void => setIsEditPopupOpen(true)}>
                                Update
                            </ToolbarButton>
                            <ToolbarButton icon={<Delete16Regular />} disabled={!selectedGroup} onClick={() => setIsDeleteDialogOpen(true)}>
                                Delete
                            </ToolbarButton>
                        </Toolbar>
                        <StackItem>
                            {isEditPopupOpen &&
                                <EditDialog
                                    groups={groups}
                                    selectedGroup={selectedGroup}
                                    isOpen={isEditPopupOpen}
                                    onDismiss={(): void => setIsEditPopupOpen(false)}
                                />
                            }
                            <Table ref={tableRef} {...columnSizing_unstable.getTableProps()} noNativeElements={true}>
                                <TableHeader >
                                    <TableRow>
                                        {getUpdatedColumns().map((column: ITableColumn<IGroup>): JSX.Element => (
                                            <TableHeaderCell
                                                key={column.columnId}
                                                {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}
                                            >
                                                {column.renderHeaderCell()}
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {rows.map((row: TableRowData<IGroup>): JSX.Element => (
                                        <TableRow key={row.item.id} appearance={row.item.id === selectedGroup?.id ? "brand" : "none"} onClick={(): void => handleRowClick(row.item)}>
                                            <TableSelectionCell type="checkbox" checked={row.item.id === selectedGroup?.id} checkboxIndicator={{ "aria-label": "Select row" }} />
                                            {columns.map((column: ITableColumn<IGroup>): JSX.Element =>
                                                <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                                    <React.Fragment>
                                                        {column.renderCell ? column.renderCell(row.item) : renderCellValue<IGroup>(row.item, column.columnId as keyof IGroup)}
                                                    </React.Fragment>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </StackItem>

                        {isDeleteDialogOpen && (
                            <ActionDialog
                                isVisible={isDeleteDialogOpen}
                                title={"Delete Alert"}
                                onConfirmation={handleDeleteGroup}
                                onDismiss={() => setIsDeleteDialogOpen(false)}
                                confirmationMessage={"Yes"}
                                dismissMessage={"No"}
                                message={"Are you sure you want to delete this group?"} />
                        )}
                        <StackItem>{onRenderDetailsFooter()}</StackItem>
                    </>
                }
            </>
        </Stack>
    );
};