import { makeStyles } from "@fluentui/react-components";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    button: {
        color: 'white',
        backgroundColor: NHColors.primary,
        ':hover': {
            color: 'white',
            backgroundColor: NHColors.primary,
        }
    },
});