import { AccountInfo } from "@azure/msal-browser";
import { CustomOption } from "components/fields/peoplePicker/PeoplePicker.types";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";
import { ITableColumn } from "models/ITableColumn";
import { IValidationRule } from "models/IValidationRule";
import React from "react";
import { ConstantValues } from "../common/constants";
import { ICategory } from "../models/ICategory";
import { IGroup } from "../models/IGroup";
import { ITag } from "../models/ITag";
import { IUser } from "../models/IUser";

export const NHColors = {
    gray: '#e5e7eb',
    primary: "#14243c",
    primaryOld: "#132e53",
    primary2: "#039fb8",
    secondary: "#525252",
    secondary2: "#dee22a",
    white: "white"
};

export const IsNullOrUndefined = (obj: any): boolean => {
    return obj === null || typeof (obj) === 'undefined';
};

export const IsNullUndefinedOrEmpty = (obj: any): boolean => {
    return IsNullOrUndefined(obj) || obj === ConstantValues.EMPTY_STRING;
};

export const IsUndefined = (obj: any): boolean => {
    return typeof (obj) === 'undefined';
};

type CellType = ITag | ICategory | IUser | IGroup | any;

export const renderCellValue = <T extends CellType>(item: T, columnKey: keyof T) => {
    const value = item[columnKey];

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return value;
    }

    if (Array.isArray(value)) {
        return (
            <React.Fragment>
                {
                    value?.map((item: T, index: number) => (
                        <span key={index} > {JSON.stringify(item)} </span>
                    ))
                }
            </React.Fragment>
        );
    }

    if (typeof value === 'object' && value !== null) {
        return <span>{JSON.stringify(value)} </span>;
    }

    return null;
};

export let currentAccount: IUser = {
    id: ConstantValues.EMPTY_STRING,
    userPrincipalName: ConstantValues.EMPTY_STRING,
    displayName: ConstantValues.EMPTY_STRING,
    mail: ConstantValues.EMPTY_STRING
};

export const setCurrentAccount = (account: AccountInfo): void => {
    currentAccount.id = account?.homeAccountId.split('.')[0];
    currentAccount.userPrincipalName = account?.username;
    currentAccount.mail = account?.name ?? ConstantValues.EMPTY_STRING;
};

export const validateFields = <T,>(
    item: T,
    columns: ITableColumn<T>[],
    validationRules?: IValidationRule<T>[]
): string[] => {
    const messages: string[] = [];

    columns.forEach((column) => {
        const value = item[column.columnId as keyof T];

        if (column.required && (value === undefined || value === null || value === ConstantValues.EMPTY_STRING)) {
            messages.push(`${column.displayValue} is required.`);
        }

        if (validationRules) {
            validationRules.forEach((rule) => {
                if (rule.columnId === column.columnId) {
                    const message = rule.validate(value);
                    if (message) {
                        messages.push(message);
                    }
                }
            });
        }
    });

    return messages;
};

export const mapAzureUsersToModel = (azureUsers: Array<IUser> | null): any[] => {
    if (!azureUsers) {
        return [];
    }

    return azureUsers.map((user, index) => {
        return {
            Uid: user.userPrincipalName ? user.userPrincipalName : `user-${index}`,
            Name: user.displayName || 'Unknown',
            Email: user.mail || 'No email provided'
        };
    });
};

export const mapAzureUserToModel = (user: IUser | null, index: number = 0): CustomOption => {
    if (!user) {
        return {
            key: ConstantValues.EMPTY_STRING,
            text: ConstantValues.EMPTY_STRING,
            secondaryText: ConstantValues.EMPTY_STRING,
        };
    }

    return {
        key: user.userPrincipalName ? user.userPrincipalName : `user-${index}`,
        text: user.displayName || 'Unknown',
        secondaryText: user.mail || 'No email provided',
        ...user
    };
};

export const convertFileWithIdToIFile = (fileWithId: FileWithId): IFile => {
    return {
        filename: fileWithId.file.name,
        url: ConstantValues.EMPTY_STRING,
        id: fileWithId.id
    };
};