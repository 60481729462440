import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileWithId } from 'models/FileWithId';

interface AttachmentsState {
    value: FileWithId[];
}

const initialState: AttachmentsState = {
    value: [],
};

export const attachmentsSlice = createSlice({
    name: 'currentAttachments',
    initialState,
    reducers: {
        setAttachments: (state, action: PayloadAction<FileWithId[]>) => {
            state.value = action.payload;
        },
        addAttachment: (state, action: PayloadAction<FileWithId>) => {
            const existingFile = state.value.find(attachment => attachment.file.name === action.payload.file.name);

            if (!existingFile) {
                state.value.push(action.payload);
            }
        },
        removeAttachment: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(attachment => attachment.file.name !== action.payload);
        },
        clearAttachments: (state) => {
            state.value = [];
        }
    },
});

export const { setAttachments, addAttachment, removeAttachment, clearAttachments } = attachmentsSlice.actions;
export default attachmentsSlice.reducer;