import { Avatar, Spinner, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOnOpenChangeData, TagPickerOnOptionSelectData, TagPickerOption, TagPickerProps, Text } from '@fluentui/react-components';
import { VirtualizerScrollView } from '@fluentui/react-components/unstable';
import { getUsers } from 'api';
import { IUser } from 'models/IUser';
import { ChangeEvent, SyntheticEvent, useMemo, useRef, useState } from 'react';
import { ConstantValues } from '../../../common/constants';
import { mapAzureUserToModel } from '../../../utils/generalUtils';
import { usePeoplePickerStyles } from './PeoplePicker.styles';
import { CustomOption, PeoplePickerProps } from './PeoplePicker.types';

const NO_MATCHES_ITEM = "no-matches";

export const PeoplePicker = (props: PeoplePickerProps): JSX.Element => {
    const styles = usePeoplePickerStyles();

    const [matchingOptions, setMatchingOptions] = useState<CustomOption[] | null>([]);
    const [isLoadingResources, setIsLoadingResources] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(ConstantValues.EMPTY_STRING);

    const isFirstSearch = useRef<boolean>(true);
    const refInput = useRef<HTMLInputElement>(null);

    const onOptionSelect: TagPickerProps["onOptionSelect"] = (event: Event | SyntheticEvent<Element, Event>, data: TagPickerOnOptionSelectData) => {
        if (data?.value === NO_MATCHES_ITEM) {
            return;
        }

        const remainingOptionsAfterSelectedRemoval: CustomOption[] | undefined = props.selectedOptions?.filter((o: CustomOption): boolean => o?.key !== data?.value)
        if (remainingOptionsAfterSelectedRemoval?.length !== props?.selectedOptions?.length) {
            props.handleSelectedItems(remainingOptionsAfterSelectedRemoval);
        }
        else {
            const matchedOption: CustomOption[] | undefined = matchingOptions?.filter((m: CustomOption) => m?.key === data?.value)
            const newlySelectedOptions: CustomOption[] = [...props.selectedOptions || [], ...matchedOption || []]
            props.handleSelectedItems(newlySelectedOptions);
        }

        setInputValue(ConstantValues.EMPTY_STRING);
    };

    const onChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const value: string = event.target.value;
        setIsLoadingResources(true);
        setInputValue(value);
        //@ts-ignore
        const usersFromText = await getUsers({ text: value, hasOnlyOurUsers: props.hasOnlyOurUsers ?? false })
        setIsLoadingResources(false);
        const matches = usersFromText?.map((user: IUser | null) => mapAzureUserToModel(user))
        setMatchingOptions(matches);
    };

    const onOpenChange: TagPickerProps["onOpenChange"] = (event, data: TagPickerOnOpenChangeData): void => {
        if (!isFirstSearch.current) {
            setMatchingOptions([...props?.selectedOptions || []]);
            return;
        }
    };

    const getTagPickerOption = (option: CustomOption): JSX.Element => {
        if (props?.selectedOptions?.filter((o: CustomOption): boolean => o?.key === option?.key)?.length) {
            return <></>
        }

        return (
            <TagPickerOption
                key={option.key}
                value={option?.key}
                text={option?.text ?? ConstantValues.EMPTY_STRING}
                title={option?.text ?? ConstantValues.EMPTY_STRING}
                media={
                    <Avatar
                        aria-hidden
                        name={option?.text ?? ConstantValues.EMPTY_STRING}
                        color="colorful"
                    />
                }
            >
                <div className={styles.truncatedText}>
                    <Text className={styles.truncatedText} size={400}>{option?.text ?? ConstantValues.EMPTY_STRING}</Text>
                    <div>{option?.secondaryText ?? ConstantValues.EMPTY_STRING}</div>
                </div>
            </TagPickerOption>
        )
    };

    const renderTagPickerListOptions = (): JSX.Element | JSX.Element[] | string => {
        if (isLoadingResources) {
            return <Spinner {...props} label={"Loading"} />
        }

        if (inputValue && matchingOptions?.length === 0) {
            return <TagPickerOption value={NO_MATCHES_ITEM} text='No matches found'>
                {"No matches found"}
            </TagPickerOption>;
        }

        if (!matchingOptions?.length) {
            return <></>
        }

        return <VirtualizerScrollView
            itemSize={50}
            numItems={matchingOptions?.length || 0}
        >
            {(index: number) => {
                if (!matchingOptions?.length) {
                    return;
                }
                return getTagPickerOption(matchingOptions[index]);
            }}
        </VirtualizerScrollView>
    };

    const renderSelectedTagsPicker: JSX.Element[] | JSX.Element = useMemo(() => {
        if (!props?.selectedOptions) {
            return <></>
        }

        return props?.selectedOptions?.map((selectedOption: CustomOption, index: number) => {
            return (
                <Tag
                    value={selectedOption?.key}
                    key={selectedOption?.key}
                    disabled={props.disabled}
                    title={selectedOption?.text ?? ConstantValues.EMPTY_STRING}
                    primaryText={{ className: styles.truncatedText }}
                    media={<Avatar
                        aria-hidden
                        name={selectedOption?.text ?? ConstantValues.EMPTY_STRING}
                        color="colorful"
                    />}
                >
                    {selectedOption?.text ?? ConstantValues.EMPTY_STRING}
                </Tag>
            );
        });
    }, [props.selectedOptions]);

    return (
        <TagPicker
            size="medium"
            disabled={props.disabled}
            onOptionSelect={onOptionSelect}
            selectedOptions={props?.selectedOptions?.map((o: CustomOption) => o?.key)}
            onOpenChange={onOpenChange}
        >
            <TagPickerControl className={styles.container}>
                <TagPickerGroup>
                    {renderSelectedTagsPicker}
                </TagPickerGroup>
                <TagPickerInput ref={refInput} value={inputValue} onChange={onChange} />
            </TagPickerControl>
            <TagPickerList>
                {renderTagPickerListOptions()}
            </TagPickerList>
        </TagPicker>
    );
};