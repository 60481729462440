import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, Field, mergeClasses, Radio, RadioGroup, Text, Textarea } from "@fluentui/react-components";
import { getAIAbstract } from "api";
import { capabilities, ConstantValues } from "common/constants";
import { PeoplePicker } from "components/fields/peoplePicker/PeoplePicker";
import { CustomOption } from "components/fields/peoplePicker/PeoplePicker.types";
import { TagPickerCustom } from "components/fields/tagPicker/TagPicker";
import { LLMType } from "enums/LLMType";
import { SharingType } from "enums/SharingType";
import { IGroup } from "models/IGroup";
import { IShareOptions } from "models/IShareOption";
import { IUser } from "models/IUser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { currentAccount, mapAzureUsersToModel, mapAzureUserToModel } from "utils/generalUtils";
import { useStyles } from "../Conversations.styles";
import { ISharingOptionsDialogProps } from "./SharingOptionsDialog.types";

export const SharingOptionsDialog = (props: ISharingOptionsDialogProps): JSX.Element => {
    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);
    const groups: IGroup[] = useSelector((state: RootState) => state.groupsSlice.value);

    const getPreselectedData = (type: 'users' | 'groups'): CustomOption[] => {
        if (!props.conversation) {
            return [];
        }

        if (type === 'users') {
            return getUsers();
        }

        if (type === 'groups') {
            return getGroups();
        }

        return [];
    };

    const getUsers = (): CustomOption[] => {
        return props.conversation.sharedIds.map((id: string): CustomOption | undefined => {
            const user: IUser | undefined = users.find((u: IUser): boolean => u.id === id);
            if (!user) {
                return undefined;
            }

            return mapAzureUserToModel(user);
        }).filter((user: IUser) => user);
    };

    const getGroups = (): CustomOption[] => {
        return props.conversation.sharedIds.map((id: string): CustomOption | undefined => {
            const group: IGroup | undefined = groups.find((g: IGroup): boolean => g.id === id);
            if (!group) {
                return undefined;
            }

            return {
                id: group.id,
                key: group.id,
                text: group.name,
                secondaryText: group.description,
            };
        }).filter((group: IGroup) => group);
    };

    const styles = useStyles();
    const [selectedCapabilities, setSelectedCapabilities] = useState<string[]>(props.conversation.labels ?? []);
    const [selectedShareOption, setSelectedShareOption] = useState<string>(props.conversation.sharingType ?? "everyone");
    const [selectedUsers, setSelectedUsers] = useState<CustomOption[]>(getPreselectedData('users'));
    const [selectedGroups, setSelectedGroups] = useState<CustomOption[]>(getPreselectedData('groups'));
    const [errorMessage, setErrorMessage] = useState<string>(ConstantValues.EMPTY_STRING);
    const [abstract, setAbstract] = useState<string>(props.conversation.abstract ?? ConstantValues.EMPTY_STRING);
    const [isAbstractLoading, setIsAbstractLoading] = useState<boolean>(false);
    const llmConfig = useSelector((state: RootState) => state.currentLLMConfigSlice.config[ConstantValues.CHAT] ?? { llmType: LLMType.OPENAI, rag: false });
    const currentMessages: any = useSelector((state: RootState) => state.currentMessagesSetter.value);

    const fetchAIAbstract = async () => {
        try {
            if (abstract === ConstantValues.EMPTY_STRING) {
                setIsAbstractLoading(true);
                const messages = currentMessages || [];
                const response = await getAIAbstract({ llmConfig, messages });

                if (response?.abstract) {
                    setAbstract(response.abstract.trim());
                }
            }
        } catch (error) {
            console.error("Error fetching AI Abstract:", error);
        } finally {
            setIsAbstractLoading(false);
        }
    };

    useEffect(() => {
        if (currentMessages.length > 0) {
            fetchAIAbstract();
        }
    }, [currentMessages]);

    const toggleCapabilitySelection = (capability: string): void => {
        if (selectedCapabilities.includes(capability)) {
            setSelectedCapabilities(selectedCapabilities.filter((selectedCapability): boolean => selectedCapability !== capability));
            return;
        }

        setSelectedCapabilities([...selectedCapabilities, capability]);
    };

    const handleShareOptionChange = (event: React.FormEvent, data: { value: string }): void => {
        const newValue: string = data.value;
        setSelectedShareOption(newValue);
    };

    const handleSelectedUsers = (selectedOptions: CustomOption[]): void => {
        setSelectedUsers(selectedOptions);
    };

    const handleSelectedGroups = (selectedOptions: CustomOption[]): void => {
        setSelectedGroups([...selectedOptions]);
    };

    const readResourcesForPickerFieldFiltered = async (searchedValue: string): Promise<any[]> => {
        return new Promise((resolve) => {
            const mappedUsers = mapAzureUsersToModel(users);
            const filteredResources = mappedUsers.filter((resource: any) =>
                resource.Name?.toLowerCase().includes(searchedValue.toLowerCase())
            );

            resolve(filteredResources);
        });
    };

    const onShare = async (): Promise<void> => {
        let sharedIds;
        let shareOption: IShareOptions = {
            shared: true,
            sharingType: selectedShareOption as SharingType,
            sharedIds: [],
            labels: selectedCapabilities,
            abstract: abstract
        };

        if (selectedShareOption === 'groups') {
            sharedIds = selectedGroups
                .map((group: CustomOption) => group.key ?? ConstantValues.EMPTY_STRING)
                .filter((g: string): boolean => g !== ConstantValues.EMPTY_STRING);

            shareOption = {
                shared: true,
                sharingType: selectedShareOption as SharingType,
                sharedIds,
                labels: selectedCapabilities,
                abstract: abstract
            };
        }
        else if (selectedShareOption === 'users') {
            if (selectedUsers.some((s: CustomOption): boolean => s.id === currentAccount.id)) {
                setErrorMessage('You cannot share with yourself !');
                setTimeout(() => setErrorMessage(ConstantValues.EMPTY_STRING), 3000);
                return;
            }

            sharedIds = selectedUsers
                .map((user: CustomOption): string => user.id ?? ConstantValues.EMPTY_STRING)
                .filter((u: string): boolean => u !== ConstantValues.EMPTY_STRING);

            shareOption = {
                shared: true,
                sharingType: selectedShareOption as SharingType,
                sharedIds,
                labels: selectedCapabilities,
                abstract: abstract
            };
        }

        resetToDefault();
        props.onShare(shareOption);
    };

    const resetToDefault = (): void => {
        setSelectedCapabilities([]);
        setSelectedUsers([]);
        setSelectedGroups([]);
        setSelectedShareOption("everyone");
    };

    return (
        <Dialog modalType="alert" open={props.isOpen} onOpenChange={(event, data) => data.open === false && props.onClose()}>
            <DialogSurface className={styles.dialogSurface}>
                <DialogTitle>{props.conversation.title || 'Sharing Options'}</DialogTitle>
                <DialogBody className={styles.dialogBody}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {errorMessage !== ConstantValues.EMPTY_STRING ? <Text style={{ marginRight: "20px", color: 'red', display: "block" }}>{errorMessage}</Text> : <></>}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px", marginTop: '10px' }}>
                            <Text size={300} weight="semibold" >Sharing Options</Text>
                            <RadioGroup
                                value={selectedShareOption}
                                onChange={handleShareOptionChange}
                                layout="horizontal"
                                style={{ gap: "20px" }}
                            >
                                <Radio value="everyone" label={SharingType.Everyone} />
                                <Radio value="users" label={SharingType.Users} />
                                <Radio value="groups" label={SharingType.Groups} />
                            </RadioGroup>
                        </div>

                        {selectedShareOption === "users" && (
                            <div style={{ marginBottom: "20px", width: "100%" }}>
                                <Field label="Select Users">
                                    <PeoplePicker
                                        id="user"
                                        handleSelectedItems={handleSelectedUsers}
                                        disabled={false}
                                        required={true}
                                        hasOnlyOurUsers={true}
                                        readResourcesForPickerFieldFiltered={readResourcesForPickerFieldFiltered}
                                        selectedOptions={selectedUsers}
                                    />
                                </Field>
                            </div>
                        )}

                        {selectedShareOption === "groups" && (
                            <div style={{ marginBottom: "20px", width: "100%" }}>
                                <Field label="Select Groups">
                                    <TagPickerCustom
                                        items={groups.map((g: IGroup): CustomOption => ({ key: g.id, text: g.name }))}
                                        selectedItems={selectedGroups}
                                        onSelectionChange={handleSelectedGroups}
                                    />
                                </Field>
                            </div>
                        )}

                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", overflowX: "auto", marginBottom: "20px" }}>
                            {capabilities.map((capability: string, index: number): JSX.Element => (
                                <span
                                    key={index}
                                    onClick={() => toggleCapabilitySelection(capability)}
                                    className={mergeClasses(
                                        styles.capabilityTag,
                                        selectedCapabilities.includes(capability) && styles.selectedCapabilityTag
                                    )} >
                                    {capability}
                                </span>
                            ))}
                        </div>
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                            <Text size={300} weight="semibold" style={{ marginBottom: "5px" }}>Abstract</Text>
                            <Textarea
                                placeholder="Enter abstract for this conversation..."
                                style={{ width: "100%", marginBottom: "20px" }}
                                value={isAbstractLoading ? "Generating abstract..." : abstract}
                                onChange={(e) => setAbstract(e.target.value)}
                            />
                        </div>
                    </div>
                </DialogBody>

                <DialogActions style={{ justifyContent: "flex-end" }}>
                    <Button appearance="secondary" onClick={props.onClose}>Cancel</Button>
                    <Button appearance="primary" className={styles.confirmationDialogButton} onClick={onShare}>Update and Share</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};