import { BlobServiceClient } from "@azure/storage-blob";
import { Modal } from "@fluentui/react";
import {
    Body2,
    Button,
    Card, CardHeader,
    CompoundButton,
    Field,
    Input,
    makeStyles,
    Persona,
    Tag,
    Text,
    Textarea,
    tokens,
    useId
} from "@fluentui/react-components";
import { Checkmark12Regular, Clipboard12Filled, Clipboard12Regular, Delete16Regular, Dismiss12Regular, DocumentAdd20Regular, Edit12Filled, Edit12Regular, Send16Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { AIModelSettings } from "components/aiModelSettings/AIModelSettings";
import { TagWithAttachments } from "components/tagWithAttachments/TagWithAttachments";
import { LLMType } from "enums/LLMType";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";
import { IMessage } from "models/IMessage";
import { ITag } from "models/ITag";
import { Fragment, useEffect, useRef, useState } from "react";
import Markdown from 'react-markdown';
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from 'remark-gfm';
import { RootState } from "store";
import { v4 as uuidv4 } from 'uuid';
import { addMessageToConversation, getAIResponse, getPrompts, getSignedUrl, updateContentOfConversation } from "../../api";
import { isImage, resolveAsset } from "../../common/fileIcons";
import { getChunkAndUpdateAIMessage } from "../../common/streamProcessing.js";
import { setNewConfigForKey } from "../../redux/llmConfig";
import { setCurrentMessages } from "../../redux/messages";
import { setCurrentPrompt } from "../../redux/selectedPrompt";
import { setTagsForKey } from "../../redux/tags";
import { setInitialMessage, setTemporaryChatOpen } from "../../redux/temporaryChat";
import { AddPrompt } from "./addPrompt/AddPrompt";
import { AIResponse } from "./AIResponse";
import { StickyHeader } from "./StickyHeader";

const useStyles: any = makeStyles({
    popover: {
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
    },
    card: {
        width: "280px",
        height: "fit-content",
    },
    modalImage: {
        maxWidth: '100%',
        maxHeight: '80vh',
        display: 'block',
        margin: '0 auto'
    },
});

export const TemporaryChat = (): JSX.Element => {
    const styles = useStyles();
    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value);
    const currentlySelectedConversation: any = useSelector((state: RootState) => state.currentConversationSetter.value);
    const realChatMessages: IMessage[] = useSelector((state: RootState) => state.currentMessagesSetter.value);
    const currentTags: ITag[] = useSelector((state: RootState) => state.currentTagsSlice.tags[ConstantValues.TEMPORARY_CHAT] || []);
    const currentLLMConfig: any = useSelector((state: RootState) => state.currentLLMConfigSlice.config[ConstantValues.TEMPORARY_CHAT] ?? { llmType: LLMType.OPENAI, rag: false });
    const initialMessage: any = useSelector((state: RootState) => state.temporaryChatSlice.initialMessage);

    const [temporaryMessages, setTemporaryMessages] = useState<IMessage[]>([]);
    const [text, setText] = useState<string>(ConstantValues.EMPTY_STRING);
    const [aiGenerateMessage, setAiGenerateMessage] = useState<string>(ConstantValues.EMPTY_STRING);
    const [uploadFiles, setUploadFiles] = useState<FileWithId[]>([]);
    const [hoveredMessageId, setHoveredMessageId] = useState<string | null>(null);
    const [copiedMessageId, setCopiedMessageId] = useState<string | null>(null);
    const [injectedMessageId, setInjectedMessageId] = useState<string | null>(null);
    const [editMessageId, setEditMessageId] = useState<string | null>(null);
    const [editMessageValue, setEditMessageValue] = useState<string | null>(null);
    const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false);
    const [cleanUpOnClose, setCleanUpOnClose] = useState<boolean>(true);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true);
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const { data: prompts } = useQuery('prompts', async () => {
        const prompts = await getPrompts()
        return prompts
    }, { refetchOnWindowFocus: false });

    const editMessageInputId: string = useId("input");
    const messagesEndRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch()

    const fileInputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const currentPrompt: any = useSelector((state: RootState) => state.currentPromptSlice.prompts[ConstantValues.TEMPORARY_CHAT]);

    useEffect(() => {
        if (temporaryMessages.length % 2 === 1 && autoScrollEnabled) {
            scrollToBottom();
        }
    }, [temporaryMessages, aiGenerateMessage, currentlySelectedConversation?.id]);

    useEffect(() => {
        if (temporaryMessages.length % 2 !== 0) {
            handleUserMessage();
        }
    }, [temporaryMessages]);

    useEffect(() => {
        const container: HTMLDivElement | null = messagesContainerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (initialMessage) {
            setTemporaryMessages([initialMessage])
        }

        return () => {
            if (initialMessage && cleanUpOnClose === true) {
                const reUpdateMessages = []
                for (const m of realChatMessages.slice()) {

                    if (m?.id === initialMessage?.id) {
                        reUpdateMessages.push({ ...m, content: initialMessage?.initialContent })
                    }
                    else reUpdateMessages.push(m)
                }
                dispatch(setCurrentMessages(reUpdateMessages))
            }
        }
    }, [])

    useEffect((): void => {
        if (currentPrompt) {
            setText(currentPrompt?.description)
        }
    }, [currentPrompt?.id]);

    const handleScroll = (): void => {
        if (!messagesContainerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;

        if (scrollHeight - scrollTop <= clientHeight + 50) {
            setAutoScrollEnabled(true);
        } else {
            setAutoScrollEnabled(false);
        }
    };

    const scrollToBottom = (): void => {
        if (autoScrollEnabled) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleUserMessage = (): void => {
        setAutoScrollEnabled(true);
        scrollToBottom();
    };

    const buildFakeMessage = ({ text, role, sourceDocuments }: { text: any, role: any, sourceDocuments: any }): IMessage => {
        return {
            id: uuidv4(),
            content: text,
            files: Array.from(uploadFiles || []).map((f: FileWithId) => { return { filename: f?.file.name, url: ConstantValues.EMPTY_STRING } }),
            isHidden: false,
            role: role,
            tags: currentTags,
            sourceDocuments: sourceDocuments,
            llmConfig: currentLLMConfig,
            createdAt: Math.floor(Date.now() / 1000),
        }
    };

    const chatWithAi = async () => {
        if (!text || !text.replace(/^\s+|\s+$/g, ConstantValues.EMPTY_STRING)) {
            setText(ConstantValues.EMPTY_STRING)
            return
        }

        setIsLoadingMessage(true)
        for (let i = 0; i < uploadFiles?.length; i++) {
            const tempFileName = `${uploadFiles[i]?.file.name.split('.').slice(0, -1).join('.')}_temporary.${uploadFiles[i]?.file.name.split('.').pop()}`;
            const uploadFileSASData = await getSignedUrl({ filename: tempFileName, entityType: 'user', entityId: currentUser?.id });
            const blobServiceClient = new BlobServiceClient(uploadFileSASData?.sas);
            const containerClient = blobServiceClient.getContainerClient(uploadFileSASData?.containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(uploadFileSASData?.blobName);
            await blockBlobClient.uploadData(uploadFiles[i]?.file, { blockSize: 4 * 1024 * 1024, concurrency: 20 });
        }

        const userMessage = buildFakeMessage({ text: text, role: "user", sourceDocuments: [] });
        let newMessages: IMessage[] = [...temporaryMessages, userMessage];
        setTemporaryMessages(newMessages);
        setText(ConstantValues.EMPTY_STRING);

        const response: Response = await getAIResponse({
            llmConfig: currentLLMConfig,
            messages: newMessages,
            persistFiles: false,
        });
        // @ts-ignore
        const { content, sourceDocuments } = await getChunkAndUpdateAIMessage(response, response.body, null, setAiGenerateMessage, setIsLoadingMessage);

        const aiMessage = buildFakeMessage({ text: content, role: "assistant", sourceDocuments });
        newMessages = [...newMessages, aiMessage];
        setTemporaryMessages(newMessages);
        return response;
    };

    const chatWithAiMutation = useMutation({
        mutationFn: chatWithAi, onSuccess: () => {
            setAiGenerateMessage(ConstantValues.EMPTY_STRING)
            setText(ConstantValues.EMPTY_STRING)
            setUploadFiles([])
        }, onError: (e) => {
            const newAIMessage: IMessage = {
                id: uuidv4(),
                content: 'Something went wrong, please try again later. If this problem persists please contact support',
                files: [],
                isHidden: false,
                role: 'assistant',
                tags: currentTags,
                sourceDocuments: [],
                llmConfig: currentLLMConfig,
                createdAt: Date.now()
            }
            setTemporaryMessages([...temporaryMessages, newAIMessage])
        }
    });

    const copyToClipboard = (message: IMessage) => () => {
        navigator.clipboard.writeText(message?.content);
        setCopiedMessageId(message?.id ?? ConstantValues.EMPTY_STRING);
    };

    const setEditMessage = (message: IMessage) => (): void => {
        setEditMessageValue(message?.content);
        setEditMessageId(message?.id ?? ConstantValues.EMPTY_STRING);
    };

    const desetEditMessage = () => (): void => {
        setEditMessageValue(null);
        setEditMessageId(null);
    };

    const updateValueOfEditingMessage = (ev: any, data: any): void => {
        setEditMessageValue(data?.value)
    };

    const onClickMessageBar = (): void => {
        chatWithAiMutation.mutate()
    };

    const onEnterPress = (e: any): void => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            chatWithAiMutation.mutate()
        }
    };

    const selectFileFromOS = (): void => fileInputRef.current?.click();

    const resetFilesFromBrowserCache = (event: any): void => {
        const { target = {} } = event || {};
        target.value = ConstantValues.EMPTY_STRING;
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            const newFiles: FileWithId[] = Array.from(event.target.files).map((file: File): FileWithId => ({
                file
            }));
            setUploadFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const deselectFile = (file: FileWithId) => (): void => {
        const remainingFiles: FileWithId[] = uploadFiles.filter((f: FileWithId): boolean => f.file.name !== file.file.name);
        setUploadFiles(remainingFiles);
    };

    const resetTemporaryChat = () => {
        setText(ConstantValues.EMPTY_STRING);
        setTemporaryMessages([]);
        dispatch(setInitialMessage(null));
    };

    const redoConversationFromEditedMessage = (lastMessageRemaining: IMessage) => () => {
        const reaminingMessages = []
        for (const message of temporaryMessages) {
            if ((message as any)?.id === lastMessageRemaining?.id) {
                break
            }
            reaminingMessages.push(message);
        }

        lastMessageRemaining.content = editMessageValue ?? ConstantValues.EMPTY_STRING;
        reaminingMessages.push(lastMessageRemaining);

        setEditMessageValue(null);
        setEditMessageId(null);
        setCurrentMessages(reaminingMessages);
        chatWithAiMutation.mutate();
    };

    const injectMessageIntoRealConversation = (message: IMessage) => () => {
        setInjectedMessageId(message?.id ?? ConstantValues.EMPTY_STRING);

        if (initialMessage) {
            const updatedMessages = realChatMessages.map((m) => {
                if (m?.id === initialMessage?.id) {
                    let content: string = ConstantValues.EMPTY_STRING;
                    let newEndIndex;

                    for (let i = 0; i < m?.content?.length; i++) {
                        if (i >= initialMessage?.start && i <= initialMessage?.end) {
                            continue;
                        }
                        if (i === initialMessage?.end + 1) {
                            const plainTextContent = stripHtml(message.content || ConstantValues.EMPTY_STRING);
                            const markdownDelimiter = initialMessage.markdownDelimiter || ""; // Ensure markdownDelimiter has a value
                            const wrappedMessageContent = `${markdownDelimiter}${plainTextContent}${markdownDelimiter}`;
                            content += wrappedMessageContent;
                            newEndIndex = initialMessage?.start + plainTextContent.length;
                        } else {
                            content += m?.content[i];
                        }
                    }

                    dispatch(setInitialMessage({ ...initialMessage, oldEnd: initialMessage.end, end: newEndIndex }));
                    return { ...m, content };
                }
                return m;
            });

            dispatch(setCurrentMessages(updatedMessages));
        } else {
            const messageCopy = { ...message, conversationId: currentlySelectedConversation?.id };
            const newChatMessages = [...realChatMessages, messageCopy];
            dispatch(setCurrentMessages(newChatMessages));
        }
    };

    function stripHtml(html: any) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || ConstantValues.EMPTY_STRING;
    };

    const extractMessageIntoRealConversation = (message: IMessage) => async (): Promise<void> => {
        setInjectedMessageId(null);
        if (initialMessage) {
            const reformattedMessages = [];
            for (const m of realChatMessages.slice()) {
                if (m?.id === initialMessage?.id) {
                    reformattedMessages.push({ ...m, content: initialMessage?.contentToUpdate });
                }
                else {
                    reformattedMessages.push(m);
                }
            }

            dispatch(setInitialMessage({ ...initialMessage, end: initialMessage?.oldEnd }));
            dispatch(setCurrentMessages(reformattedMessages));
            return;
        }

        const realChatCopy: IMessage[] = realChatMessages.slice();
        dispatch(setCurrentMessages(realChatCopy?.filter((m: IMessage): boolean => m?.id !== message?.id)));
    };

    const saveInjectedMessageToConversation = (message: IMessage) => async (): Promise<void> => {
        if (initialMessage) {
            const reformattedMessages: any[] = []
            for (const m of realChatMessages.slice()) {
                if (m?.id === initialMessage?.id) {
                    let content: string = ConstantValues.EMPTY_STRING
                    for (let i = 0; i < m?.content?.length; i++) {
                        if (m?.content[i] === initialMessage?.markdownDelimiter && i >= initialMessage?.start - 2 && i <= initialMessage?.end + 3) {
                            continue
                        }
                        content += m?.content[i];
                    }
                    await updateContentOfConversation({ conversationId: currentlySelectedConversation?.id, messageId: initialMessage?.id, content: content })
                    reformattedMessages.push({ ...m, content })
                }
                else {
                    reformattedMessages.push(m);
                }
            }

            setCleanUpOnClose(false);
            dispatch(setTemporaryChatOpen(false));
            setTimeout(() => dispatch(setCurrentMessages(reformattedMessages)), 100);
        }
        else {
            const newMessage = await addMessageToConversation({
                message: {
                    content: message?.content,
                    files: Array.from(uploadFiles || []).map((f: FileWithId) => { return { filename: f?.file.name } }),
                    isHidden: false,
                    role: message?.role,
                    tags: currentTags,
                    llmConfig: currentLLMConfig,
                },
                conversationId: currentlySelectedConversation?.id
            })

            const realChatCopy: IMessage[] = realChatMessages.slice();
            const remaining: IMessage[] = realChatCopy?.filter((m: IMessage): boolean => m?.id !== message?.id);
            remaining.push(newMessage);
            dispatch(setCurrentMessages(remaining));
            setInjectedMessageId(null);
        }
    };

    const openImagePreview = (fileUrl: string) => () => {
        setPreviewImage(fileUrl);
    };

    const closeImagePreview = () => {
        setPreviewImage(null);
    };

    const handleCloseButton = (): void => {
        dispatch(setTemporaryChatOpen(false));
        dispatch(setTagsForKey({ key: ConstantValues.TEMPORARY_CHAT, tags: [] }));
        dispatch(setNewConfigForKey({ key: ConstantValues.TEMPORARY_CHAT, config: { llmType: LLMType.OPENAI, rag: false } }));
        dispatch(setInitialMessage(null));
    };

    return <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        gap: "10px"
    }}>
        {previewImage && (
            <Modal isOpen={!!previewImage} onDismiss={closeImagePreview}>
                <img src={previewImage} alt="Preview" className={styles.modalImage} />
            </Modal>
        )}
        <StickyHeader sourceKey={ConstantValues.TEMPORARY_CHAT} >
            <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                <Button style={{ width: '100%' }} onClick={resetTemporaryChat}>Clear</Button>
                <Button style={{ width: '100%' }} onClick={handleCloseButton} icon={<Dismiss12Regular />}>Close</Button>
            </div>
        </StickyHeader>

        <div ref={messagesContainerRef} style={{ height: "100%", padding: "50px 5rem 5px 5rem", backgroundColor: tokens.colorNeutralBackground2, overflowY: "scroll" }}>
            {temporaryMessages?.map(
                (message: IMessage, index: number) =>
                    message?.id !== editMessageId
                        ? <div
                            key={index}
                            onMouseEnter={() => setHoveredMessageId(message?.id ?? ConstantValues.EMPTY_STRING)}
                            onMouseLeave={() => setHoveredMessageId(null)}
                            style={{ display: "flex", flexDirection: "column", alignItems: message?.role === "assistant" ? "start" : "end", gap: "10px" }}
                        >
                            <Persona
                                name={message?.role === "assistant" ? "NorthHighland AI" : currentUser?.displayName}
                                secondaryText={message?.role}
                                presence={{ status: message?.role === "assistant" ? "available" : "offline" }}
                                size="large"
                                avatar={
                                    message?.role === "assistant"
                                        ? { image: { src: "/images/logo_sm.png" } }
                                        : { name: currentUser?.displayName }
                                }
                            />
                            <Body2 style={{ padding: message?.role === "assistant" ? "10px 20px 10px 45px" : "10px 20px 10px 50px", wordBreak: "break-word" }}>
                                <Markdown remarkPlugins={[remarkGfm]} >{message?.content}</Markdown>
                            </Body2>
                            <div style={{ display: "flex", gap: "5px" }}>
                                {message?.tags
                                    ? message?.tags?.map((tag: ITag) => <TagWithAttachments
                                        key={tag.id}
                                        sourceKey={ConstantValues.TEMPORARY_CHAT}
                                        size="small"
                                        entity={tag}
                                        entityType="tag"
                                        secondaryActionFunction={false}
                                        isEditable={false}
                                        showTagName={true}
                                    />)
                                    : <Fragment />}
                                <Tag size="small" appearance="outline">{message?.llmConfig?.rag ? 'Retrieval Augmented' : 'Normal Generation'}</Tag>
                                {message?.role === "assistant" ? <Tag size="small">{message?.llmConfig?.llmType}</Tag> : <div></div>}
                            </div>

                            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", flexDirection: "column" }}>
                                {message?.files
                                    ? message?.files?.map((file: IFile, index: number) => <Card key={index} style={{ height: "fit-content" }} size="small" role="listitem">
                                        <CardHeader
                                            image={{ as: "img", src: resolveAsset(file?.filename, file), alt: "Word app logo", width: "40px", height: "40px" }}
                                            header={<Text size={100} weight="semibold">{file?.filename}</Text>}
                                        />
                                    </Card>)
                                    : <Fragment />}
                            </div>
                            {message?.sourceDocuments
                                ? message?.sourceDocuments?.map((document: any, index: number) =>
                                    <Card key={index} style={{ width: "100%" }}>
                                        <CardHeader
                                            image={{ as: "img", src: resolveAsset(document?.filename, document), alt: "Word app logo", width: "40px", height: "40px" }}
                                            header={<Text weight="semibold">{document?.filename}</Text>}

                                        />
                                        <p style={{ textAlign: "start", color: tokens?.colorNeutralForeground2 }}>
                                            {document?.content}
                                        </p>
                                    </Card>

                                )
                                : <Fragment />}
                            <Text italic size={100} style={{ padding: "0px 0px 0px 5px", color: tokens.colorNeutralForeground3 }}>
                                {message.createdAt ? new Date(message.createdAt * 1000).toDateString() : undefined}
                            </Text>
                            {hoveredMessageId === message?.id ? <div style={{ display: "flex", gap: "5px" }}>
                                {copiedMessageId === message?.id
                                    ? <Button size="small" icon={<Clipboard12Filled />} onClick={copyToClipboard(message)}>Copied!</Button>
                                    : <Button size="small" icon={<Clipboard12Regular />} onClick={copyToClipboard(message)}>Copy</Button>
                                }
                                {message?.role === "user"
                                    ? <Button onClick={setEditMessage(message)} size="small" icon={<Edit12Regular />}>Edit</Button>
                                    : <Fragment />}
                                {message?.role === "assistant" ?
                                    injectedMessageId !== message?.id ?
                                        <Button onClick={injectMessageIntoRealConversation(message)} size="small" icon={<Edit12Regular />}>
                                            Inject
                                        </Button>
                                        : <div style={{ display: "flex", gap: "5px" }}>
                                            <Button onClick={extractMessageIntoRealConversation(message)} size="small" icon={<Edit12Filled />}>
                                                Injected
                                            </Button>
                                            <Button onClick={async () => {
                                                await saveInjectedMessageToConversation(message)();
                                                dispatch(setInitialMessage(null));
                                                dispatch(setCurrentPrompt({ key: ConstantValues.TEMPORARY_CHAT, prompt: null }));
                                            }} appearance="primary" icon={<Checkmark12Regular />} size="small">
                                                Save Injection
                                            </Button>
                                        </div> : <Fragment />}

                            </div> : <div style={{ height: "24px" }} />}
                        </div>
                        : <div
                            onMouseEnter={() => setHoveredMessageId(message?.id ?? ConstantValues.EMPTY_STRING)}
                            onMouseLeave={() => setHoveredMessageId(null)}
                            style={{ display: "flex", flexDirection: "column", alignItems: message?.role === "assistant" ? "start" : "end", gap: "10px" }}
                        >
                            <Persona
                                name={message?.role === "assistant" ? "NorthHighland AI" : "Kevin Sturgis"}
                                secondaryText={message?.role}
                                presence={{ status: message?.role === "assistant" ? "available" : "offline" }}
                                size="large"
                                avatar={{
                                    image: {
                                        src: "https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-react-assets/persona-male.png",
                                    }
                                }}
                            />
                            <Input
                                value={editMessageValue ?? ConstantValues.EMPTY_STRING}
                                id={editMessageInputId}
                                onChange={updateValueOfEditingMessage}
                                style={{ width: "60%" }}
                            />

                            <div style={{ display: "flex", gap: "5px" }}>
                                <Button appearance="subtle" onClick={desetEditMessage()} size="small">Cancel</Button>
                                <Button appearance="primary" size="small" onClick={redoConversationFromEditedMessage(message)}>Save</Button>
                            </div>
                        </div>
            )}

            <AIResponse aiGenerateMessage={aiGenerateMessage} isLoading={isLoadingMessage} />
            <div ref={messagesEndRef} />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "20px 20px 10px 20px", }}>
            {
                uploadFiles
                    ? Array.from(uploadFiles || [])?.map((file: FileWithId, index: number) =>
                        <Card key={index} className={styles.card} role="listitem">
                            <CardHeader
                                image={{
                                    as: "img",
                                    src: resolveAsset(file?.file.name, file),
                                    alt: "Word app logo",
                                    width: "40px",
                                    height: "40px",
                                    onClick: isImage(file.file.name) && file ? openImagePreview(URL.createObjectURL(file.file)) : undefined,
                                    style: isImage(file.file.name)
                                        ? { cursor: 'pointer', userSelect: 'none' }
                                        : undefined
                                }}
                                header={<Text
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "150px",
                                        display: "inline-block"
                                    }}
                                    size={100}
                                    weight="semibold"
                                    title={file?.file.name}
                                >
                                    {file?.file.name}
                                </Text>}
                                action={
                                    <Button
                                        appearance="transparent"
                                        icon={<Delete16Regular />}
                                        onClick={deselectFile(file)}
                                    />
                                }
                            />
                        </Card>
                    )
                    : <Fragment />
            }
            <div style={{ display: "flex", gap: "5px", flexWrap: 'wrap' }}>
                <AIModelSettings dictKey={ConstantValues.TEMPORARY_CHAT} />
                {currentlySelectedConversation == null || currentlySelectedConversation?.userId === currentUser?.id
                    ? <AddPrompt
                        dictKey={ConstantValues.TEMPORARY_CHAT}
                        canManagePrompt={false}
                        data={prompts}
                        description={text ?? ConstantValues.EMPTY_STRING}
                        setDescription={setText} />
                    : <Fragment />
                }
            </div>
            <Field style={{ display: "flex" }}>
                <input onChange={handleFileInputChange}
                    onClick={resetFilesFromBrowserCache}
                    multiple={true}
                    ref={fileInputRef}
                    type='file'
                    hidden />
                <CompoundButton
                    icon={<DocumentAdd20Regular />}
                    size="small"
                    onClick={selectFileFromOS}
                    style={{ borderBottomRightRadius: "0px", borderTopRightRadius: "0px", borderRight: "0", }}
                />
                <Textarea placeholder="How can I help you today?"
                    resize="vertical"
                    style={{ width: "100%", borderRadius: "0px", borderBottomRightRadius: "0px", borderLeft: "0", textAlign: "center" }}
                    value={text}
                    onChange={(e) => setText(e?.target?.value)}
                    onKeyDown={onEnterPress} />
                <CompoundButton
                    icon={<Send16Regular />}
                    appearance="primary"
                    size="small"
                    onClick={onClickMessageBar}
                    style={{ borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px" }}
                ></CompoundButton>
            </Field>
        </div>
    </div >
}