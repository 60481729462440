import { Button, Subtitle1, Text, Title1 } from "@fluentui/react-components";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";

export const GuidelinesPage = (): JSX.Element => {
    const navigate = useNavigate();
    return (
        <div className="App" style={{
            flexDirection: "column",
            display: "flex",
            gap: "10px",
            padding: "5rem 20rem"
        }}>
            <Button style={{ width: "120px", display: "flex", gap: "5px" }} onClick={() => navigate('/')}> <ArrowLeftRegular /> back </Button>
            <Title1>AI Guidelines</Title1>
            <Subtitle1>Summary</Subtitle1>
            <Text>
                Genesis is NH's proprietary LLM tool that leverages Generative AI to help generate content with NH information and in the standard format, level of detail, and NH voice.
            </Text>
            <Text>
                It can be utilized to draft the initial proposal responses, qualifications, summarizing and editing executive summaries. It can also be utilized to reformat resumes into the NH format, but the resume must be uploaded and verified by the individual that the resume is for. Genesis provides an easy way to query any of the NH Knowledge components that have been validated and considered gold standard. Genesis reduces the barrier to entry for utilizing AI in content creation and refinement.
            </Text>
            <Subtitle1>Limitations/Known-Issues</Subtitle1>
            <Text>
                <ul>
                    <li> Genesis only includes those proposals and quals that have been reviewed and updated to the gold standard. It is not all inclusive of every proposal, qual, project, or team. </li>
                    <li> Genesis should NOT be used to search for or recommend a list of team members to be included in the proposal or a list of team members with a certain skill, etc. Any query for a list of people should be redirected to Resource Management. This is NOT a tool for recommending people. </li>
                </ul>
            </Text>
        </div>
    )
}