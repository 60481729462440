import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { ArrowDown12Regular, ArrowSortDown16Filled, ArrowSortUp16Filled, ClearFormatting16Filled } from "@fluentui/react-icons";
import { SortingDirection } from "enums/SortingOrder";
import { IColumnActionsMenuProps } from "./ColumnActionsMenu.types";

export const ColumnActionsMenu = (props: IColumnActionsMenuProps): JSX.Element => {
    const isAscending = props.sortingDirection === SortingDirection.Ascending;
    const isDescending = props.sortingDirection === SortingDirection.Descending;

    return (
        <Menu>
            <MenuTrigger>
                <MenuButton appearance="subtle" icon={isAscending ? <ArrowSortUp16Filled /> : isDescending ? <ArrowSortDown16Filled /> : <ArrowDown12Regular />} />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem
                        icon={<ArrowSortUp16Filled />}
                        onClick={(): void => props.onSortClicked(props.columnKey, false)}
                        disabled={isAscending || !props.isSortable}
                    >
                        Sort A to Z
                    </MenuItem>
                    <MenuItem
                        icon={<ArrowSortDown16Filled />}
                        onClick={(): void => props.onSortClicked(props.columnKey, true)}
                        disabled={isDescending || !props.isSortable}
                    >
                        Sort Z to A
                    </MenuItem>
                    <MenuItem
                        icon={<ClearFormatting16Filled />}
                        onClick={(): void => props.onClear(props.columnKey)}
                    >
                        Clear Filter
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};